import { styled } from '@material-ui/core'
import ReactSelect from 'react-select'

const StyledSelect = styled(ReactSelect)({
    fontSize: '1rem',
    fontFamily: 'Atyp, sans-serif',
    "& .custom-select__value-container": {
        padding: 0
    },
    "& .custom-select__control": {
        border: 'none',
        // minWidth: 200,
        // display: 'inline-flex',
    },
    "& .custom-select__indicator-separator": {
        backgroundColor: 'white'
    },
    "& .custom-select__control--is-focused": {
        borderColor: 'unset',
        boxShadow: 'unset'
    }
})

export default StyledSelect;