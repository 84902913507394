import React from 'react';
import { withRouter } from 'react-router-dom';

interface RouteInfo {
  /** page params (e.g: /photos/:id, params will contains the id) */
  params: { [key: string]: string },

  /** route path with params variables names (e.g: /photos/:id)  */
  path: string,

  /** route path with params variables names resolved to its value (e.g: /photos/44) */
  url: string,

  /** page query params (e.g: /photos/33?type=private will give you the {type:private} */
  queryParams: { [key: string]: string },

  /** navigate to another page */
  navigate: (url: string) => void,
}



const DEFAULT_ROUTE_INFO = {
  params: {},
  path: '',
  url: '',
  queryParams: {},
  navigate: (_url: string) => { },
}


const RouterContext = React.createContext<RouteInfo>(DEFAULT_ROUTE_INFO);



function _RouterContextProvider(props: any) {

  let queryParamsString = props?.location?.search ?? '';
  let queryParamsObj = new URLSearchParams(queryParamsString);
  let queryParams = queryParamsObj.toString()
    .split('&')
    .reduce((params, currentString) => {
      const [key = '', value = ''] = currentString.split('=');

      return {
        ...params,
        [key]: value
      }
    }, {});

  const contextValue: RouteInfo = {
    params: props?.match?.params ?? {},
    path: props?.match?.path ?? '',
    url: props?.match?.url ?? '',
    queryParams,
    navigate: props?.history?.push ?? (() => { }),
  }

  return (
    <RouterContext.Provider value={contextValue}>
      {props?.children}
    </RouterContext.Provider>
  );
}


export const RouterContextProvider = (withRouter as any)(_RouterContextProvider);



export function useRouteInfo(): Omit<RouteInfo, "navigate"> {
  try {

    const { navigate, ...routeInfo } = React.useContext(RouterContext);

    return routeInfo;

  } catch (error) {
    console.error(error);
    console.error('You are using useRouteInfo without the context RouterContextProvider');
    return DEFAULT_ROUTE_INFO;
  }
}




export function useNavigate(): RouteInfo["navigate"] {
  try {

    const { navigate, } = React.useContext(RouterContext);

    return navigate;

  } catch (error) {
    console.error(error);
    console.error('You are using useNavigate without the context RouterContextProvider');
    return DEFAULT_ROUTE_INFO.navigate;
  }
}
