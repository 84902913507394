import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography, Button } from "@material-ui/core";
//@ts-ignore
import { motion as m } from "framer-motion";
import { UploadPhoto } from './UploadPhoto';
import { useFetchDocuments, usePutDocument } from "../../../components/src/api/hooks/basicProfile.hooks";
const { baseURL } = require("../../../framework/src/config");
import { HideContainer, DocumentAccordionItem} from '../../../components/src/shared';
import CircularProgress from "@material-ui/core/CircularProgress";
export const configJSON = require("./config");

export function DocumentUpload() {
  const useStyles = makeStyles((theme) => ({
    root: {},
    inputRoot: {
      width: "100px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 0, // Remove border radius
        // borderColor:'white',
        borderColor: "none",
        outline: "none",
      },
    },
    dropdown: {
      // backgroundColor: 'red', // Change the background color
      // marginTop: theme.spacing(1), // Customize the position
      zIndex: theme.zIndex.modal + 1, // Ensure the dropdown appears above other elements
      boxShadow: "none",
      minWidth: "200px",
    },
    linksContainer: {
      display: 'flex',
      width: "100%",
      justifyContent: 'space-between',
      alignItems: 'center',
      '& a': {
        textDecoration: 'none',
        color: theme.palette.secondary.main,
      },
      '& a:nth-child(2)': {
        marginLeft: '20px',

      },
      '& p': {
        fontSize: '1.1rem',
        fontWeight: 600,
      },
    },
    activeLink: {
      color: theme.palette.primary.main,
    },
    emptySpan: {
      flexGrow: 1,
    },
    nameOfUser: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "40px",
      lineHeight: "40px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    heading: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: "71px",
      color: "#FFFFFF",
      [theme.breakpoints.down("xs")]: {
        fontSize: "25px",
        lineHeight: "36px",
      },
    },
    blueLines: {
      height: "1px",
      position: "relative",
      width: "40%",
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down("xs")]: {
        width: "40%",
      },
    },
    arrowM : {
      width: "9px",
      height: "9px",
      left: "calc(100% - 10px)",
      bottom: "-4px",
      margin: "0",
      position: "absolute",
      transform:"rotate(45deg)",
      borderRight: `1px solid ${theme.palette.primary.main}`,
      borderTop: `1px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.down('sm')]: {
          width: "9px",
          height: "9px",
          bottom: "-4px",
      },
    },
    subHeading: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "40px",
      color: "#C1C1C1",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
        lineHeight: "20px",
      },
    },
    sendButton: {
      fontWeight: 400,
      textTransform: "uppercase",
      textAlign: "right",
      paddingLeft: "40px",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0px",
      },
    },
    subTitleText: {
      color: "#999",
    },
    saveButton: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      justifyContent: "flex-end",
    },
    arrow: {
      left: "-8px",
      fontSize: "16px",
      position: "relative",
    },
    arrowContainer: {
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      justifyItems: "flex-end",
      justifyContent: "flex-end",
      marginTop: "100px",
      [theme.breakpoints.down('sm')]: {
        marginTop: "52px"
      }
    },
    termsConditions: {
      fontSize: "20px",
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
    },
    headerTypo: {
      fontSize: "40px",
      [theme.breakpoints.down('sm')]: {
        fontSize: "20px"
      }
    },
    subheaderTypo: {
      fontSize: "20px",
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px'
      }
    },
    numberTypo: {
      marginRight: "50px",
      [theme.breakpoints.down('sm')]: {
        marginRight: "15px"
      }
    },
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "150px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "110px",
      },
    },
    headingContainer: {
      width: "600px",
      marginTop: "20px",
      [theme.breakpoints.down("xs")]: {
        width: "300px",
      },
    },
    marginOfheading: {
      marginBottom: "60px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "40px",
      },
    },
    text: {
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "28px",
      display: "inline-block",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
        lineHeight: "14px",
      },
    },
    downloadContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      justifyItems: "flex-end",
      marginLeft: "25%",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        marginLeft: "15%",
      },
    },
    downloadButton: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "28px",
      textTransform: "uppercase",
      color: theme.palette.primary.main,
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
        lineHeight: "14px",
        display: "none",
      },
    },
    marginRightNumber: {
      marginRight: "19%",
      [theme.breakpoints.down("sm")]: {
        marginRight: "49px",
      },
    },
    plusIconContainer: {
      width: "80%",
      height: 320,
      borderTop: "1px solid #2C62E0",
      borderBottom: "1px solid #2C62E0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRight: 0,
      borderLeft: 0,
      [theme.breakpoints.down("xs")]: {
        marginBottom: "42px",
        height: 146,
        width: '100%'
      },
    },
    plusIcon: {
      fontWeight: 400,
      fontSize: 100,
      lineHeight: "40px",
      color: "#2C62E0",
    },
    cardContainer: {
      width: "100%",
      display: "flex",
      gap: "40px",
      justifyContent: "space-between",
      justifyItems: "space-between",
      rowSpacing: "10%",
      marginBottom: "80px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        marginBottom: "40px",
      },
    },
    headingName: {
      fontSize: "20px",
      lineHeight: "40px",
      fontWeight: 400,
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
        lineHeight: "20px",
      },
    },
    headingSpacing: {
      marginRight: "47px",
      [theme.breakpoints.down("xs")]: {
        marginRight: "11px",
      },
    },
    subHeadings: {
      color: "#C1C1C1",
      fontSize: "18px",
      lineHeight: "30px",
      fontWeight: 400,
      textTransform: "uppercase",
      marginLeft: "5px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "8px",
        lineHeight: "16px",
      },
    },
  }));

  const classes = useStyles();
  const { data: uploadData, refetch:refetchUploadData } = useFetchDocuments();
  const { mutate: putDocument, isLoading } = usePutDocument();

  const [ccFront, setCCFront] = useState<any>()
  const [passFront, setPassFront] = useState<any>()
  const [ccBack, setCCBack] = useState<any>()
  const [passBack, setPassBack] = useState<any>()
  const [valueSet, setValueSet] = useState<any>(
    {
      "card_number": "",
      "card_expiry_date": "",
      "cvv": "",
      "customer_name": "",
      "nationality": "",
      "country_of_issue": "",
      "issue_date": "",
      "passport_number": "",
      "passport_expiry_date": "",
      "passport": "",
      "credit_card": "",
      "credit_card_type" : ""
    }
  )
  const [documentAccordian, setDocumentAccordian] = useState<any>(
    [
      {
        heading: "passport",
        required: false,
        subHeading: "MANUALLY UPLOAD",
        inputFields: [
          { label: "Passport Number", placeHolder: "Passport number", name: "passport_number", type: "string", error: "", value: "" },
          {
            label: "Nationality", placeHolder: "Nationality", name: "nationality", type: "select", options: configJSON.countryList, error: "", value: ""
          },
          {
            label: "Country of Issue", placeHolder: "Country of issue", name: "country_of_issue", type: "select", options: configJSON.countryList, error: "", value: ""
          },
          { label: "Issue Date", placeHolder: "Issue date", name: "issue_date", type: "date", error: "", value: "" },
          { label: "Expire Date", placeHolder: "Expire date", name: "passport_expiry_date", type: "date", error: "", value: "" },
        ],
      },
      {
        heading: "credit card",
        required: false,
        subHeading: "MANUALLY UPLOAD",
        inputFields: [
          {
            label: "Card Type", placeHolder: "Card Type", name: "credit_card_type", type: "select", options: [
              { label: "Visa", value: "Visa" },
              { label: "Mastercard", value: "Mastercard" },
              { label: "Amex", value: "Amex" },
              { label: "JCB", value: "JCB" },
              { label: "Diners", value: "Diners" },
            ]
          },
          { label: "Card Number", placeHolder: "Card number", name: "card_number", type: "number", error: "", value: "" },
          { label: "Expire Date", placeHolder: "MM/YY", name: "card_expiry_date", type: "string", variant: "m/y", error: "", value: "" },
          { label: "CVV", placeHolder: "CVV", name: "cvv", type: "number", error: "", value: "" },
          { label: "Customer Name", placeHolder: "Customer name", name: "customer_name", type: "string", error: "", value: "" },
        ]
      }
    ]
  )

  useEffect(() => {
    setValueSet(uploadData?.data?.attributes)
  }, [uploadData])

  const handleChange = (e: any) => {
    const name = e.target.name
    const obj = {
      ...valueSet,
      [name]: e.target.value
    }
    setValueSet(obj)
  }

  const [error, setError] = useState<string[]>([])
  const [sucess, setSucess] = useState<string[]>([])

  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChangeS = (panel: string) => (_event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const placeDocumentErrors = (errorMessage : any) => {
    setError(["Check for errors."])
    const updatedDocumentAccordian = documentAccordian.map((documentsection:any) => {
      documentsection.inputFields.forEach((documentinputField:any) => {
        const fieldName = documentinputField.name;
        if (errorMessage.hasOwnProperty(fieldName)) {
          documentinputField.error = errorMessage[fieldName].join(", ");
        } else {
          documentinputField.error = "";
        }
      });
      return documentsection;
    });
  
    setDocumentAccordian(updatedDocumentAccordian);
  }

  const reSetErrors = () => {
    const updatedDocumentAccordian = documentAccordian.map((documentSections:any) => {
      documentSections.inputFields.forEach((documentField:any) => {
        documentField.error = ""
      })
      return documentSections;
    })
    setDocumentAccordian(updatedDocumentAccordian)
  }

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear() % 100;
  const currentMonth = currentDate.getMonth() + 1;


  const handleSubmit = () => {
    setSucess([])
    setError([])
    reSetErrors()
    const payloadSet = {
      "card_number": valueSet["card_number"],
      "card_expiry_date": valueSet["card_expiry_date"],
      "credit_card_type" : valueSet["credit_card_type"],
      "cvv": valueSet["cvv"],
      "customer_name": valueSet["customer_name"],
      "nationality": valueSet["nationality"],
      "country_of_issue": valueSet["country_of_issue"],
      "issue_date": valueSet["issue_date"],
      "passport_number": valueSet["passport_number"],
      "passport_expiry_date": valueSet["passport_expiry_date"],
      "passport_front": passFront,
      "passport_back": passBack,
      "credit_card_back": ccBack,
      "credit_card_front": ccFront,
      "token": sessionStorage.getItem('token'),
    };
    if(valueSet["card_expiry_date"] && !(/^(?!00\/)\d{2}\/\d{2}$/.test(valueSet["card_expiry_date"])) && (+valueSet["card_expiry_date"].split("/")[0] > 12 || +valueSet["card_expiry_date"].split("/")[0] < 1 || +valueSet["card_expiry_date"].split("/")[1] == 0)){
      placeDocumentErrors({
        "card_expiry_date" : [
          "Invalid month/year."
        ]
      })
    } else if(valueSet["card_expiry_date"] && (+valueSet["card_expiry_date"].split("/")[1] < currentYear || (+valueSet["card_expiry_date"].split("/")[1] == currentYear && +valueSet["card_expiry_date"].split("/")[0] < currentMonth))){
      placeDocumentErrors({
        "card_expiry_date" : [
          "Card is expired."
        ]
      })
    } else {
      putDocument(payloadSet, {
        onSuccess() {
          refetchUploadData()
          setSucess(["Document sucessfully updated."])
        },
        onError(error) {
          const {errors : errorMessage} = error
          typeof(errorMessage) === 'object' ? placeDocumentErrors(errorMessage) : setError([errorMessage])
        }
      });
    }
  }

return (
  <Box className="rootContainer1" style={{overflowX:"hidden"}} sx={{ padding: { sm: "20px 40px", md: "20px 40px" }, display: "block" }}>

    <m.div
      initial={{
        x: "100%",
      }}
      animate={{
        x: "0%",
      }}
      transition={{
        duration: 0.75,
        ease: "easeOut",
      }}
    >
      <Grid container className={classes.container}>
        <Box className={classes.cardContainer}>
          <Box width="100%">
            <UploadPhoto
              imageFile={uploadData?.data?.attributes?.passport_front && `${baseURL}${uploadData?.data?.attributes?.passport_front}`}
              title="PASSPORT"
              subtitle="FRONT PHOTO"
              itemNumber="01"
              onRemove={()=>{setPassFront(uploadData?.data?.attributes?.passport_front ? 'nil' : undefined)}}
              onUpload={(file) => setPassFront(file.file)}
            />
          </Box>
          <Box width="100%">
            <UploadPhoto
              imageFile={uploadData?.data?.attributes?.passport_back && `${baseURL}${uploadData?.data?.attributes?.passport_back}`}
              title="PASSPORT"
              subtitle="BACK PHOTO"
              itemNumber="02"
              onRemove={()=>setPassBack(uploadData?.data?.attributes?.passport_back ? 'nil' : undefined)}
              onUpload={(file) => setPassBack(file.file)}
            />
          </Box>
        </Box>
        <Box className={classes.cardContainer}>
          <Box width="100%">
            <UploadPhoto
              imageFile={uploadData?.data?.attributes?.credit_card_front && `${baseURL}${uploadData?.data?.attributes?.credit_card_front}`}
              title="CREDIT CARD"
              subtitle="FRONT PHOTO"
              itemNumber="03"
              onRemove={()=>setCCFront(uploadData?.data?.attributes?.credit_card_front ? 'nil' : undefined)}
              onUpload={(file) => setCCFront(file.file)}
            />
          </Box>
          <Box width="100%">
            <UploadPhoto
              imageFile={uploadData?.data?.attributes?.credit_card_back && `${baseURL}${uploadData?.data?.attributes?.credit_card_back}`}
              title="CREDIT CARD"
              subtitle="BACK PHOTO"
              itemNumber="04"
              onRemove={()=>setCCBack(uploadData?.data?.attributes?.credit_card_back ? 'nil' : undefined)}
              onUpload={(file) => setCCBack(file.file)}
            />
          </Box>
        </Box>
        <Box>
        {valueSet && documentAccordian.map((item:any, index:any) => (
          <DocumentAccordionItem
            item={item}
            index={index}
            expanded={expanded}
            classes={classes}
            handleChange={handleChange}
            change={handleChangeS(index.toString())}
            valueSet={valueSet}
            key={'document-accordion-' + item.heading}
          />
        ))}

        </Box>
        <Box style={{ width: "100%" }}>
          <Box>
            {error.map((item) => {
              return (
                <Typography key={item} variant='body2' color='error'>{item}</Typography>
              )
            })}

            {sucess.map((item) => {
              return (
                <Typography key={item} variant='body2' color='primary'>{item}</Typography>
              )
            })}
          </Box>
          <Box className={classes.arrowContainer} style={{ marginBottom: "170px" }}>
            <Box className={classes.blueLines}><div className={classes.arrowM}></div></Box>
            <Button
              data-test-id='btn-save'
              color="primary"
              variant="text"
              className={classes.sendButton}
              type="submit"
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {"SAVE"}
              <HideContainer hideWhen={!isLoading}>
                <CircularProgress
                  size={20}
                  style={{ margin: "0 5px" }}
                  color="primary"
                />
              </HideContainer>
            </Button>
          </Box>
        </Box>
      </Grid>
    </m.div>
  </Box>
);
}

