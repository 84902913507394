import { makeHttpMessage } from "../../utils/http-message"
import { appendJsonContentType } from "../../utils/http-response";

export const impactMessages = {

  fetchImpactPageContent() {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: 'bx_block_self_content/impacts',
      httpMethod: 'GET',
    });
  },
}
