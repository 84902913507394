// Customizable Area Start
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, useMediaQuery, styled } from "@material-ui/core";
import { JoinPageForm } from "../../termsconditions/src/JoinForm";
import { useJoinPageHeadings } from "../../../components/src/api/hooks/join-page.hooks";
import { VerticalSpacer } from "../../../components/src/shared";
import FixedText from "../../../components/src/shared/FixedText";
import clsx from 'clsx'
//@ts-ignore
import {Helmet} from "react-helmet";
import { HTMLParser } from "../../../components/src/utils/html-parser";

const { baseURL } = require("../../../framework/src/config");

const useStyles = makeStyles((theme) => ({
  joinTextFixedContainerJoinContent: {
    position: 'fixed',
    top: 400,
    left: 70,
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  containerJoinContent: {
    padding: '250px 40px 40px 40px',
    maxWidth: 'min(800px, 100vw)',
    width: '100%',
    margin: '0 auto',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '140px 40px 30px 40px',
    },
  },
  headerTextContainerJoinContent: {
    width: 'max-content',
    textAlign: 'left',
    marginBottom: '80px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '250px',
      paddingTop: 59
    },
    '& h1 p:nth-child(2n)': {
      textAlign: 'center',
    },
    '& h1 *': {
      fontSize: '3rem',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: '22px',
      },
    },
  },
  imageContainerJoinContent: {
    marginTop: "105px",
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: "90px",
    }
  },
  imageJoinContent : {
    marginBottom: '-30px',
    maxWidth: '100%',
    width: '100%',
    objectFit: 'cover',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '500px',
    },
  },
  joinTextJoinContent: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "28px",
    letterSpacing: "0.2em",
    color: theme.palette.primary.main,
  },
  fontTextJoinContent: {
    fontSize: "50px",
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px'
    }
  },
  boxcontain : {
    width : "100%",
    "& > p" : {
      color: "white",
      margin : "12px 0px",
      fontWeight: 400,
      textAlign: "right",
      marginLeft: "40px"
    },
    "& > p:first-child": {
      textAlign: "left",
      marginLeft: "0px",
    },
  }
}));

const TextWrapperJoinContent = styled('div')({
  // @ts-ignore
  "& #floating-fixed-text": {
    position: 'unset !important',
    paddingBottom: 26,
    "& > p": {
      fontSize: "10px !important",
      letterSpacing: "2px !important"
    }
  }
})

export function JoinWebContentJoinContent() {

  const { data } = useJoinPageHeadings();
  let backGroudImageJoinContent = data?.data[0]?.attributes?.image ?? "";
  const titleJoinContent = data?.data[0]?.attributes?.join_page_headlines?.data?.sort((a:any,b:any) => parseInt(a.id) - parseInt(b.id)).map((item:any) => item.attributes.headline)
  const imageDescriptionJoinContent = data?.data[0]?.attributes?.description ?? ""
  const isMobileScreenJoinContent = useMediaQuery('(max-width: 768px)');
  const classesJoinContent = useStyles();

  function messageJoinContent() {
    return (
      <Box className={classesJoinContent.headerTextContainerJoinContent}>
        {titleJoinContent?.map((textJoinContent:any, indexJoinContent:any) => {
          const textColorJoinContent = indexJoinContent%2==0 ? 0 : 1
          return(
            <Typography key={textJoinContent} variant="h1" style={{textAlign : indexJoinContent==1 ? "center" : "left"}}>
              {
                indexJoinContent == 0 ? <Typography variant="caption">{textJoinContent}</Typography> :
                textJoinContent.split(' ').map((wordJoinContent:any, wordIndexJoinContent:any) => (
                  <Typography key={wordJoinContent} variant="caption" color={wordIndexJoinContent === textColorJoinContent ? "secondary" : "initial"}>
                    {`${wordJoinContent} `}
                  </Typography>
                ))
              }
            </Typography>
          )}
        )}
        <VerticalSpacer value={20} />
      </Box>
    )
  }

  return (
    <>
      <Helmet>
          <title>Global Join: Create Your Worldwide Travel Account</title>
          <meta charSet="utf-8" />
          <meta name="description" content="Embark on a journey of global discovery and unparalleled experiences by becoming a valued member of the Global community. Our \'Join\' page brings you closer to a realm of extraordinary travel opportunities, where the world becomes your playground. Create your personal Global account and unlock a universe of remarkable adventures. BE PART OF THE GLOBAL TRAVEL EXPERIENCE: At Global, we are dedicated to curating exceptional travel moments that transcend boundaries. By joining us, you are entering a realm of endless possibilities, where every destination is within your reach. Join our diverse community of travelers who share your passion for exploration, enrichment, and cultural immersion. We want to travel the world with you: Your aspiration to explore the world on your terms is at the heart of what we do. The \'Join\' page is your gateway to a future defined by boundless horizons and unparalleled travel opportunities. No matter your travel style or purpose, Global is here to transform your visions into extraordinary realities. Sign up now and let your journey commence. Experience travel in a new light with Global – where each adventure is a testament to your individuality and our commitment to delivering exceptional experiences. Your global odyssey begins here." />
      </Helmet>
      {!isMobileScreenJoinContent && <FixedText text="JOIN" />}

      <div className={clsx(classesJoinContent.imageContainerJoinContent,'hide-clock-in-this-container')}>

        <img className={classesJoinContent.imageJoinContent} alt='JetZero Reduced Join Page Background Image' src={`${baseURL}${backGroudImageJoinContent.trim()}`} />
        <Box className={classesJoinContent.fontTextJoinContent} sx={{ position: "absolute", left: { xs: "40px", sm: "40px", md: "20%" }, bottom: "20px" }}>
          {isMobileScreenJoinContent && <TextWrapperJoinContent><FixedText text="JOIN" /></TextWrapperJoinContent>}
          <Box className={classesJoinContent.boxcontain}>
            <HTMLParser content={imageDescriptionJoinContent}/>
          </Box>
        </Box>
      </div>

      <div className={classesJoinContent.containerJoinContent}>

        {!isMobileScreenJoinContent &&
          messageJoinContent()
        }

        <JoinPageForm />
        <VerticalSpacer value={20} />

        {isMobileScreenJoinContent &&
          messageJoinContent()
        }

      </div>


    </>
  );
}
// Customizable Area End