import React, { ReactNode } from "react";
import { Typography, useMediaQuery } from "@material-ui/core";
//@ts-ignore
import { motion as m } from "framer-motion";
import ThankYouPageController, { Props } from "./ThankYouPageController.web";
import { makeStyles, styled, useTheme } from '@material-ui/core/styles';
import WorldClocks from "../../../components/src/shared/WorldClock";

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    height: '100vh',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '270px',
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingLeft: '40px',
    },
  },
  thankYouText: {
    color: 'white',
    '& *': {
      fontSize: '3rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
  },
  blue: {
    color: theme.palette.primary.main,
  },
  offset: {
    position: 'relative',
    left: '70px',
  },
  joinText: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "28px",
    letterSpacing: "0.2em",
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      color: 'white',
      marginBottom: '100px',
    },
  },
}));

const useFixedTextContainerStyels = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    left: '110px',
    top: '360px',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0,
      left: 0,
    },
  },

}));

function FixedTextContainer({ children }: { children: ReactNode }) {
  const classes = useFixedTextContainerStyels();

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
}

export default class ThankYouPage extends ThankYouPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <ThankYouPageContent />
    );
  }
}

function ThankYouPageContent() {
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div style={{ overflowX: "hidden" }}>
      <m.div
        initial={{
          x: "100%",
        }}
        animate={{
          x: "0%",
        }}
        transition={{
          duration: 0.75,
          ease: "easeOut",
        }}
      >

        <div className={classes.root}>

          <FixedTextContainer>
            <Typography className={classes.joinText}>
              {'JOIN'}
            </Typography>
          </FixedTextContainer>

          <Typography variant="h1" className={classes.thankYouText}>
            <Typography variant="caption" className={classes.blue}>
              {'THANK YOU'}
            </Typography>
            <br />
            <Typography variant="caption" className={classes.offset}>
              {'FOR YOUR INTEREST.'}
            </Typography>
            <br />
            <Typography variant="caption" className={classes.blue}>
              {'A TEAM MEMBER WILL'}
            </Typography>
            <br />
            <Typography variant="caption">
              {'REACH OUT SHORTLY.'}
            </Typography>
            <br />
          </Typography>

          {matches && (
            <FixedClockToLeftSideWrapper>
              <WorldClocks color="white" />
            </FixedClockToLeftSideWrapper>
          )}

        </div>

      </m.div>
    </div>
  );
}

export const FixedClockToLeftSideWrapper = styled('div')({
  "@media only screen and (max-width: 768px)": {
    "& > #floating-world-clock": {
      paddingTop: 177,
      paddingBottom: 40,
      position: 'static',
      backgroundColor: 'black',
    }
  }
})
