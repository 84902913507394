// Customizable Area Start
import React, { useState } from 'react';
// @ts-ignore
import classes from './LoginForm.module.css';
import {
  Typography,
  useMediaQuery,
  Button,
  Checkbox,
  FormControlLabel,
  Divider
} from '@material-ui/core';
import clsx from 'clsx';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { JetZeroTextField, } from '../../../components/src/design-system';
import { HideContainer, PasswordVisibility } from '../../../components/src/shared';
import { useLogin } from '../../../components/src/api/hooks/account.hooks';
import { useNavigate } from '../../../components/src/utils/router-context';
import {
  useAllTrips
} from "../../../components/src/api/hooks/basicProfile.hooks";



const VALIDATION_SCHEMA2 = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
  rememberMe: yup.boolean(),
});

const FORM_INITIAL_VALUES_LOGIN2 = {
  username2: '',
  password2: '',
  rememberMe2: false,
}

export function LoginForm2({ errorMsg2, setLoginErrorMsg2 }: { errorMsg2: string, setLoginErrorMsg2: React.Dispatch<React.SetStateAction<string>> }) {

  const isMobileScreen2 = useMediaQuery('(max-width: 959px)');
  const [showPassword2, setShowPassword2] = useState<boolean>(false)
  const disableUnderline2 = !isMobileScreen2;
  const navigate2 = useNavigate();
  const { mutate: loginCall2, isLoading:isLoading2 } = useLogin();

  const sessionSet2 = ({token, refresh_token, id} : {token:string, refresh_token:string, id:string}) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('refresh_token', refresh_token);
    sessionStorage.setItem('id', id);
  }

  const {
    mutate: getAllTrips2,
  } = useAllTrips();

  const style = {
    style1 : {position:"relative", top:"1px"} as React.CSSProperties
  }

  return (
    <Formik
      initialValues={FORM_INITIAL_VALUES_LOGIN2}
      onSubmit={(Loginvalues) => {
        if (!Loginvalues.username2 && !Loginvalues.password2) {
          setLoginErrorMsg2("Username & password are required.");
          return;
        }
        if (!Loginvalues.username2) {
          setLoginErrorMsg2("Username is required.");
          return;
        }
        if (!Loginvalues.password2) {
          setLoginErrorMsg2("Password is required.");
          return;
        }
        setLoginErrorMsg2("");
        loginCall2({ email: Loginvalues.username2, password: Loginvalues.password2 }, {
          onSuccess(responseLogin: any) {
            try {
              setLoginErrorMsg2('');
              const { meta: { token:loginToken, refresh_token:loginrefresn_token, id:loginID, checked_terms_and_policy: loginchecked_terms_and_policy } } = responseLogin;
              if(Loginvalues.rememberMe2){
                localStorage.setItem('token', loginToken);
                localStorage.setItem('refresh_token', loginrefresn_token);
                localStorage.setItem('id', loginID);
                sessionSet2({token:loginToken, refresh_token:loginrefresn_token, id:loginID})
              } else {
                sessionSet2({token:loginToken, refresh_token:loginrefresn_token, id:loginID})
              }

              getAllTrips2("call")
              
              navigate2( loginchecked_terms_and_policy ? '/account#myTrip' : '/account#account');
            } catch (errorlogin) {
              console.error(errorlogin);
            }
          },
          onError(errorlogin) {
            try {
              const { errors: [{ failed_login: errorLoginMessage }] } = errorlogin;
              setLoginErrorMsg2(errorLoginMessage);
            } catch (errorL) {
              console.error(errorL);
            }
          },
        });
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {
        // @ts-ignore
        formik => <Form translate="yes" className={classes.form}>

          <div className={classes.loginFormInputsContainer}>

            <JetZeroTextField
              inputProps={{
                'data-test-id': 'username'
              }}
              className={classes.input}
              label=""
              placeholder="USERNAME"
              InputProps={{ disableUnderline:disableUnderline2 }}
              fullWidth={isMobileScreen2}
              value={formik.values.username2}
              onChange={(event) => formik.setFieldValue('username2', event.target.value)}
            />

            <Divider orientation="vertical" />

            <JetZeroTextField
              inputProps={{
                'data-test-id': 'password'
              }}
              className={classes.input}
              label=""
              placeholder="PASSWORD"
              type={showPassword2 ? "text" : "password"}
              InputProps={{ 
                disableUnderline:disableUnderline2,
                endAdornment:
                  <PasswordVisibility showPassword={showPassword2} togglePassword={()=>setShowPassword2(!showPassword2)}/>
              }}
              fullWidth={isMobileScreen2}
              value={formik.values.password2}
              onChange={(event) => formik.setFieldValue('password2', event.target.value)}
            />

            <Divider orientation="vertical" />

            <Button
              data-test-id='btn-submit'
              type="submit"
              color="primary"
              variant="text"
              className={classes.loginBtnDesktop}
              disabled={isLoading2}>
              <span style={style.style1}>LOGIN</span>
            </Button>

          </div>


          <div className={classes.rememberMeContainer}>
            <FormControlLabel
              className={classes.rememberMeCheckbox}
              control={<Checkbox
                // @ts-ignore
                inputProps={{ 'data-test-id': 'rememberMe2' }}
                checked={formik.values.rememberMe2}
                onChange={(event) => formik.setFieldValue('rememberMe2', event.target.checked)}
                name="rememberMe2"
              />}
              label={<span style={style.style1}>Remember Me</span>}
            />

            <Link title='Forgot Password' to="/forgot-password" className={clsx(classes.errorText2, classes.forgotPasswodText2)}>
              <Typography style={style.style1}>
                Forgot Password ?
              </Typography>
            </Link>

            <HideContainer hideWhen={!errorMsg2}>
              <Typography className={clsx(classes.errorText2, classes.apierrorMsg2)}>
                {errorMsg2}
              </Typography>
            </HideContainer>
          </div>

          <Button
            data-test-id='btn-submit'
            type="submit"
            color="primary"
            variant="text"
            className={classes.loginBtnMobile}
            disabled={isLoading2}>
            {'LOGIN'}
          </Button>

        </Form>
      }
    </Formik>
  );

}
// Customizable Area End