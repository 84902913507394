import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";


export function appendToken(headers: any, token?: string) {
  if (headers && !headers?.['token']) {
    let _token = token || sessionStorage.getItem('token') || '';
    if (_token)
      headers['token'] = _token;
  }

  return headers;
}
export function appendJsonContentType(headers: any) {
  if (headers && !headers?.['Content-Type'])
    headers['Content-Type'] = 'application/json';

  return headers;
}

export function extractAPIResponse(message: Message): {
  messageId: string | undefined,
  responseJson: any | undefined,
  errors: any | undefined
} {

  try {
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let apiError = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const errors = apiError || responseJson?.error || responseJson?.errors || undefined;

      let messageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      return {
        responseJson: errors ? undefined : responseJson,
        errors: errors ? responseJson : undefined,
        messageId
      }
    }

    return {
      responseJson: undefined,
      errors: undefined,
      messageId: undefined,
    }
  } catch (error) {
    console.error(error);

    return {
      responseJson: undefined,
      errors: undefined,
      messageId: undefined,
    }
  }
}








interface MessageHandlersParams {
  message?: Message,
  handlers: {
    [id: string]: {
      onSuccess?: (response: any) => void,
      onError?: (errors: any) => void,
      afterAll?: (response: any) => void,
    }
  }
}

export function defineMessageHandlers(params: MessageHandlersParams) {


  const { handlers, message } = params;

  if (!message) return;

  const { responseJson, errors, messageId } = extractAPIResponse(message);

  if (messageId && handlers[messageId]) {

    const { onSuccess, onError, afterAll } = handlers[messageId];

    if (errors && onError)
      onError(errors);
    else if (responseJson && onSuccess)
      onSuccess(responseJson);

    if (afterAll)
      afterAll(responseJson)
  }
}
