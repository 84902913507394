import React from "react";
import { Box } from "@material-ui/core";

import MyTripController from "./MyTripsController";
import MyPreferencesSection from "./MyPreferences";

import { HideContainer, Process } from "../../../components/src/shared";
import { DocumentUpload as DocumentUploadSection } from "./DocumentUpload2";
import BookedTrips from "./BookedTrips.web";
import Invoice from "./Invoice.web";

class MyTrips extends MyTripController {
  render() {
    const { preActivatedIndex, setPreactivatedIndex } = this.props;

    return (
      <Box
        sx={{
          padding: { xs: "30px 0px", sm: "30px 0px", md: "0px", lg: "0px" },
        }}
      >
        <Process
          classed={true}
          setPreactivatedIndex={setPreactivatedIndex}
          preActivated={preActivatedIndex}
          subHeading="Menu"
          process={[
            {
              name: "MY TRIPS",
              para: "",
              discription: "FLIGHT LOG",
            },
            {
              name: "MY PREFERENCES",
              para: "",
              discription:
                "Gathered information regarding handling preferences.",
            },
            {
              name: "INVOICES",
              para: "",
              discription: "Gathered information regarding your invoices.",
            },
            {
              name: "DOCUMENT UPLOAD",
              para: "",
              discription: "Gathered information regarding your documents.",
            },
          ]}
        >
          {({ selectedIndex }: { selectedIndex: any }) => (
            <>
              <HideContainer hideWhen={selectedIndex != 0}>
                <BookedTrips />
              </HideContainer>

              <HideContainer hideWhen={selectedIndex != 1}>
                <MyPreferencesSection />
              </HideContainer>

              <HideContainer hideWhen={selectedIndex != 2}>
                <Invoice />
              </HideContainer>

              <HideContainer hideWhen={selectedIndex != 3}>
                <DocumentUploadSection />
              </HideContainer>
            </>
          )}
        </Process>
      </Box>
    );
  }
}

export default MyTrips;