// Customizable Area Start
import React, { useState, useEffect } from "react";
import AccountContent2 from "./AccountContent2.web";
import MyTrips from "./MyTrips.web";
import Book from "./Book.web";
import { Typography, useMediaQuery, Box } from "@material-ui/core";
import { useNavigate } from "../../../components/src/utils/router-context";

export default function AccountPages2({ accountAccount }: { accountAccount: any }) {
  const [activeAccount, setActiveAccount] = useState<string>("");
  const [preActivatedIndexAccount, setPreActivatedIndexAccount] = useState<number>(0);
  const isMobileScreenAccount = useMediaQuery("(max-width: 768px)");
  const navigateAccount = useNavigate();
  const hashAccount: string = window.location.hash;
  const stylesAccount = {
    linksContainerAccount: {
      gap: 20,
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center"
    } as React.CSSProperties,
    linkAccount: {
      color: "#C1C1C1",
      cursor: "pointer",
      whiteSpace : "nowrap",
      textUnderlineOffset: "5px",
    }as React.CSSProperties,
    activeLinkAccount: {
      color: "#2C62E0",
      cursor: "pointer",
      whiteSpace : "nowrap",
      textUnderlineOffset: "5px",
      textDecoration: "underline"
    } as React.CSSProperties,
    emptySpanAccount: {
      flexGrow: 1,
      display: isMobileScreenAccount ? "none" : "block"
    }
  };

  useEffect(() => {
    setActiveAccount(String(hashAccount).replace("#", ""));
  }, [hashAccount]);

  const changeTabAccount = (tabAccount: string, pathAccount: string) => {
    setActiveAccount(tabAccount);
    navigateAccount(pathAccount);
  };

  return (
    <>
      <Box
        className="rootContainer1"
        sx={{
          padding: { sm: "20px 40px", md: "20px 40px" },
          marginTop: isMobileScreenAccount ? "40px" : "100px"
        }}
      >
        <div style={stylesAccount.linksContainerAccount}>
          <Typography
            data-test-id="btn-myTrip"
            variant="h3"
            onClick={() => changeTabAccount("myTrip", "/account#myTrip")}
            style={hashAccount === "#myTrip" ? stylesAccount.activeLinkAccount : stylesAccount.linkAccount}
          >
            {"MY TRIPS"}
          </Typography>
          <Typography
            data-test-id="btn-book"
            variant="h3"
            onClick={() => changeTabAccount("book", "/account#book")}
            style={{
              ...(hashAccount === "#book" ? stylesAccount.activeLinkAccount : stylesAccount.linkAccount),
              marginLeft: isMobileScreenAccount ? "0px" : "59px"
            }}
          >
            {"BOOK"}
          </Typography>
          <span style={stylesAccount.emptySpanAccount} />
          <Typography
            data-test-id="btn-account"
            variant="h3"
            onClick={() => changeTabAccount("account", "/account#account")}
            style={hashAccount === "#account" ? stylesAccount.activeLinkAccount : stylesAccount.linkAccount}
          >
            {"ACCOUNT"}
          </Typography>
        </div>
      </Box>
      {(activeAccount === "account" || hashAccount === "#account") && accountAccount && (
        <AccountContent2
          preActivatedIndex={preActivatedIndexAccount}
          setPreactivatedIndex={setPreActivatedIndexAccount}
        />
      )}
      {(activeAccount === "myTrip" || hashAccount === "#myTrip") && (
        <MyTrips
          preActivatedIndex={preActivatedIndexAccount}
          setPreactivatedIndex={setPreActivatedIndexAccount}
        />
      )}
      {(activeAccount === "book" || hashAccount === "#book") && <Book />}
    </>
  );
}
// Customizable Area End