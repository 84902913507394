import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { makeHttpMessage } from '../../../components/src/utils/http-message';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
}

interface S {
  loading : boolean,
  error : string,
  invoice : any[]
}

interface SS {
  id: any;
}

export default class InvoiceControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchInvoiceId: string;
  downloadInvoiceId: string;

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      loading : false,
      error : '',
      invoice : []
    };
    this.fetchInvoiceId = ''
    this.downloadInvoiceId = ''

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId == this.fetchInvoiceId) {
        this.setState({
          loading: false
        })
        if (errorReponse) {
          this.setState({
            error: "SOMETHING WENT WRONG."
          })
        } else {
          (responseJson.length>0 && responseJson[0]?.trip_id) ?
          this.setState({
            error : '',
            invoice : responseJson
          })
          :
          this.setState({
            error : "NO INVOICE FOUND."
          })
        }
      }
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.fetchInvoice()
  }

  getCurrentTimeInISOFormat() {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const isoFormat = `${year}-${month}-${day}T${hours}:${minutes}Z`;
    return isoFormat;
  }

  handleDownload = async (link:string) => {
    const headers = {
      "Content-Type": 'application/pdf',
      "Authorization": "Bearer eyJraWQiOiIxNkVBQkQ5RS1BRjYyLTQ4NTEtODk5Qi1BM0UwMThGRjYxNDciLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJBM0JBQjZCQS1BNkYzLTQzRTktOTU3OS1GMTk4MkI0NDRCNzUiLCJhdmlkb21haW4iOiIuYXZpbm9kZS5jb20iLCJhdml0ZW5hbnQiOjEyNjA0LCJpc3MiOiJhdmlub2RlIiwiYXZpdHlwZSI6MTUsImF2aW5vbmNlIjoiYjE3ODk3OTktZTFmYi00MzJlLTg5ZGEtOTY0YTI5MjU2MjllIn0.L0MpR0SED0EOtOyL0_p7g4OMRCSapVfBHMTAW6iZ9nl6w2YAqk8HLCfKbkmkA9-pCATCd6tTYWjTKjdU-hGjJonvsuYypqomtb5uV2MQ_6UPhsqvSi3AwZGjb8MBWuSeeWaG1i19rmoKnJkzNwC-sSqise3wV-u9wrDryn1zYwVJFifu7rmJFoD4P92jG4rZyPyG3P8LxnfIDzU5q9YlGwMJvs1alZyrVbt-EAv-Gl3gYXeFYisBBoRQWLnaZxyawTPByCb-JjQG_7Qk0FUVWTNIoN-A8a7Q5nAhYsvSbHNpRpwpq1vIBK87MqkgUPV0BuMLBGply9u2Gd2tP9ljfg",
      "X-Avinode-ApiToken": "5abea39a-d477-4995-abd3-5f160119efea",
      "X-Avinode-SentTimestamp": this.getCurrentTimeInISOFormat()
    };
  
    try {
      const response = await fetch(link, {
        method: 'GET',
        headers: headers,
      });

      if (response.ok) {
        const pdfBlob = await response.blob();
        const downloadUrl = URL.createObjectURL(pdfBlob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'downloaded.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        console.error('API call failed:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  fetchInvoice() {
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": 'application/json',
      "token": sessionStorage.getItem('token')
    };

    const httpBody = {}

    const msg = makeHttpMessage({
      header,
      httpBody,
      httpMethod: 'GET',
      url: configJSON.apiEndPointInvoice
    });

    this.fetchInvoiceId = msg.messageId;

    runEngine.sendMessage(msg.id, msg);
  }
}
