import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { makeHttpMessage } from '../../../components/src/utils/http-message';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
}

interface S {
  error: string,
  info: any[],
  loading: boolean
}

interface SS {
  id: any;
}

export default class UserProfileControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  attachContactIDMsgId: string;
  fetchMyTipMsgId: string;

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      error: "",
      info: [],
      loading: false
    };
    this.attachContactIDMsgId = ''
    this.fetchMyTipMsgId = ''

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleLogout() {
    sessionStorage.clear()
    localStorage.clear()
    window.location.replace("/login")
  }

  attachHandling(responseJson: any) {
    if (responseJson.error) {
      this.setState({
        error: responseJson.error
      })
    } else if (responseJson.errors) {
      this.handleLogout()
    } else {
      this.setState({
        error: ""
      })
      this.fetchMyTrip()
    }
  }

  myTipHandling(responseJson: any) {
    if (responseJson.error || responseJson.message) {
      this.setState({
        error: responseJson.error || responseJson.message
      })
    } else if (responseJson.errors) {
      this.handleLogout()
    } else {
      this.setState({
        error: "",
        info: responseJson.data.map((item: any) => {
          return {
            arrival_airport: {
              data: {
                attributes: {
                  city: item.attributes.arrival_airport?.data.attributes.city || "<br/>",
                  icao_code: item.attributes.to_icao || "<br/>"
                }
              }
            },
            departure_airport: {
              data: {
                attributes: {
                  city: item.attributes.departure_airport?.data.attributes.city || "<br/>",
                  icao_code: item.attributes.from_icao || "<br/>"
                }
              }
            },
            availability: {
              aircraft_type: item.attributes.aircraft_type || "<br/>",
              from_date_utc: item.attributes.from_utc || "<br/>",
              to_date_utc: item.attributes.to_utc || "<br/>"
            }
          };
        })
      });
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId == this.attachContactIDMsgId) {
        this.setState({
          loading: false
        })
        if (errorReponse) {
          this.setState({
            error: "Something went wrong."
          })
        } else {
          this.attachHandling(responseJson)
        }
      }

      if (apiRequestCallId == this.fetchMyTipMsgId) {
        this.setState({
          loading: false
        })
        if (errorReponse) {
          this.setState({
            error: "Something went wrong."
          })
        } else {
          this.myTipHandling(responseJson)
        }
      }
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    if (sessionStorage.getItem("avinode_contact") === "null") {
      this.attachContactID()
    } else {
      this.fetchMyTrip()
    }
  }

  attachContactID() {
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": 'application/json',
      "token": sessionStorage.getItem('token')
    };

    const httpBody = {}

    const msg = makeHttpMessage({
      header,
      httpBody,
      httpMethod: 'GET',
      url: configJSON.apiEndPointAttachContactID
    });

    this.attachContactIDMsgId = msg.messageId;

    runEngine.sendMessage(msg.id, msg);
  }

  fetchMyTrip() {
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": 'application/json',
      "token": sessionStorage.getItem('token')
    };

    const httpBody = {}

    const msg = makeHttpMessage({
      header,
      httpBody,
      httpMethod: 'GET',
      url: configJSON.apiEndPointFetchMyTrip
    });

    this.fetchMyTipMsgId = msg.messageId;

    runEngine.sendMessage(msg.id, msg);
  }
}
