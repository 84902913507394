import { useJetZeroMutation } from "../../utils/react-query";
import { accountMessages } from "../message-generators/account.messages";
import { accountKeys } from "../query-keys/account.keys";

export function useLogin() {

  return useJetZeroMutation<Parameters<typeof accountMessages.login>[0]>({
    queryKey: accountKeys.login(),
    messageGenerator: accountMessages.login,
  });
}
