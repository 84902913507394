import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@material-ui/core";

import SuccessPageController from "./SuccessPageController";
import WorldClocks from "../../../components/src/shared/WorldClock";

import { FixedClockToLeftSideWrapper } from "../../termsconditions/src/ThankYouPage.web";

const imgCheck = require("../assets/view_check.svg");

class SuccessPage extends SuccessPageController {
  render(): React.ReactNode {
    return (
      <div>
        <Container>
          <div>
            <BlueTick>
              <img src={imgCheck} />
            </BlueTick>
            <SuccessText>You have successfully reset your password</SuccessText>
            <div style={{ marginTop: 33 }}>
              <StyledLink to={"/login"}>Back To Login</StyledLink>
            </div>
          </div>
          <WorldClockWrapperFix>
            <FixedClockToLeftSideWrapper>
              <WorldClocks color="white" />
            </FixedClockToLeftSideWrapper>
          </WorldClockWrapperFix>
        </Container>
      </div>
    );
  }
}

const WorldClockWrapperFix = styled("div")({
  // @ts-ignore
  textAlign: "left !important",
  paddingTop: "0px !important",
});

const SuccessText = styled("h3")({
  margin: 0,
  fontSize: 20,
  color: "#FFF",
  paddingTop: 75,
  fontWeight: 400,
  letterSpacing: 4,
  fontFamily: "Atyp",
  textAlign: "center",
  fontStyle: "normal",
  lineHeight: "normal",
  textTransform: "uppercase",
  "@media only screen and (max-width:1150px)": {
    paddingTop: 20,
    fontSize: 16,
  },
});

const BlueTick = styled("div")({
  width: 150,
  height: 150,
  display: "flex",
  margin: "0 auto",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#2C62E0",
  "& img": {
    padding: 15,
    maxWidth: "100%",
  },
  "@media only screen and (max-width:1150px)": {
    width: 64,
    height: 64,
  },
});

const Container = styled("div")({
  position: "relative",
  height: "calc(100vh - 100px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "black",
  marginTop: 100,
  "& #floating-world-clock": {
    padding: "0px !important",
  },
  "& > div": {
    paddingBottom: 0,
    padding: "20px 40px",
    textAlign: "center",
  },
  "@media only screen and (max-width: 959px)": {
    marginTop: 60,
    display: "block",
    height: "calc(100vh - 60px)",
    "& > div": {
      paddingTop: "216px !important",
      paddingBottom: "62px !important",
    },
  },
});

const StyledLink = styled(Link)({
  fontSize: 20,
  fontWeight: 400,
  color: "#2C62E0",
  fontFamily: "Atyp",
  fontStyle: "normal",
  textDecoration: "none",
  textTransform: "uppercase",
  "@media only screen and (max-width:1150px)": {
    fontSize: 15,
  },
});

export default SuccessPage;
