import React, { useRef, useState } from 'react'
import { Typography, Box, Button, CircularProgress } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
//@ts-ignore
import classes from './components.module.css';
import Founders from '../types/founders';
import { baseURL } from '../../../blocks/bulkuploading/src/BulkUploadingController';
import { HideContainer } from './HideContainer';


export const Draggable = ({ innerRef, rootClass = "", children }: { innerRef: any, rootClass: string, children: any }) => {
    const ourRef = useRef(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });
    const handleDragStart = (e: any) => {
        if (!ourRef.current) return
        //@ts-ignore
        const slider = ourRef?.current?.children[0];
        const startX = e.pageX - slider.offsetLeft;
        const startY = e.pageY - slider.offsetTop;
        const scrollLeft = slider.scrollLeft;
        const scrollTop = slider.scrollTop;
        mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
        setIsMouseDown(true)
        document.body.style.cursor = "grabbing"
    }
    const handleDragEnd: any = () => {
        setIsMouseDown(false)
        if (!ourRef.current) return
        document.body.style.cursor = "default"
    }
    const handleDrag = (e: any) => {
        if (!isMouseDown || !ourRef.current) return;
        e.preventDefault();
        //@ts-ignore
        const slider = ourRef.current.children[0];
        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const walkX = (x - mouseCoords.current.startX) * 1.5;
        const walkY = (y - mouseCoords.current.startY) * 1.5;
        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
        slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    }

    return (
        <div ref={ourRef} onMouseDown={handleDragStart} onMouseUp={handleDragEnd} onMouseMove={handleDrag} className={rootClass + "flex overflow-x-scroll"}>
            {children}
        </div>
    );
};

export function Carousel({ items, destination, book, onClickRequest, postPrivateFlightsLoading }: Readonly<{ items: Founders[] | undefined, destination?: boolean, book?: boolean, onClickRequest?: Function, postPrivateFlightsLoading?:boolean }>) {

    //Making responsive
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const journalRef = useRef(null);
    const [id, setId] = useState("")

    function calcPL(width: number) {
        if (1150 > width) {
            return 40
        } else if (1250 > width) {
            return width / 2 - 650 / 2
        } else if (1315 > width) {
            return width / 2 - 750 / 2
        } else if (1410 > width) {
            return width / 2 - 850 / 2
        } else if (1600 > width) {
            return width / 2 - 950 / 2
        } else return width / 2 - 1200 / 2
    }

    function calcBookPL(width: number) {
        if (1199 > width) {
            return 40
        } else return width / 2 - 1200 / 2
    }

    const calcPLFun = () => {
        return book ? calcBookPL(window.innerWidth) + 40 : calcPL(window.innerWidth) + 40
    }

    const styles = {
        container: {
            width: "100%",
            overflowX: "hidden",
            boxSizing: "border-box"
        } as React.CSSProperties,
        wrapperContainer: {
            overflowX: "scroll",
            display: "flex",
            alignItems: "center",
            gap: "30px",
            padding: matches ? "20px 40px" : `20px 80px 20px ${calcPLFun()}`
        } as React.CSSProperties,
        wrapper: {
            width: matches ? "234px" : "640px",
            height: matches ? "350px" : "960px",
            borderRadius: "20px",
            backgroundColor: "green",
            backgroundPosition: "center",
            backgroundSize: "cover"
        } as React.CSSProperties,
        textWrapper: {
            marginTop: matches ? "20px" : "50px",
        } as React.CSSProperties,
        contactWrapper: {
            display: "flex",
            flexDirection: matches ? "column" : "row",
            justifyContent: "space-between",
            alignItems: matches ? "flex-start" : "center",
            gap: "20px",
            marginTop: book ? "14px" : "5px"
        } as React.CSSProperties,
        lineWrapper: {
            flex: 1,
            position: "relative",
            display: "flex",
            width: "100%",
            alignItems: "center"
        } as React.CSSProperties,
        line: {
            width: "100%",
            height: "1px",
            backgroundColor: "#000000"
        } as React.CSSProperties,
        arrow: {
            display: matches ? "none" : "block",
            width: "9px",
            height: "9px",
            left: "calc(100% - 9px)",
            bottom: "-4px",
            margin: "0",
            position: "absolute",
            transform:"rotate(45deg)",
            borderRight: `1px solid #000000`,
            borderTop: `1px solid #000000`,
            [theme.breakpoints.down('sm')]: {
                left: "auto",
                right: "147px",
                width: "9px",
                height: "9px",
                bottom: "-4px",
            },
        } as React.CSSProperties
    }

    const calDate = (post: string) => {
        if (book) {
            return post.split("-").reverse().join("/")
        } else return post
    }

    return (
        <Box id='overlap-container' data-test-id="overlap-container" className={classes.carousel} style={styles.container}>
            <Draggable innerRef={journalRef} rootClass={"drag"}>
                <div className={classes.carousel} ref={journalRef} style={styles.wrapperContainer}>
                    {
                        items?.map((item) => {
                            return (
                                <Box key={item.attributes.name}>
                                    <Box style={{ ...styles.wrapper, backgroundImage: `url('${baseURL}${item.attributes.image.trim()}')` }}></Box>
                                    <Box style={styles.textWrapper}>
                                        <Typography variant='h3' style={{ textTransform: "uppercase", fontWeight: 600 }}>{calDate(item.attributes.position)}</Typography>
                                        <Box style={styles.contactWrapper}>
                                            {(!destination || (destination && !matches)) && <Typography variant='h3' color="primary" style={{ textTransform: "uppercase", fontWeight: 600 }}>{item.attributes.name}</Typography>}
                                            <Box style={styles.lineWrapper}>
                                                <Box style={styles.line}></Box>
                                                <div style={styles.arrow}></div>
                                            </Box>
                                            {
                                                destination ? 
                                                <Box style={{display:"flex", alignItems:"center", justifyContent:"space-between", width: matches ? "100%" : "auto"}}>
                                                    {matches && <Typography variant='h3' color="primary" style={{ textTransform: "uppercase", fontWeight: 600 }}>{item.attributes.name}</Typography>}
                                                    <Typography variant='h3' color="primary" style={{ textTransform: "uppercase", fontWeight: 600 }}>{item.attributes.content}</Typography>
                                                </Box>
                                                    : <Button onClick={(e: any) => { window.location.href = `mailto:${item.attributes.name}`; e.preventDefault() }} color="primary" style={{ textTransform: "uppercase", fontWeight: 600, padding: 0 }}><Typography variant='h3'>contact</Typography></Button>
                                            }
                                        </Box>
                                        {book && onClickRequest && (
                                            <div style={{ display: 'flex', gap: 16, justifyContent: 'end' }}>
                                                <Button style={{
                                                    fontSize: 16,
                                                    marginTop: 16,
                                                    fontWeight: 400,
                                                    borderRadius: 4,
                                                    cursor: 'pointer',
                                                    lineHeight: '100%',
                                                    padding: '10px 22px',
                                                    textTransform: 'uppercase',
                                                    fontFamily: "Atyp, sans-serif",
                                                }}
                                                data-test-id={`RequestButton-${item.id}`}
                                                color="primary"
                                                variant='outlined'
                                                disabled={item.attributes.requested}
                                                onClick={() => {
                                                    setId(item.id)
                                                    onClickRequest(item)
                                                }}>
                                                    {item.attributes.requested ? 'Requested' : "Request"}
                                                    <HideContainer hideWhen={!(postPrivateFlightsLoading && id===item.id)}>
                                                        <CircularProgress
                                                            size={20}
                                                            style={{ margin: "0 5px" }}
                                                            color="primary"
                                                        />
                                                    </HideContainer>
                                                </Button>
                                            </div>
                                        )}
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </div>
            </Draggable>
        </Box>
    )
}

