import MuiAccordion from '@material-ui/core/Accordion';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const JetZeroAccordion = withStyles((theme) => ({
    root: {
      paddingBottom : "5px",
      borderBottom: `0px`,
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      }
    },
    expanded: {},
  }))(MuiAccordion);
  
  
  
export const JetZeroAccordionSummary = withStyles({
    root: {
      marginBottom: -8,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
      padding: "0px"
    },
    content: {
      '&$expanded': {
        marginTop: '12px',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  
  
export const JetZeroAccordionDetails = withStyles({
    root: {
      padding: "30px 0px",
      display:'block',
      width:"90%",
      margin:"auto"
    },
  })(MuiAccordionDetails);