import React, { useEffect, useState } from "react";
//@ts-ignore
// Customizable Area Start
import { motion as m } from "framer-motion";
import {
  Box,
  Link,
  Button,
  Checkbox,
  TextField,
  makeStyles,
  Typography,
  useMediaQuery,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core";
import {
  HideContainer,
  Process,
  JetZeroAccordion,
  JetZeroAccordionSummary,
  JetZeroAccordionDetails,
  PasswordVisibility,
} from "../../../components/src/shared";
import {accountAccordian, valuesetDefault} from "../../../components/src/shared/DemoData"
import { useGetAccount, usePutAccount, useResetPassword, useVerifyPassword } from "../../../components/src/api/hooks/basicProfile.hooks";
import StyledSelect from "./StyledSelect";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { InputContainer, Headings } from "./MyPreferences";
import { useNavigate } from "../../../components/src/utils/router-context";
import { emailRegex } from "../../../components/src/fake-data/fake-impact";
export const configJSON = require("./config");

interface Props {
  setPreactivatedIndex: any;
  preActivatedIndex: number;
}

/*  NOTE: transferred this file from terms and condition block to user profile basic block */
function AccountContent2({
  setPreactivatedIndex,
  preActivatedIndex,
}: Props) {
  const isMobileScreenAccount = useMediaQuery("(max-width: 768px)");
  const [accountAccordianAccount, setAccountAccordianAccount] = useState<any[]>(accountAccordian);
  const [valueAccount, setValueAccount] = useState<any>("");

  const [showResetPasswordAccount, setShowResetPasswordAccount] = useState<boolean>(false)
  const [showNewPasswordAccount, setShowNewPasswordAccount] = useState<boolean>(false)
  const [showOldPasswordAccount, setShowOldPasswordAccount] = useState<boolean>(false)

  const [valueSetAccount, setValueSetAccount] = useState<any>(valuesetDefault);
  const navigateAccount = useNavigate()


  useEffect(() => {
    const e = {
      target: {
        name: "full_phone_number",
        value: valueAccount,
      },
    };
    handleChangeAccount(e);
  }, [valueAccount]);

  const handleChangeAccount = (e: any) => {
    const name = e.target.name;
    const obj = {
      ...valueSetAccount,
      [name]: e.target.value,
    };
    setValueSetAccount(obj);
  };

  const [errorAccount, setErrorAccount] = useState<string[]>([]);
  const [sucessAccount, setSucessAccount] = useState<string[]>([]);

  const useStylesAccount = makeStyles((theme) => ({
    arrowWAccount : {
      height: "9px",
      width: "9px",
      left: "calc(100% - 10px)",
      bottom: "-4px",
      position: "absolute",
      transform:"rotate(45deg)",
      margin: "0",
      borderTop: `1px solid ${theme.palette.primary.main}`,
      borderRight: `1px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.down('sm')]: {
          width: "9px",
          height: "9px",
          bottom: "-4px",
      },
    },
    subHeadingAccountAccount: {
      fontWeight: 400,
      fontSize: "20px",
      fontStyle: "normal",
      color: "#C1C1C1",
      lineHeight: "40px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
        lineHeight: "20px",
      },
    },
    sendButtonAccountAccount: {
      fontWeight: 400,
      cursor: "pointer",
      marginLeft: "40px",
      textAlign: "right",
      right : "-14px",
      position: "relative",
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0px",
      },
    },
    saveButtonAccountAccount: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      justifyContent: "flex-end",
    },
    arrowAccountAccount: {
      left: "-8px",
      fontSize: "16px",
      position: "relative",
    },
    arrowContainerResetPasswordAccount: {
      justifyItems: "flex-end",
      alignItems: "center",
      justifyContent: "flex-end",
      display: "flex",
      alignContent: "center",
      marginTop: "40px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px"
      },
    },
    fieldHeadingAccount: {
      textTransform: "uppercase",
      fontSize: "20px !important",
      [theme.breakpoints.down('sm')]: {
        fontSize: "12px !important"
      }
    },
  
    termsConditionsAccountAccount: {
      fontSize: "20px",
      fontWeight: 400,
      "@media only screen and (max-width: 1150px)": {
        fontSize: 17,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
    },
    subheaderTypoAccountAccount: {
      textTransform: "uppercase",
      fontSize: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
        textTransform: "none",
      },
    },
    arrowContainerAccountAccount: {
      display: "flex",
      marginBottom: "200px",
      alignContent: "center",
      alignItems: "center",
      justifyItems: "flex-end",
      justifyContent: "flex-end",
      marginTop: "60px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0px",
        marginBottom: "140px",
      },
    },
    headerTypoAccountAccount: {
      fontSize: "40px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
    numberTypoAccountAccount: {
      width: "50px",
      [theme.breakpoints.down("sm")]: {
        width: "50px",
      },
    },
    headingAccountAccount: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: "71px",
      color: "#FFFFFF",
      [theme.breakpoints.down("xs")]: {
        fontSize: "25px",
        lineHeight: "36px",
      },
    },
    blueLinesAccountAccount: {
      height: "1px",
      width: "40%",
      position:"relative",
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down("xs")]: {
        width: "40%",
      },
    },
  }));
  const classesAccount = useStylesAccount();
  const { mutate: putAccountAccount, isLoading } = usePutAccount();
  const {data:accountCallAccount, refetch:refetchAccountCallAccount} = useGetAccount()
  const {mutate: resetPasswordAccount, isLoading:isRestLoadingAccount} = useResetPassword()
  const {mutate: verifyPasswordAccount, isLoading:isVerifyLoadingAccount } = useVerifyPassword()

  const [expandedAccount, setExpandedAccount] = React.useState<string | false>("panel1");

  useEffect(()=>{
    if(accountCallAccount){
      setValueSetAccount(accountCallAccount.data.attributes)
      if (
        accountCallAccount.data.attributes.full_phone_number &&
        isValidPhoneNumber(`+${accountCallAccount.data.attributes.full_phone_number}`)
      ) {
        setValueAccount(`+${accountCallAccount.data.attributes.full_phone_number}`);
      }
    }
    
  },[accountCallAccount])

  const handleChangeSAccount = (panel: string) => (
    _event: React.ChangeEvent<{}>,
    newExpandedAccount: boolean
  ) => {
    setExpandedAccount(newExpandedAccount ? panel : false);
  };

  const placeErrorsAccount = (errorMessageAccount : any) => {
    setErrorAccount(["CHECK FOR ERRORS."])
    const updatedAccordian = accountAccordianAccount.map((sectionAccount) => {
      sectionAccount.inputFieldsAccount.forEach((inputField:any) => {
        const fieldNameAccount = inputField.nameAccount;
        if (errorMessageAccount.hasOwnProperty(fieldNameAccount)) {
          inputField.errorAccount = errorMessageAccount[fieldNameAccount].join(", ");
        } else {
          inputField.errorAccount = "";
        }
      });
      return sectionAccount;
    });
  
    setAccountAccordianAccount(updatedAccordian);
  }

  const reACSetErrorsAccount = () => {
    const updatedAccordianAccount = accountAccordianAccount.map((SectionsAccount:any) => {
      SectionsAccount.inputFieldsAccount.forEach((FieldAccount:any) => {
        FieldAccount.errorAccount = ""
      })
      return SectionsAccount;
    })
    setAccountAccordianAccount(updatedAccordianAccount)
  }

  const checkValidationAccount = () => {

    const requiredFieldsAccount = [
      'first_name',
      'last_name',
      'home_address_line_1',
      'home_address_city',
      'home_address_country',
      'home_address_zip_code',
      'pronoun',
      'full_phone_number',
      'email',
      'citizenship',
      'country_of_residence',
      'date_of_birth',
      'gender',
      'approx_weight',
      'passport_number',
      'nervous_flyer'
    ]

    let validateAccount : boolean = true

    const errorsAccount : any = {}

    requiredFieldsAccount.forEach((itemAccount:any) => {
      if(valueSetAccount[itemAccount] === "" || valueSetAccount[itemAccount] === null){
        errorsAccount[itemAccount] = ["CAN'T BE BLANK."]
      }
    })

    function isValidEmail(email:string) {
      const emailRege = emailRegex;
      return emailRege.test(email);
    }
    
    if(valueSetAccount['assistants_email'].length !==0 && !isValidEmail(valueSetAccount['assistants_email'])){
      errorsAccount['assistants_email'] = ["Invalid Email."]
    }

    if(!isValidEmail(valueSetAccount['email'])){
      errorsAccount['email'] = ["Invalid Email."]
    }

    if(Object.keys(errorsAccount).length>0){
      placeErrorsAccount(errorsAccount)
      validateAccount = false
    }

    return validateAccount
  }

  const fieldTypeAccount = (fieldsAccount:any) => {
    let fieldtypeAccount = "text"
    if(fieldsAccount.nameAccount === "date_of_birth"){
      fieldtypeAccount = "date"
    } else if(fieldsAccount.nameAccount === "confirmPassword"){
      fieldtypeAccount = showResetPasswordAccount ? "text" : "password"
    } else if(fieldsAccount.nameAccount === "newPassword"){
      fieldtypeAccount = showNewPasswordAccount ? "text" : "password"
    } else if(fieldsAccount.nameAccount === "oldPassword"){
      fieldtypeAccount = showOldPasswordAccount ? "text" : "password"
    }

    return fieldtypeAccount
  }

  const handleSubmitAccount = () => {
    setErrorAccount([]);
    setSucessAccount([]);
    reACSetErrorsAccount();
    if(isValidPhoneNumber(`${valueSetAccount["full_phone_number"]}`)){
      const payloadSetAccount = {
        "first_name": valueSetAccount["first_name"],
        "middle_name": valueSetAccount["middle_name"],
        "last_name": valueSetAccount["last_name"],
        "full_name": `${valueSetAccount["first_name"]} ${valueSetAccount["last_name"]}`,
        "home_address_line_1": valueSetAccount["home_address_line_1"],
        "home_address_line_2": valueSetAccount["home_address_line_2"],
        "home_address_city": valueSetAccount["home_address_city"],
        "home_address_country": valueSetAccount["home_address_country"],
        "home_address_zip_code": valueSetAccount["home_address_zip_code"],
        "company_address_line_1": valueSetAccount["company_address_line_1"],
        "company_address_line_2": valueSetAccount["company_address_line_2"],
        "company_address_city": valueSetAccount["company_address_city"],
        "company_address_country": valueSetAccount["company_address_country"],
        "company_address_zip_code": valueSetAccount["company_address_zip_code"],
        "pronoun": valueSetAccount["pronoun"],
        "company_name": valueSetAccount["company_name"],
        "company_address": valueSetAccount["company_address"],
        "phone_number": valueSetAccount["phone_number"],
        "full_phone_number": valueSetAccount["full_phone_number"],
        "email": valueSetAccount["email"],
        "assistants_email": valueSetAccount["assistants_email"],
        "citizenship": valueSetAccount["citizenship"],
        "country_of_residence": valueSetAccount["country_of_residence"],
        "date_of_birth": valueSetAccount["date_of_birth"],
        "gender": valueSetAccount["gender"],
        "approx_weight": valueSetAccount["approx_weight"],
        "passport_number": valueSetAccount["passport_number"],
        "nervous_flyer": valueSetAccount["nervous_flyer"],
        "terms": valueSetAccount["terms"],
        "policy": valueSetAccount["policy"]
      };
      checkValidationAccount() && putAccountAccount(payloadSetAccount, {
        onSuccess() {
            setSucessAccount(["ACCOUNT UPDATED SUCCESSFULLY."])
            refetchAccountCallAccount()
            setTimeout(()=>navigateAccount("/account#book"),1000)
        },
        onError(errorAccount) {
          const {errors : errorMessage} = errorAccount
          typeof(errorMessage) === 'object' ? placeErrorsAccount(errorMessage) : setErrorAccount([errorMessage])
        }
      });
    } else {
      placeErrorsAccount({
        full_phone_number : ["Invalid phone number."]
      })
    }
  };

  const passwordDuplicateAccount = () => {
    if((valueSetAccount["oldPassword"] ==  valueSetAccount["newPassword"])){
      placeErrorsAccount({
        newPassword : ["Old password is same as new."]
      })
    }
    if((valueSetAccount["newPassword"] !==  valueSetAccount["confirmPassword"])){
      placeErrorsAccount({
        confirmPassword : ["New and confirm passwords do not match."]
      })
    }
    return (valueSetAccount["newPassword"] !==  valueSetAccount["confirmPassword"]) || (valueSetAccount["oldPassword"] ==  valueSetAccount["newPassword"])
  }



  const handleResetAccount = () => {
    setErrorAccount([]);
    setSucessAccount([]);
    reACSetErrorsAccount()
    if(!passwordDuplicateAccount()){
      const payloadAccount = {
        "oldPassword": valueSetAccount["oldPassword"]
      }
      verifyPasswordAccount(payloadAccount, {
        onSuccess(){
          const payloadSetAccount = {
            "newPassword": valueSetAccount["newPassword"],
            "confirmPassword": valueSetAccount["confirmPassword"]
          };
          resetPasswordAccount(payloadSetAccount, {
            onSuccess() {
              localStorage.clear()
              sessionStorage.clear()
              navigateAccount("/login")
            },
            onError() {
              placeErrorsAccount({
                newPassword : ["Password must contain atleast 1 capital letter, small letter, symbol and number."]
              })
            }
          });
        },
        onError(){
          placeErrorsAccount({
            oldPassword : ["Old password is not correct."]
          })
        }
      })
    }
  };

  const summaryContentAccount = (itemAccount: any, indexAccount: number, isExpandedAccount: boolean) => {
    const errorAccount = itemAccount.inputFieldsAccount.map((itemAccount:any) => itemAccount.errorAccount).reduce((acc:any, curr:any) => acc || Boolean(curr), false)
    const variantsAccount = {
      shake: {
        x: [40, -40, 40, -40, 40, 0],
      },
      noShake: {
        x: 0,
      },
    };
    return (
      <JetZeroAccordionSummary>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #2C62E0",
            padding: { xs: "0px", sm: "0px", md: "10px 0px" },
          }}
        >
          <Typography
            variant="h4"
            className={classesAccount.numberTypoAccountAccount}
            color={errorAccount ? "error" : "primary"}
          >
            {String(indexAccount + 1).padStart(2, "0")}
          </Typography>
          <m.div
            style={{width:"100%"}}
            animate={
              errorAccount ? "shake" : "noShake"
            }
            variants={variantsAccount}
            transition={{
              type: "spring",
            }}
          >
            <Headings style={{color: errorAccount ? "#f44336" : "black"}} variant="h3">
              {itemAccount.headingAccount}
              {itemAccount.requiredAccount && (
                <span style={{ color: errorAccount ? "#f44336" : "rgba(44, 98, 224, 1)" }}>*</span>
              )}
            </Headings>
          </m.div>
          <div style={{ flexGrow: 1 }} />
          <Typography
            style={{
              width: "30px",
              textAlign: "center",
              fontSize: "30px",
              lineHeight: "40px",
              fontWeight: 600,
              color: errorAccount ? "#f44336" : "#2C62E0"
            }}
          >
            {isExpandedAccount ? "-" : "+"}
          </Typography>
        </Box>
      </JetZeroAccordionSummary>
    );
  };



  const detailContentAccount = (isMultipleFieldsAccount: boolean, fieldsAccount: any) => {

    let fieldtypeAccount = fieldTypeAccount(fieldsAccount)

    let elementAccount = (
      <TextField
        fullWidth
        required={fieldsAccount.requiredAccount}
        onKeyDown={(e) => {
          if (fieldsAccount.typeAccount === "number") {
            const regex = /^\d$/; // Regular expression to match a single digit
            if (!regex.test(e.key) && e.key !== "Backspace") {
              e.preventDefault(); // Prevent input if it doesn't match the regex and it's not the backspace key
            }
          }
        }}
        type={fieldtypeAccount}
        onChange={(e: any) => handleChangeAccount(e)}
        placeholder={fieldsAccount.placeHolderAccount}
        name={fieldsAccount.nameAccount}
        style={{ marginBottom: "10px", width:"100%" }}
        value={valueSetAccount[fieldsAccount.nameAccount]}
        InputProps={{
          disableUnderline: true,
          ...(fieldsAccount.nameAccount === "confirmPassword") && {
            endAdornment : <PasswordVisibility showPassword={showResetPasswordAccount} togglePassword={()=>setShowResetPasswordAccount(!showResetPasswordAccount)} />
          },
          ...(fieldsAccount.nameAccount === "newPassword") && {
            endAdornment : <PasswordVisibility showPassword={showNewPasswordAccount} togglePassword={()=>setShowNewPasswordAccount(!showNewPasswordAccount)} />
          },
          ...(fieldsAccount.nameAccount === "oldPassword") && {
            endAdornment : <PasswordVisibility showPassword={showOldPasswordAccount} togglePassword={()=>setShowOldPasswordAccount(!showOldPasswordAccount)} />
          }
        }}
        inputProps={{
          "data-test-id": "account-field-" + fieldsAccount.nameAccount,
          ...(fieldsAccount.nameAccount === "date_of_birth" && {
            max: new Date().toISOString().split("T")[0],
          }),
        }}
      />
    );

    if (fieldsAccount.typeAccount === "select") {
      elementAccount = (
        <StyledSelect
          style={{ marginLeft: 16 }}
          aria-label={"account-field-" + fieldsAccount.nameAccount}
          placeholder={fieldsAccount.placeHolderAccount}
          onChange={(e: any) =>
            handleChangeAccount({ target: { value: e.value, name: fieldsAccount.nameAccount } })
          }
          options={fieldsAccount.optionsAccount.map((option: any) => ({
            label: option.label,
            value: option.value,
          }))}
          value={fieldsAccount.optionsAccount.find(
            (item: any) => item.value === valueSetAccount[fieldsAccount.nameAccount]
          )}
          name={fieldsAccount.nameAccount}
          classNamePrefix={"custom-select"}
        />
      );
    }

    if (fieldsAccount.labelAccount === "Phone") {
      elementAccount = (
        <>
          <PhoneInput
            international
            value={valueAccount}
            onChange={setValueAccount}
            placeholder="Enter phone number"
            data-test-id={"account-field-" + fieldsAccount.nameAccount}
          />
          <Typography variant="h5" style={{ marginTop: "5px" }}>
            Do not add '0' before number
          </Typography>
        </>
      );
    }

    return (
      <Box sx={{ marginLeft: isMultipleFieldsAccount ? "60px" : "0px" }}>
        <InputContainer>{elementAccount}</InputContainer>
        <Typography variant="h5" color="error">
          {fieldsAccount.errorAccount}
        </Typography>
      </Box>
    );
  };

  const formLableAccount = (policyAccount?:boolean) => {
    return (
      <Box>
        <FormControlLabel
            style={{ marginTop:"5px" }}
            control={
              <Checkbox
                inputProps={{
                  'data-test-id': policyAccount ? 'account-field-privacy' : 'account-field-terms'
                } as any}
                color="primary"
                checked={Boolean(policyAccount ?  valueSetAccount["policy"] : valueSetAccount["terms"])}
                disabled={Boolean(policyAccount ? valueSetAccount["policy"] : valueSetAccount["terms"])}
                onChange={() => {
                  setValueSetAccount({
                    ...valueSetAccount,
                    [policyAccount ? "policy" : "terms"]: !valueSetAccount[policyAccount ? "policy" : "terms"]
                  })
                }}
              />
            }
            label={
              <Typography
                variant="body2"
                className={classesAccount.termsConditionsAccountAccount}
              >
                I understand and agree to the{" "}
                <Link
                  href="/privacyTerms"
                  data-test-id = "privacyButton"
                  target="_blank"
                  onClick={()=>{localStorage.setItem("token", `${sessionStorage.getItem("token")}`)}}
                  rel="noopener"
                  color="primary"
                  title={policyAccount ? `Privacy and policy` : `Terms and conditions`}
                >
                  {policyAccount ? `Privacy and policy` : `Terms and conditions`}
                </Link>
              </Typography>
            }
        />
      </Box>
    )
  }

  function convertToDDMMYYYYAccount(dateStringAccount:string) {
    const [year, month, day] = dateStringAccount.split('-');
    const formattedDateAccount = `${day}/${month}/${year}`;
  
    return formattedDateAccount;
  }

  const dupStyle = {
    overflow : {
      overflowX:"hidden"
    } as React.CSSProperties,
    initial : {
      x: "100%",
    },
    animate : {
      x: "0%",
    },
    transition : {
      duration: 0.75,
      ease: "easeOut",
    },
    box1 : { display: { xs: "none", sm: "none", md: "block" } },
    box2 : { padding: { sm: "20px 40px", md: "20px 40px" }, display: "block" },
    box3 : {
      padding: {
        xs: "30px 0px 20px 0px",
        sm: "70px 0px 50px 0px",
        md: "70px 0px 50px 0px",
      },
    },
    box3style : { width: "100%" }
  }

  const renderProcess = () => {
    return <Process
    classed
    isAccountPage
    setPreactivatedIndex={setPreactivatedIndex}
    preActivated={preActivatedIndex}
    subHeading="MENU"
    process={[
      {
        name: "MY TRIPS",
        para: "",
        discription: "Gathered information regarding your trips.",
      },
      {
        name: "MY PREFERENCES",
        para: "",
        discription:
          "Gathered information regarding handling preferences.",
      },
      {
        name: "INVOICES",
        para: "",
        discription: "Gathered information regarding your invoices.",
      },
      {
        name: "DOCUMENT UPLOAD",
        para: "",
        discription: "Gathered information regarding your documents.",
      },
    ]}
  />
  }

  return (
    <div style={dupStyle.overflow}>
      <m.div
        initial={dupStyle.initial}
        animate={dupStyle.animate}
        transition={dupStyle.transition}
      >
        <Box sx={dupStyle.box1}>
          {renderProcess()}
        </Box>
        <Box
          className="rootContainer1"
          sx={dupStyle.box2}
        >
          <Box
            sx={dupStyle.box3}
            style={dupStyle.box3style}
          >
            <Typography
              className={classesAccount.headerTypoAccountAccount}
              style={{
                color: "black",
                textAlign: "left",
                fontWeight: 400,
                textTransform: "uppercase",
                marginBottom: "5px",
              }}
            >
              {valueSetAccount.gender === "Female" ? "MRS" : "MR"} {valueSetAccount.full_name}
            </Typography>
            <Typography
              className={classesAccount.subheaderTypoAccountAccount}
              style={{ color: "rgba(193, 193, 193, 1)", textAlign: "left" }}
            >
              Gathered information regarding your invoices.
            </Typography>
          </Box>
          {accountAccordianAccount.map((itemAccount: any, indexAccount: number) => {
            const isExpandedAccount = expandedAccount === indexAccount.toString();
            const changesAccount = handleChangeSAccount(indexAccount.toString());
            let resetPasswordButtonAccount = (<></>)
            if (itemAccount.headingAccount === "Reset Password"){
              resetPasswordButtonAccount = (
                <Box className={classesAccount.arrowContainerResetPasswordAccount}>
                  <Box className={classesAccount.blueLinesAccountAccount}>
                    <div className={classesAccount.arrowWAccount}></div>
                  </Box>
                  <Button
                    data-test-id='btn-reset'
                    color="primary"
                    variant="text"
                    className={classesAccount.sendButtonAccountAccount}
                    type="submit"
                    onClick={handleResetAccount}
                  >
                    {"SAVE"}
                    <HideContainer hideWhen={!(isRestLoadingAccount || isVerifyLoadingAccount)}>
                      <CircularProgress
                        size={20}
                        style={{ margin: "0 5px" }}
                        color="primary"
                      />
                    </HideContainer>
                  </Button>
                </Box>
              )
            }

            return (
              <JetZeroAccordion
                data-test-id={"accordion-preferences-" + itemAccount.headingAccount}
                key={"account-accordion-key-" + itemAccount.headingAccount}
                expanded={isExpandedAccount}
                onChange={changesAccount}
              >
                {summaryContentAccount(itemAccount, indexAccount, isExpandedAccount)}
                <JetZeroAccordionDetails>
                  {itemAccount.inputFieldsAccount.map((fieldsAccount: any, fieldIndexAccount: number) => {
                    const fieldKeyAccount = `account-accordion-item-${itemAccount.headingAccount}-${fieldsAccount.labelAccount}`;
                    const isMultipleFieldsAccount = itemAccount.inputFieldsAccount.length > 1;

                  return (
                    <Box key={fieldKeyAccount} sx={{
                      borderBottom: "1px solid #2C62E0",
                      padding: { xs: '10px 0px 0px 0px', sm: "10px 0px 0px 0px", md: "10px 0px" }
                    }}>
                      {isMultipleFieldsAccount && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="h4" color="primary" style={{ width: "60px" }}>
                            {String(fieldIndexAccount + 1).padStart(2, '0')}
                          </Typography>
                          <Typography variant="h3" className={classesAccount.fieldHeadingAccount}>
                            {fieldsAccount.labelAccount}{fieldsAccount.requiredAccount && <span style={{ color: "rgba(44, 98, 224, 1)" }}>*</span>}
                          </Typography>
                        </Box>
                      )}
                      {detailContentAccount(isMultipleFieldsAccount, fieldsAccount)}
                    </Box>
                  );
                })}
                {resetPasswordButtonAccount}
              </JetZeroAccordionDetails>     
            </JetZeroAccordion>
          );
        })}
      </Box>
      <Box className="rootContainer1" sx={{ padding: { sm: "20px 40px", md: "20px 40px" }, display: "block" }}>
        <Box>
          {errorAccount.map((itemAccount) => {
            return (
              <Typography key={itemAccount} variant='h5' color='error'>{itemAccount}</Typography>
            )
          })}
          {sucessAccount.map((itemAccount) => {
            return (
              <Typography key={itemAccount} variant='h5' color='primary'>{itemAccount}</Typography>
            )
          })}
        </Box>
        <Box style={{marginTop: isMobileScreenAccount ? "5px" : "30px"}}>
          {formLableAccount(true)}
          {formLableAccount(false)}
          {
            valueSetAccount.first_time_selection_at &&
            <Typography style={{marginTop:"5px"}} variant='h3' color='secondary'>Accepted at {convertToDDMMYYYYAccount(valueSetAccount.first_time_selection_at)}</Typography>
          }
        </Box>
        <Box className={classesAccount.arrowContainerAccountAccount}>
          <Box className={classesAccount.blueLinesAccountAccount}><div className={classesAccount.arrowWAccount}></div></Box>
          
          <Button
            data-test-id='btn-save'
            color="primary"
            variant="text"
            className={classesAccount.sendButtonAccountAccount}
            type="submit"
            onClick={handleSubmitAccount}
          >
            {"SAVE"}
            <HideContainer hideWhen={!isLoading}>
              <CircularProgress
                size={20}
                style={{ margin: "0 5px" }}
                color="primary"
              />
            </HideContainer>
          </Button>
        </Box></Box>
    </m.div></div>
  );
}

export default AccountContent2;
// Customizable Area End