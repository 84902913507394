import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// @ts-ignore
import ReactHtmlParser from 'react-html-parser';

// @ts-ignore
import * as DOMPurify from 'dompurify';


/*****************/

const useEmStyles = makeStyles({
  em: {
    borderBottom: '2px solid red'
  }
});

function em(node: any, index: number) {

  const classes = useEmStyles();

  return (
    <em className={classes.em} key={index}>
      {node?.children?.[0]?.data}
    </em>
  );
}

/*****************/

const useUStyles = makeStyles({
  u: {
    color: 'green'
  }
});

function u(node: any, index: number) {

  const classes = useUStyles();

  return (
    <u className={classes.u} key={index}>
      {node?.children?.[0]?.data}
    </u>
  );
}

/*****************/

const usePStyles = makeStyles({
  p: {
    display: 'inline',
    color: 'red',
  }
});

function p(node: any, index: number) {

  const classes = usePStyles();

  return (
    <p className={classes.p} key={index}>
      {node?.children?.[0]?.data}
    </p>
  );
}

/*****************/
const parsedElements: { [elementName: string]: (node: any, index: number) => any } = {
  //p,
}

/*****************/

const htmlParserOptions = {

  transform(node: any, index: any) {


    if (node.type == "tag") {
      let name = node?.name?.toLowerCase();
      if (parsedElements[name]) {
        return parsedElements[name](node, index)
      }
    }

  },
}

/*****************/

export function HTMLParser({ content }: { content: any }) {

  if (content===undefined || !content.length)
    return null;

  const parsed = ReactHtmlParser(DOMPurify.sanitize(content), htmlParserOptions);;

  return (<> {parsed} </>);
}
