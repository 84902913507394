import { makeHttpMessage } from "../../utils/http-message"
import { appendJsonContentType } from "../../utils/http-response";

export const accountMessages = {

  login(payload: { email: string, password: string }) {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: 'bx_block_login/logins',
      httpMethod: 'POST',
      httpBody: {
        "data": {
          "type": "email_account",
          "attributes": payload
        }
      }
    });
  },
}
