import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, styled, useMediaQuery } from "@material-ui/core";
import WorldClocks from "../../../components/src/shared/WorldClock";

const useStyles = makeStyles(theme => ({
  container: {
    padding: "20px 110px",
    margin: "auto",
    color: "white",
    height: "100vh",
    width: "100%",
    display: "grid",
    gridTemplateAreas: '"empty" \n "welcome" \n "scroll-arrow"',
    gridTemplateColumns: "repeat(1, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: '"empty" \n "welcome" \n "name"',
      gridTemplateColumns: "100%",
      padding: "20px 40px"
    }
  },
  wrapper: {
    position: "relative",
    background: "black",
    borderRadius: "0px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0px 0px 20px 20px"
    }
  },
  empty: {
    gridArea: "empty"
  },
  welcomeTextContainer: {
    gridArea: "welcome",
    justifySelf: "start",
    alignSelf: "center"
  },
  welcomeTexts: {},
  nameTextContainer: {
    gridArea: "name",
    justifySelf: "start",
    alignSelf: "center"
  },
  scrollArrow: {
    gridArea: "scroll-arrow",
    justifySelf: "start",
    alignSelf: "end",
    padding: "0 0 3vh 0",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  layoutContent: {
    padding: "5vh 0vh",
    margin: "0 auto",
    width: "100%"
  },
  nameText: {
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: "4.3px",
    fontSize: "1.4rem"
  },
  welcomeText: {
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: "4.3px",
    fontSize: "1.4rem"
  },
  pronoun: {
    color: theme.palette.primary.main,
    paddingRight: "20px"
  },
  arrowContainer: { position: "relative" },
  line: { height: "1px", background: "var(--primary-color)", position:"relative" },

  arrow: {
    width: "9px",
    height: "9px",
    left: "calc(100% - 10px)",
    bottom: "-4px",
    margin: "0",
    position: "absolute",
    transform:"rotate(45deg)",
    borderRight: `1px solid ${theme.palette.primary.main}`,
    borderTop: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down('sm')]: {
        width: "9px",
        height: "9px",
        bottom: "-4px",
    },
  },
  scrollText: {
    paddingBottom: "39px",
    textTransform: "uppercase",
    fontWeight: 400,
    letterSpacing: "0.2rem"
  },
  welcomeContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  welcomeWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "7px",
    flexDirection: "row",
    marginRight: "150px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "50px",
      marginRight: "0px"
    }
  }
}));

export function AccountLayout({
  name,
  gender
}: {
  name: string;
  gender: string;
}) {
  const classes = useStyles();
  const mobile = useMediaQuery("(max-width: 959px)");

  return (
    <>
      <div className={classes.wrapper}>
        <Container mobile={mobile}>
          <WorldClocks color="white" account />
        </Container>
        <div className={classes.container}>
          <div className={classes.empty} />

          <div className={classes.welcomeTextContainer}>
            <div className={classes.welcomeContainer}>
              <div className={classes.welcomeWrapper}>
                <Typography
                  className={classes.welcomeTexts}
                  variant="h3"
                  style={{
                    letterSpacing: "0.2em",
                    fontWeight: 400,
                    textTransform: "uppercase"
                  }}
                >
                  {"welcome"}
                </Typography>
                <Typography
                  className={classes.welcomeTexts}
                  variant="h3"
                  style={{
                    letterSpacing: "0.2em",
                    fontWeight: 400,
                    textTransform: "uppercase"
                  }}
                >
                  {"to global"}
                </Typography>
              </div>
              <Typography
                variant="h3"
                style={{
                  letterSpacing: "0.2em",
                  fontWeight: 400,
                  textTransform: "uppercase",
                  paddingBottom: mobile ? 243 : 0
                }}
              >
                <span style={{ color: "rgba(44, 98, 224, 1)" }}>
                  {gender === "Female" ? "MRS " : "MR "}
                </span>
                {name}
              </Typography>
            </div>
          </div>

          <div className={classes.scrollArrow}>
            <Typography className={classes.scrollText} variant="h4">
              {"scroll to discover more"}
            </Typography>

            <div className={classes.arrowContainer}>
              <div className={classes.line}><div className={classes.arrow}></div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const Container = styled("div")(({ mobile }: any) => ({
  ...(!mobile
    ? {}
    : {
        "& #floating-world-clock": {
          right: "unset",
          left: 40,
          bottom: 70
        }
      })
}));
