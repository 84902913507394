import React from "react";
import { makeStyles, } from "@material-ui/core/styles";
import { HideContainer } from "../../../components/src/shared";
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'relative',
  },
  input: {
    fontFamily: 'Atyp',
    width: '100%',
    padding: '5px',
    background: 'transparent',
    border: 'none',
    fontSize : "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize : "10px",
    },
    '&:focus-visible': {
      border: 'none',
      outline: 'none',
    },
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    '&:focus + label': {
      opacity: 0,
    },
    '&:valid + label': {
      opacity: 0,
    },
  },
  label: {
    fontSize : "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize : "15px",
    },
    whiteSpace: 'nowrap',
    fontFamily: 'Atyp',
    position: 'absolute',
    bottom: '5px',
    left: 0,
    color: '#c1c1c1',
    cursor: 'text',
    transition: 'all 0.2s ease-in-out',
  },
}));

export function JetZeroCustomInput(props: any) {

  const { placeholder, ...inputProps } = props;
  const classes = useStyles();

  return (
    <div id='jetZeroInputContainer' className={classes.container}>

      <input  {...inputProps} className={clsx(classes.input, inputProps.className)} placeholder={placeholder}/>

    </div>
  );
}
