import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { makeHttpMessage } from '../../../components/src/utils/http-message';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  error:string
  email:string
  url:string
  sucess:string
  timer:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendForgotRequestMsgId:string
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      error : "",
      email : "",
      url: "",
      sucess: "",
      timer: true
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      let apiForgotRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseForgotJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiForgotRequestCallId == this.sendForgotRequestMsgId) {
        if(responseForgotJson.errors){
          this.setState({
            error: responseForgotJson.errors
          })
        } else {
          this.setState({
            sucess: responseForgotJson.message
          })
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      url : window.location.href.split("/forgot-password")[0]
    })
  }

  // Customizable Area Start
  sendRequest(){
    this.setState({
      error:"",
      sucess: "",
      timer: true
    })

    setTimeout(()=>{
      this.setState({
        timer: false
      })
    },120000)

    if(this.state.email === ""){
      this.setState({
        error : "Email is required."
      })
    } else {
      const header = {
        "Content-Type" : 'application/json'
      };
  
      const httpBody = {
        "url" : this.state.url,
        "email" : this.state.email
      }
  
      const msg = makeHttpMessage({
        header,
        httpBody,
        httpMethod : 'POST',
        url : `/bx_block_login/forgot_password?email=${this.state.email}`
      });
  
      this.sendForgotRequestMsgId = msg.messageId;
  
      runEngine.sendMessage(msg.id, msg);
    }
  }
  // Customizable Area End
}
