import React from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import {VisibilityOff, Visibility} from "@material-ui/icons";

interface Props {showPassword:boolean, togglePassword:()=>void}

export function PasswordVisibility({showPassword, togglePassword}:Props) {
    return (
        <InputAdornment position="end">
            <IconButton data-test-id="toggleBtn" onClick={()=>togglePassword()}>
                {
                showPassword ? 
                    <Visibility color="primary" /> : 
                    <VisibilityOff color="primary" />
                }
            </IconButton>
        </InputAdornment>
    )
}
