import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

interface Props {
  preActivatedIndex: number;
  setPreactivatedIndex: React.Dispatch<React.SetStateAction<number>>;
}
interface SS { }
interface S {
  filterType: string;
}

export interface IFilterItem {
  value: string;
  label: string;
}

class MyTripsController extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

}

export default MyTripsController;
