import { makeHttpMessage } from "../../utils/http-message"
import { makeHttpFormMessage } from "../../utils/http-messageform";
import { appendJsonContentType } from "../../utils/http-response";
import FormData from 'form-data';

export const emptyLegsMessages = {

  getEmptyLegsSearchFilter({
    page,
    date,
    fromIcao,
    toIcao,
  }: {
    page: number;
    date: string;
    fromIcao: string;
    toIcao: string;
  }) {
    return makeHttpMessage({
      header: appendJsonContentType({
        token: sessionStorage.getItem("token"),
      }),
      url: `/bx_block_cfavinodeintegration/avinode_apis/search_flight_legs?page=${page}&date_time=${date}&from_airport_icao=${fromIcao}&to_airport_icao=${toIcao}`,
      httpMethod: "GET",
    });
  },

  getEmptyLegs({ fromDate, toDate }: { fromDate: string, toDate: string }) {
    return makeHttpMessage({
      header: appendJsonContentType({
        "token": sessionStorage.getItem('token')
      }),
      url: `/bx_block_cfavinodeintegration/avinode_apis/availabilities_list?time_from=${fromDate}&time_to=${toDate}`,
      httpMethod: 'GET',
    });
  },

  getAllTrips(value:string) {
    return makeHttpMessage({
      header: appendJsonContentType({
        "token": sessionStorage.getItem('token')
      }),
      url: `/bx_block_cfavinodeintegration/avinode_apis/get_all_trips`,
      httpMethod: 'GET',
    });
  },

  getAllEmptyLegs(pageNumber: number = 1) {
    return makeHttpMessage({
      header: appendJsonContentType({
        "token": sessionStorage.getItem('token')
      }),
      url: `/bx_block_cfavinodeintegration/avinode_apis/all_empty_legs?page=${pageNumber}`,
      httpMethod: 'GET',
    });
  },
  postLegRequest(id: number) {
    const fc = new FormData();
    fc.append("data[avinode_request_id]", id)

    return makeHttpFormMessage({
      header: {},
      url: '/bx_block_booking/flight_requests',
      httpMethod: 'POST',
      httpBody: fc
    });
  },

  postPrivateFlights(id: number) {
    return makeHttpFormMessage({
      header: appendJsonContentType({
        "token": sessionStorage.getItem('token')
      }),
      url: 'bx_block_self_content/requested_feature_flights?data[featured_private_flight_id]=' + id,
      httpMethod: 'post'
    });
  },
}

export const resetPasswordMessage = {
  resetPassword(payload: {
    "newPassword": string,
    "confirmPassword": string
  }) {
    return makeHttpMessage({
      header: appendJsonContentType({
        "token": sessionStorage.getItem('token')
      }),
      url: `bx_block_login/reset_password?password=${payload.newPassword}&password_confirmation=${payload.confirmPassword}`,
      httpMethod: 'POST',
      httpBody: {}
    });
  },
  verifyPassword(payload: {
    "oldPassword": string,
  }) {
    return makeHttpMessage({
      header: appendJsonContentType({
        "token": sessionStorage.getItem('token')
      }),
      url: `/bx_block_login/verify_password?password=${payload.oldPassword}`,
      httpMethod: 'POST',
      httpBody: {}
    });
  }
}

export const putAccountMessage = {

  putAccount(payload: {
    "first_name": string,
    "middle_name": string,
    "last_name": string,
    "full_name": string,
    "home_address_line_1": string,
    "home_address_line_2": string,
    "home_address_city": string,
    "home_address_country": string,
    "home_address_zip_code": string,
    "company_address_line_1": string,
    "company_address_line_2": string,
    "company_address_city": string,
    "company_address_country": string,
    "company_address_zip_code": string,
    "pronoun": string,
    "company_name": string,
    "company_address": string,
    "full_phone_number": string,
    "email": string,
    "assistants_email": string,
    "citizenship": string,
    "country_of_residence": string,
    "date_of_birth": string,
    "gender": string,
    "approx_weight": string,
    "passport_number": string,
    "nervous_flyer": string
  }) {

    return makeHttpMessage({
      header: appendJsonContentType({
        "token": sessionStorage.getItem('token')
      }),
      url: '/account_block/accounts/edit',
      httpMethod: 'PUT',
      httpBody: {
        "data": payload
      }
    });
  },
  getAccount() {
    return makeHttpMessage({
      header: appendJsonContentType({
        "token": sessionStorage.getItem('token')
      }),
      url: `/account_block/my_account`,
      httpMethod: "GET",
    })
  },

  getFeatured() {
    return makeHttpMessage({
      header: appendJsonContentType({"token": sessionStorage.getItem('token')}),
      url: `/bx_block_self_content/featured_private_flight`,
      httpMethod: "GET",
    })
  },
}

export const getUploadDocumentMessage = {
  getUploadDocument() {
    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: `/bx_block_document/fetch_documents?token=${sessionStorage.getItem('token')}`,
      httpMethod: "GET",
    })
  },
  getWorldClock() {
    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: `/bx_block_settings/settings/world_clock`,
      httpMethod: "GET",
    })
  }
}


export const preferenceMessage = {
  getAirports(term: string) {
    return makeHttpMessage({
      header: appendJsonContentType({
        "token": sessionStorage.getItem('token')
      }),
      url: `/bx_block_cfavinodeintegration/avinode_apis/airports_list?page=1&search=${term}`,
      httpMethod: "GET"
    })
  },
  getFavAirports() {
    return makeHttpMessage({
      header: appendJsonContentType({
        token: sessionStorage.getItem('token')
      }),
      url: `/bx_block_cfavinodeintegration/favourite_airports`,
      httpMethod: "GET"
    })
  },
  getAircrafts(term: string) {
    return makeHttpMessage({
      header: appendJsonContentType({
        "token": sessionStorage.getItem('token')
      }),
      url: `/bx_block_cfavinodeintegration/avinode_apis/aircrafts_list?page=1&search=${term}`,
      httpMethod: "GET"
    })
  },
  getFavAircrafts() {
    return makeHttpMessage({
      header: appendJsonContentType({
        token: sessionStorage.getItem('token')
      }),
      url: `/bx_block_cfavinodeintegration/favourite_aircrafts`,
      httpMethod: "GET"
    })
  },
  getVehicleType() {
    return makeHttpMessage({
      header: appendJsonContentType({
        token: sessionStorage.getItem('token')
      }),
      url: `/bx_block_profile_bio/ground_transports/vehicle_preference_type`,
      httpMethod: "GET"
    })
  },
  getProvider() {
    return makeHttpMessage({
      header: appendJsonContentType({
        token: sessionStorage.getItem('token')
      }),
      url: `/bx_block_profile_bio/ground_transports/preferred_provider`,
      httpMethod: "GET"
    })
  },
  getSuperCar() {
    return makeHttpMessage({
      header: appendJsonContentType({
        token: sessionStorage.getItem('token')
      }),
      url: `/bx_block_profile_bio/ground_transports/preferred_supercar`,
      httpMethod: "GET"
    })
  },
}

export const userMessage = {
  getUsersPreferences() {
    return makeHttpMessage({
      header: appendJsonContentType({
        token: sessionStorage.getItem('token')
      }),
      url: `/bx_block_profile_bio/get_user_preference`,
      httpMethod: "GET"
    })
  },
  postUsersPreferences(payload: any) {
    return makeHttpMessage({
      header: appendJsonContentType({
        token: sessionStorage.getItem('token')
      }),
      url: `/bx_block_profile_bio/create_customer_info`,
      httpMethod: "POST",
      httpBody: payload
    })
  }
}

export const hotelKeyMessage = {
  getHotels() {
    return makeHttpMessage({
      header: {},
      url: `/bx_block_hotel/hotels`,
      httpMethod: "GET"
    })
  },
  getFavHotels() {
    return makeHttpMessage({
      header: appendJsonContentType({
        token: sessionStorage.getItem('token')
      }),
      url: `/bx_block_hotel/hotels/get_user_hotels`,
      httpMethod: "GET"
    })
  },
}

export const postPreferenceMessage = {
  postFavAirport(payload: {
    "airport_id": any,
  }) {

    const fc = new FormData();
    fc.append("data[airport_id]", payload.airport_id)

    return makeHttpFormMessage({
      header: {},
      url: '/bx_block_cfavinodeintegration/favourite_airports',
      httpMethod: 'POST',
      httpBody: fc
    });
  },
  delFavAirport(id: string) {
    return makeHttpFormMessage({
      header: {},
      url: `/bx_block_cfavinodeintegration/favourite_airports/${id}`,
      httpMethod: 'DELETE',
      httpBody: {}
    });
  },
  postFavAircraft(payload: {
    "aircraft_id": any,
  }) {

    const fc = new FormData();
    fc.append("data[aircraft_id]", payload.aircraft_id)

    return makeHttpFormMessage({
      header: {},
      url: '/bx_block_cfavinodeintegration/favourite_aircrafts',
      httpMethod: 'POST',
      httpBody: fc
    });
  },
  delFavAircraft(id: any) {
    return makeHttpMessage({
      header: appendJsonContentType({
        token: sessionStorage.getItem('token')
      }),
      url: `bx_block_cfavinodeintegration/favourite_aircrafts/${id}`,
      httpMethod: 'DELETE',
      httpBody: {}
    });
  },
}

export const putDocumentMessage = {
  putDocument(payload: {
    "card_number": any,
    "card_expiry_date": any,
    "cvv": any,
    "credit_card_type": any,
    "customer_name": any,
    "nationality": any,
    "country_of_issue": any,
    "issue_date": any,
    "passport_number": any,
    "passport_expiry_date": any,
    "passport_front": any,
    "passport_back": any,
    "credit_card_back": any,
    "credit_card_front": any,
  }) {

    const fc = new FormData();
    fc.append("token", sessionStorage.getItem("token"))
    if (payload.credit_card_front !== undefined) {
      fc.append("credit_card_front", payload.credit_card_front)
    }
    if (payload.credit_card_back !== undefined) {
      fc.append("credit_card_back", payload.credit_card_back)
    }
    if (payload.nationality) {
      fc.append("nationality", payload.nationality)
    }
    if (payload.card_number) {
      fc.append("card_number", payload.card_number)
    }
    if (payload.card_expiry_date) {
      fc.append("card_expiry_date", payload.card_expiry_date)
    }
    if (payload.credit_card_type) {
      fc.append("credit_card_type", payload.credit_card_type)
    }
    if (payload.cvv) {
      fc.append("cvv", payload.cvv)
    }
    if (payload.customer_name) {
      fc.append("customer_name", payload.customer_name)
    }
    if (payload.country_of_issue) {
      fc.append("country_of_issue", payload.country_of_issue)
    }
    if (payload.issue_date) {
      fc.append("issue_date", payload.issue_date)
    }
    if (payload.passport_number) {
      fc.append("passport_number", payload.passport_number)
    }
    if (payload.passport_expiry_date) {
      fc.append("passport_expiry_date", payload.passport_expiry_date)
    }
    if (payload.passport_front !== undefined) {
      fc.append("passport_front", payload.passport_front)
    }
    if (payload.passport_back !== undefined) {
      fc.append("passport_back", payload.passport_back)
    }


    return makeHttpFormMessage({
      header: {},
      url: '/bx_block_document/documents',
      httpMethod: 'POST',
      httpBody: fc
    });
  },
}
