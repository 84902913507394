import { build, perBuild, sequence } from "@jackfranklin/test-data-bot"
import { fakeString, fakeNumber, generateArrayOf } from "./fake-utils";
import { ImpactResponse, JetZeroImpact, ImpactTimeline, ImpactTimelineAttributes } from '../types/impact';

const impactTimelineAttrBuilder = build<ImpactTimelineAttributes>({
  fields: {
    title: fakeString,
    heading: fakeString,
    description: fakeString,
    order: fakeNumber,
    impact_id: fakeNumber,
  }
});

export const emailRegex = /[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,3}$/


const impactTimelineBuilder = build<ImpactTimeline>({
  fields: {
    id: sequence(x => String(x)),
    type: "impact_timeline",
    attributes: perBuild(impactTimelineAttrBuilder),
  }
});

////////////////////////

const impactAttrBuilder = build<JetZeroImpact['attributes']>({
  fields: {
    title: fakeString,
    heading: fakeString,
    description: fakeString,
    timeline_title: fakeString,
    image: fakeString,
    impact_timelines: { data: generateArrayOf(impactTimelineBuilder) }
  }
});


export const impactBuilder = build<JetZeroImpact>({
  fields: {
    id: sequence(x => String(x)),
    type: "impact",
    attributes: perBuild(impactAttrBuilder),
  }
});

export const fakeImpactResponse: ImpactResponse = {
  data: generateArrayOf(impactBuilder, 1)
}
