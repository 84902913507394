import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField as MaterialTextField } from '@material-ui/core';

export const JetZeroTextField = withStyles((theme) => ({
  root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.primary.main
    },

    '& input': {
      color: 'white',
      fontWeight: 600,
      position:"relative",
      top:"1px",

      '&::placeholder': {
        color: 'white',
        fontWeight: 600,
        opacity: 0.6,
      }
    },


  },
}))(MaterialTextField);
