import React from 'react';
import { Typography, useMediaQuery, styled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { HTMLParser } from '../../../components/src/utils/html-parser';

const useTripInfoStyles = makeStyles((theme) => ({
  rootEmptyTripInfo: {
    width: '100%',
    display: 'grid',
    gridGap: 8,
    gridTemplateColumns: '150px 1fr 150px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: '"plane plane" "info info"',
    },
  },
  airportInfoEmptyTripInfo: {
    '& p:first-child': {
      color: 'black',
    },
    '& p': {
      color: theme.palette.secondary.main,
      fontWeight: '600',
      textTransform: 'uppercase',
      margin: '0',
    },
    [theme.breakpoints.down('sm')]: {
      gridArea: "info",
    },
  },
  airportSmallInfoEmptyTripInfo: {
    '& p:first-child': {
      color: 'black',
    },
    '& p': {
      color: theme.palette.secondary.main,
      fontWeight: '600',
      textTransform: 'uppercase',
      margin: '0',
    }
  },
  leftAlignedEmptyTripInfo: {
    '& p': {
      textAlign: 'left',
    },
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'start',
    },
  },
  rightAlignedEmptyTripInfo: {
    '& p': {
      textAlign: 'right',
    },
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'end',
    },
  },
  airPlaneContainerEmptyTripInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    paddingBottom: "15px",
    '& p': {
      color: theme.palette.primary.main,
      fontWeight: '600',
      textTransform: 'uppercase',
      margin: '0',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      gridArea: "plane",
    },
  },
  airPlaneContainerReverseEmptyTripInfo: {
    flexDirection: 'row-reverse',
  },
  planeImgEmpty: {
    position: 'absolute',
    width: 'var(--plane-img-size)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '70%',
    },
  },
  planeSmallImgEmptyTripInfo: {
    width: "100%",
  },
}));
interface Props {
  type: string; // 'arrive' | 'departure'
  TripInfo: any;
}

export default function Trips({ type, TripInfo }: Props) {
  const classes = useTripInfoStyles();
  const isMobileScreenTrip = useMediaQuery('(max-width: 768px)');

  const arrivalTrip = TripInfo.arrival_airport.data.attributes
  const departureTrip = TripInfo.departure_airport.data.attributes
  const availabilityTrip = TripInfo.availability

  const convertDateTrip = (dateTrip: string) => {
    const [dateComponentTrip] = dateTrip.split("T");
    const [yearTrip, monthTrip, dayTrip] = dateComponentTrip.split("-");
    return `${dayTrip}/${monthTrip}/${yearTrip}`;
  }

  return (
    TripInfo && (
      <div>
        <div className={classes.rootEmptyTripInfo}>

          {!isMobileScreenTrip && (
            <div className={clsx(classes.airportInfoEmptyTripInfo, classes.leftAlignedEmptyTripInfo)}>
              <FlightDetailsTrip typeTrip={availabilityTrip.aircraft_type} timecodeTrip={convertDateTrip(availabilityTrip.from_date_utc)} dataTrip={departureTrip} />
            </div>
          )}

          <div className={clsx(classes.airPlaneContainerEmptyTripInfo, {
            [classes.airPlaneContainerReverseEmptyTripInfo]: type == "arrive"
          })}>
            <HeadingsTrip>{'departure'}</HeadingsTrip>

            {!isMobileScreenTrip && (
              <img
                className={clsx(classes.planeImgEmpty, {
                  ["planeArrive"]: type == 'arrive',
                  ["planeDeparture"]: type == 'departure',
                })}
                src="/assets/black-white-plane.svg"
                alt="plane" />
            )}

            <HeadingsTrip>{'arrival'}</HeadingsTrip>
            <FlightImageLineTrip />
          </div>

          {!isMobileScreenTrip && (
            <div className={clsx(classes.airportInfoEmptyTripInfo, classes.rightAlignedEmptyTripInfo)}>
              <FlightDetailsTrip typeTrip={availabilityTrip.aircraft_type} timecodeTrip={convertDateTrip(availabilityTrip.to_date_utc)} dataTrip={arrivalTrip} />
            </div>
          )}
        </div>
        {isMobileScreenTrip && (
          <div style={{ paddingTop: 10, display: "flex", width: "100%" }}>
            <div className={classes.airportSmallInfoEmptyTripInfo} style={{ flex: 1 }}>
              <FlightDetailsTrip typeTrip={availabilityTrip.aircraft_type} timecodeTrip={convertDateTrip(availabilityTrip.from_date_utc)} dataTrip={departureTrip} />
            </div>
            <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: 'center' }}>
              <img
                className={clsx(classes.planeSmallImgEmptyTripInfo, {
                  ["planeArrive"]: type == 'arrive',
                  ["planeDeparture"]: type == 'departure',
                })}
                src="/assets/black-white-plane.svg"
                alt="plane" />
            </div>
            <div className={classes.airportSmallInfoEmptyTripInfo} style={{ flex: 1, textAlign: "right" }}>
              <FlightDetailsTrip typeTrip={availabilityTrip.aircraft_type} timecodeTrip={convertDateTrip(availabilityTrip.to_date_utc)} dataTrip={arrivalTrip} />
            </div>
          </div>
        )}
      </div>)
  );
}

function FlightDetailsTrip({ dataTrip, timecodeTrip, typeTrip }: { dataTrip: any, timecodeTrip: any, typeTrip: any }) {
  return (
    <FlightDetailsTextTrip>
      <Typography><HTMLParser content={dataTrip.city}/></Typography>
      <Typography><HTMLParser content={dataTrip.icao_code}/></Typography>
      <Typography><HTMLParser content={timecodeTrip}/></Typography>
      <Typography><HTMLParser content={typeTrip}/></Typography>
    </FlightDetailsTextTrip>
  )
}

const HeadingsTrip = styled(Typography)({
  fontWeight: 400,
  fontSize: "20px",
  color: "#2C62E0",
  fontStyle: "normal",
  lineHeight: "normal",
  textTransform: "uppercase",
  "@media only screen and (max-width: 1150px)": {
    fontSize: "16px",
  },
  "@media only screen and (max-width: 768px)": {
    fontSize: "10px",
  }
})


const FlightDetailsTextTrip = styled('div')({
  '@media only screen and (max-width: 599px)': {
    '& .MuiTypography-body1': {
      fontSize: 10
    }
  }
})

const FlightImageLineTrip = styled('div')({
  left: 0,
  right: 0,
  top: '96px',
  position: 'absolute',
  transform: 'translateY(-50%)',
  borderBottom: '1px solid #2C62E0',
  '@media only screen and (max-width: 959px)': {
    top: '100%',
  }
})