import { APIArrayData, } from "../../types";
import { useJetZeroMutation, useJetZeroQuery } from "../../utils/react-query";
import { joinPageMessage } from "../message-generators/join-page.messages";
import { joinPageKeys } from "../query-keys/join-page.keys";
import { Question } from '../../types/question';


export function useJoinPageQuestions() {

  return useJetZeroQuery<APIArrayData<Question>>({
    queryKey: joinPageKeys.fetchQuestions(),
    message: joinPageMessage.fetchQuestions()
  });

}

export function useJoinPageHeadings() {

  return useJetZeroQuery<any>({
    queryKey: joinPageKeys.fetchHeadings(),
    message: joinPageMessage.fetchHeading()
  });

}

export function usePostAnswers() {

  return useJetZeroMutation<Parameters<typeof joinPageMessage.postAnswers>[0]>({
    queryKey: joinPageKeys.postAnswers(),
    messageGenerator: joinPageMessage.postAnswers,
  });
}
