export const emptyLegsKey= {
  getEmptyLegsSearchFilter: () => ['getEmptyLegsSearchFilter'] as const,
  getEmptyLegs: () => ['getEmptyLegs'] as const,
  getAllEmptyLegs: () => ['getAllEmptyLegs'] as const,
  getAllTrips: () => ['getAllTrips'] as const,
  postEmptyLegRequest: () => ['postEmptyLegRequest'] as const,
  postPrivateFlights: () => ['postPrivateFlights'] as const,
}

export const basicProfileKey = {
  putAccount: () => ['putAccount'] as const,
  getAccount: () => ['getAccount'] as const,
  getFeatured: () => ['getFeatured'] as const,
  resetPassword: () => ['resetPassword'] as const,
  verifyPassword: () => ['verifyPassword'] as const
}

export const uploadDocumentKey = {
  getDocument: () => ['getDocument'] as const,
  putDocument: () => ['putDocument'] as const,
  getWorldClock: () => ['getWorldClock'] as const
}

export const preferences = {
  getAirports: () => ['getAirports'] as const,
  getFavAirports: () => ['getFavAirports'] as const,
  postFavAirport: () => ['postFavAirport'] as const,
  delFavAirport: () => ['delFavAirport'] as const,
  getAircrafts: () => ['getAircrafts'] as const,
  getFavAircrafts: () => ['getFavAircrafts'] as const,
  postFavAircraft: () => ['postFavAircraft'] as const,
  delFavAircraft: () => ['delFavAircraft'] as const,
  getVehicleType: () => ['getVehicleType'] as const,
  getProvider: () => ['getProvider'] as const,
  getSuperCar: () => ['getSuperCar'] as const,
}

export const hotelKey = {
  getHotels: () => ['getHotels'] as const,
  getFavHotels: () => ['getFavHotels'] as const,
}

export const user = {
  getUsersPreferences : () => ['gerUsersPreferences'] as const,
  postUsersPreferences : () => ['postUsersPreferences'] as const
}


  