import { makeHttpMessage } from "../../utils/http-message"
import { appendJsonContentType } from "../../utils/http-response";

export const joinPageMessage = {

  fetchQuestions() {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: 'bx_block_question/questions',
      httpMethod: 'GET',
    });
  },

  fetchHeading() {
    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: 'bx_block_self_content/join_page',
      httpMethod: 'GET',
    });
  },

  postAnswers(questionsAndAnswers: any) {
    return makeHttpMessage({
      header: {
        "Content-Type" : "application/json"
      },
      url: 'account_block/join_requests',
      httpMethod: 'POST',
      httpBody: {
        data: questionsAndAnswers,
      }
    });
  },

}
