import React from 'react';
import Divider from '@material-ui/core/Divider';

export const VerticalSpacer =
  ({ value }: { value?: number }) =>
    (<Divider style={{ background: 'transparent', height: `${value || 20}px` }} />);

export const HorizontalSpacer =
  ({ value }: { value?: number }) =>
    (<Divider style={{ background: 'transparent', width: `${value || 20}px` }} />);
