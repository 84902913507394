import { oneOf, perBuild } from '@jackfranklin/test-data-bot';
// @ts-ignore
import faker from '@faker-js/faker';

export const generateArrayOf = (builder: any, count = 1, options?: any) =>
  Array(count).fill(0).map((_x) => builder(options || {}));


export const fakeString = perBuild(() => faker.internet.userName());
export const fakeUrl = perBuild(() => faker.internet.url());
export const fakeColor = perBuild(() => faker.internet.color());
export const fakeNumber = perBuild(() => faker.datatype.number({ min: 0, max: 100 }));
export const fakeDate = perBuild(() => faker.date.past().toISOString());
export const fakeEmail = perBuild(() => faker.internet.email());
export const fakePhone = perBuild(() => faker.phone.phoneNumber());
export const fakeLocation = perBuild(() => faker.internet.userName());
export const fakeLorem = perBuild(() => faker.lorem.paragraph());
export const fakeBoolean = oneOf(true, false);
