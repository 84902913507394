import React from "react";
// Customizable Area Start
import ForgotPasswordController, { Props } from "./forgotPasswordController.web";
import {Box, Typography, Divider, Button} from '@material-ui/core'
import { JetZeroTextField } from "../../../components/src/design-system";
import './reset.css'
import WorldClocks from "../../../components/src/shared/WorldClock";
// Customizable Area End


export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  isMobile = window.outerWidth < 960
  // Customizable Area End

  render() {
    return (
      <Box className="resetPassword">
        <Box className="resetPasswordContainer">
          <Typography className="resetPasswordHeadingTypo">
            FORGOT PASSWORD?
          </Typography>
          <Box style={{flex:1}}>
            <Box className="resetInputContainer" style={this.style.inputContainer}>
              <JetZeroTextField
                style={{...this.style.textField, flex: 1}}
                data-test-id="textField"
                label=""
                placeholder="ENTER YOUR EMAIL"
                type="email"
                value={this.state.email}
                onChange={(e:any) => this.setState({
                  email: e.target.value
                })}
                InputProps={{ disableUnderline: !this.isMobile }}
              />
              <Typography className="forgotPasswordResendMobile">Didn’t get the recovery link? <span data-test-id="spanBtn" onClick={()=>{!this.state.timer && this.sendRequest()}} style={{fontWeight:600, color: this.state.timer ? "rgb(58, 77, 121)" : "#2C62E0", cursor:"pointer"}}>RESEND</span></Typography>
              <Box style={this.style.dividerWrapper}>
                <Divider className="resetDivider" orientation="vertical" />
                <Button
                  type="submit"
                  color="primary"
                  variant="text"
                  data-test-id="btnSend"
                  onClick={()=>this.sendRequest()}
                >
                  <span style={{position:"relative", top:"1px"}}>SUBMIT</span>
                </Button>
              </Box>
            </Box>
            <Typography className="forgotPasswordResend">Didn’t get the recovery link? <span data-test-id="spanDBtn" onClick={()=>{!this.state.timer && this.sendRequest()}} style={{fontWeight:600, color: this.state.timer ? "rgb(58, 77, 121)" : "#2C62E0", cursor:"pointer"}}>RESEND</span></Typography>
          </Box>
        </Box>
        <Typography color="error" className="resetDividerErrorTypo">{this.state.error}</Typography>
        <Typography color="primary" className="resetDividerErrorTypo">{this.state.sucess}</Typography>
        <WorldClocks reset color="white" />
      </Box>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  style = {
    dividerWrapper: {
      display: "flex",
      alignItems:"center",
    },
    textField: {
      position:"relative",
      top:2,
      width: "100%"
    } as React.CSSProperties,
    inputContainer: {
    border: "1px solid #2C62E0"
    }
  }
  // Customizable Area End
}

