
/*
 * FLIP Technique for performant animation
 *
 * Reference:
 * https://aerotwist.com/blog/flip-your-animations/
 */
export function flipElements(fn: () => void, elements: { element: HTMLElement, dataset?: string }[]) {

  let allElements = elements.map(e => e.element);

  let firstClientRects = allElements.map(x => x.getBoundingClientRect());

  requestAnimationFrame(() => {

    fn();

    requestAnimationFrame(() => {

      let lastClientRects = allElements.map(x => x.getBoundingClientRect());

      lastClientRects.forEach((lastFrame, i) => {

        let firstFrame = firstClientRects[i];
        let element = allElements[i];

        let dx = firstFrame.x - lastFrame.x;
        let dy = firstFrame.y - lastFrame.y;
        let dh = firstFrame.height / lastFrame.height;
        let dw = firstFrame.width / lastFrame.width;

        element.style.setProperty('--dx', String(dx));
        element.style.setProperty('--dy', String(dy));
        element.style.setProperty('--dh', String(dh));
        element.style.setProperty('--dw', String(dw));

        let dataSetName = elements[i].dataset || "flip";

        element.dataset[dataSetName] = "invert";

        requestAnimationFrame(() => {
          element.dataset[dataSetName] = "play";
        });

      });
    });

  });
}
