import React from "react";
// Customizable Area Start
import ImpactController, { Props } from "./ImpactController";
import { ImpactContent } from "./ImpactContent.web";
// Customizable Area End


export default class Impact extends ImpactController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    // Customizable Area Start
    return (
      <ImpactContent />
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}

