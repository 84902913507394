import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { makeHttpMessage } from '../../../components/src/utils/http-message';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  account : {
    "country_code": string,
    "email": string,
    "first_name": string,
    "middle_name": string,
    "last_name": string,
    "full_phone_number": string,
    "phone_number": string,
    "home_address_line_1": string,
    "home_address_line_2": string,
    "home_address_city": string,
    "home_address_country": string,
    "home_address_zip_code": string,
    "company_address_line_1": string,
    "company_address_line_2": string,
    "company_address_city": string,
    "company_address_country": string,
    "company_address_zip_code": string,
    "full_name": string,
    "user_name": string,
    "gender": string,
    "date_of_birth": string,
    "age": string,
    "pronoun": string,
    "company_name": string,
    "assistants_email": string,
    "citizenship": string,
    "country_of_residence": string,
    "approx_weight": string,
    "passport_number": string,
    "nervous_flyer": string,
    "terms" : string,
    "policy" : string
  },
  active : string,
  newAccount : any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserProfileControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchAccountDataMsgId : string;
  putAccountDataMsgId : string;
  refreshTokenDataMsgId : string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      newAccount : "",
      account : {
        "country_code": "",
        "email": "",
        "first_name": "",
        "middle_name": "",
        "last_name": "",
        "full_phone_number": "",
        "phone_number": "",
        "home_address_line_1": "",
        "home_address_line_2": "",
        "home_address_city": "",
        "home_address_country": "",
        "home_address_zip_code": "",
        "company_address_line_1": "",
        "company_address_line_2": "",
        "company_address_city": "",
        "company_address_country": "",
        "company_address_zip_code": "",
        "full_name": "",
        "user_name": "",
        "gender": "",
        "date_of_birth": "",
        "age": "",
        "pronoun": "",
        "company_name": "",
        "assistants_email": "",
        "citizenship": "",
        "country_of_residence": "",
        "approx_weight": "",
        "passport_number": "",
        "nervous_flyer": "yes",
        "terms" : "",
        "policy" : ""
      },
      active : "account"
      // Customizable Area End
    };

    // Customizable Area Start
    this.fetchAccountDataMsgId = ''
    this.putAccountDataMsgId = ''
    this.refreshTokenDataMsgId = ''
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId == this.fetchAccountDataMsgId) {
        if (errorReponse) {
          console.error("ERROR", errorReponse);
        } else {
          if(responseJson?.errors){
            this.refreshToken()
          } else{
            this.setState({
              account : responseJson?.data?.attributes
            })
            sessionStorage.setItem('avinode_contact',responseJson?.data?.attributes.avinode_contact)
          }
        }
      }

      if (apiRequestCallId == this.putAccountDataMsgId) {
        if (errorReponse) {
          console.error("ERROR FROMM PUT", errorReponse)
        } else {
          console.log(responseJson, "RESPONSE OF PUT")
        }
      }

      if(apiRequestCallId == this.refreshTokenDataMsgId){
        if (errorReponse) {
          this.clearStorage()
        } else {
          if(responseJson?.errors){
            this.clearStorage()
          } else{
            const { meta: { token, refresh_token, id } } = responseJson;
              sessionStorage.setItem('token', token);
              sessionStorage.setItem('refresh_token', refresh_token);
              sessionStorage.setItem('id', id);
            this.fetchAccount()
          }
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Set the flag variable
    let userScrolled = false;

    // Add scroll event listener to detect user scroll
    window.addEventListener("scroll", () => {
      userScrolled = true;
    });

    this.handlePut({})
    this.fetchAccount()
    setTimeout(() => {
    // Check if the user has scrolled
    if (!userScrolled) {
      window.scrollTo({
        top: window.innerHeight,
        behavior: "smooth",
      });
    }}, 3000);
    // Customizable Area End
  }

  // Customizable Area Start

  clearStorage(){
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("id");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("id");
    window.location.replace("/login")
  }

  fetchAccount() {
    // console.log(this, "FETCH THIS")
    const header = {
      "Content-Type" : 'application/json',
      "token" : sessionStorage.getItem('token')
    };

    const httpBody = {}

    const msg = makeHttpMessage({
      header,
      httpBody,
      httpMethod : 'GET',
      url : configJSON.apiEndPointFetchAccount
    });

    this.fetchAccountDataMsgId = msg.messageId;

    runEngine.sendMessage(msg.id, msg);
  }

  putAccount(newAccount:any){
    const header = {
      "token" : sessionStorage.getItem('token')
    };

    const httpBody = {data:newAccount}

    const msg = makeHttpMessage({
      header,
      httpBody,
      httpMethod : 'PUT',
      url : configJSON.apiEndPointPutAccount
    });

    console.log("MSG OF CONTROLLER", msg)

    this.putAccountDataMsgId = msg.messageId;

    runEngine.sendMessage(msg.id, msg);
  }

  refreshToken() {
    const header = {
      "Content-Type" : 'application/json',
      "token" : localStorage.getItem('refresh_token')
    };

    const httpBody = {}

    const msg = makeHttpMessage({
      header,
      httpBody,
      httpMethod : 'POST',
      url : configJSON.apiEndPointRefreshToken
    });

    this.refreshTokenDataMsgId = msg.messageId;

    runEngine.sendMessage(msg.id, msg);
  }

  handlePut(account:any){
    this.putAccount(account)
  }
  // Customizable Area End
}
