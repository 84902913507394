import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import useOnlineStatus from "../../../components/src/shared/GetOnlineStatus";

const BG_SYNC_KEY = "JetZeroOfflineData";

interface Props {
  online: Boolean;
}
interface State {
  currentStatus: Boolean;
}
interface SS {}

class OfflineSyncMiddleware extends BlockComponent<Props, State, SS> {
  state = {
    currentStatus: false,
  };

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIRequestMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    super.componentDidMount();
    this.onCreateDB();
    this.onUpdate();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: SS | undefined
  ): void {
    let oldData = prevProps.online;
    let newData = this.props.online;
    if (oldData !== newData) this.onUpdate();
  }

  onUpdate() {
    let currentStatus = this.props.online;
    this.setState({ currentStatus });
    currentStatus && this.onUploadToServer();
  }

  onCreateDB() {
    let db: any = localStorage.getItem(BG_SYNC_KEY);
    if (!db) localStorage.setItem(BG_SYNC_KEY, JSON.stringify({ data: [] }));
  }

  saveRequestToDB(request: Message) {
    if (!this.validateRequest(request)) return;

    let db = this.getDB();
    db.data.push(request);
    localStorage.setItem(BG_SYNC_KEY, JSON.stringify(db));
  }

  getDB() {
    let db = JSON.parse(
      localStorage.getItem(BG_SYNC_KEY) || JSON.stringify({ data: [] })
    );
    return db;
  }

  resetDB() {
    let db = { data: [] };
    localStorage.setItem(BG_SYNC_KEY, JSON.stringify(db));
  }

  onUploadToServer() {
    let db: any = this.getDB();
    if (db.data.length === 0) return;

    db.data.forEach((message: Message) => {
      runEngine.sendMessage(message.id, message);
    });
    
    this.resetDB();

    setTimeout(() => {
      alert(
        "Offline data is posted to server and page will reload for data sync!"
      );
      window.location.reload();
    }, 2000);
  }

  validateRequest(message: Message) {
    let method: any = String(
      message.getData(getName(MessageEnum.RestAPIRequestMethodMessage))
    ).toLowerCase();

    return !["get"].includes(method);
  }

  receive(_: string, message: Message): void {
    !navigator.onLine && this.saveRequestToDB(message);
  }

  render() {
    return <></>;
  }
}

function BindFlag() {
  let online: Boolean = useOnlineStatus();
  return <OfflineSyncMiddleware online={online} />;
}

export default BindFlag;
