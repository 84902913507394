import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/Delete';
import Upgradeicon from '@material-ui/icons/Publish';

const useUploadPhotoStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: '250px',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '10px',
    paddingBottom: '10px',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    '& p': {
    },
    '& p:nth-child(1)': {
      color: theme.palette.primary.main,
      marginRight: '30px',
    },
    '& p:nth-child(2)': {
      marginRight: '5px',
      fontWeight: 'bold',
    },
    '& p:nth-child(3)': {
      color: theme.palette.secondary.main,
    },
  },
  photoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '250px',
  },
  hidden: {
    display: "none",
  },
  uploadedImgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '10px',
    "& > button" : {
      display:"block"
    },
    "&:hover > button" : {
      display: "block"
    }
  },
  uploadedImg: {
    cursor: 'pointer',
    maxWidth: '100%',
    maxHeight: '250px',
    objectFit: 'cover',
    borderRadius: '5px',
  },
  headingName: {
    fontSize: "20px",
    lineHeight: "40px",
    fontWeight: 400,
    textTransform: "uppercase",
    "@media only screen and (max-width: 1150px)": {
      fontSize: "15px",
      lineHeight: "20px",
    },
    "@media only screen and (max-width: 768px)": {
      fontSize: "12px",
    },
  },
  headingSpacing: {
    marginRight: "47px",
    "@media only screen and (max-width: 768px)": {
      marginRight: "11px",
    },
  },
  subHeadings: {
    color: "#C1C1C1",
    fontSize: "20px",
    lineHeight: "40px",
    fontWeight: 400,
    textTransform: "uppercase",
    marginLeft: "5px",
    "@media only screen and (max-width: 1150px)": {
      fontSize: "15px",
    },
    "@media only screen and (max-width: 768px)": {
      fontSize: "12px",
      lineHeight: "20px",
    },
  },
  plusIcon: {
    fontWeight: 400,
    fontSize: 100,
    lineHeight: "40px",
    color: "#2C62E0",
  }
}));

function encodeImageFileAsURL(file: any) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onloadend = function () {
      let imagebase64 = reader.result;
      resolve(imagebase64);
    };
    reader.readAsDataURL(file);
  });
}


export function UploadPhoto({
  title,
  subtitle,
  itemNumber,
  onUpload,
  imageFile,
  onRemove
}: {
  title: string,
  subtitle: string,
  itemNumber: string,
  onUpload: (params: { file: File }) => void,
  imageFile?: string,
  onRemove: () => void
}) {


  const [uploadedImgBase64, setUploadedImgBase64] = useState('');
  const [showImage, setshowImage] = useState<boolean>(true)
  const [passedImageFile, setPassedImageFile] = useState<string | undefined>()

  useEffect(()=>{
    setPassedImageFile(imageFile)
  },[imageFile])

  const uploadRef = useRef<HTMLInputElement>(null);
  const classes = useUploadPhotoStyles();

  function iconClickHandler() {
    if (uploadRef && uploadRef.current)
      uploadRef.current.click();
  }

  async function uploadHandler(event: React.ChangeEvent<HTMLInputElement>) {
    let file = event?.target?.files?.[0];

    if (file) {
      setshowImage(false)
      onUpload({ file });

      let base64 = await encodeImageFileAsURL(file)

      setUploadedImgBase64(base64 as string);
    }
  }

  const handleRemove = () => {
    setUploadedImgBase64('')
    setPassedImageFile(undefined)
    onRemove()
  }

  return (
    <>
      <input
        data-test-id={'uploadPhoto-' + title + '-' + subtitle}
        onChange={uploadHandler}
        className={classes.hidden}
        accept="image/*"
        ref={uploadRef}
        type="file" />

      <div>
        <Typography className={classes.headingName}>
          {" "}
          <Typography
            component="span"
            className={`${classes.headingName} ${classes.headingSpacing}`}
            color="primary"
          >
            {itemNumber}
          </Typography>
          {title}
          <Typography
            component="span"
            className={`${classes.subHeadings}`}
          >
            {subtitle}
          </Typography>
        </Typography>
      </div>
      <div style={{ margin: "15px 0px", padding: "15px 0px", borderTop: "1px solid #2C62E0", borderBottom: "1px solid #2C62E0" }}>
        {
          uploadedImgBase64 || passedImageFile ?
            <div data-test-id={'removePhoto-' + title + '-' + subtitle} className={classes.uploadedImgContainer} style={{position:"relative", minHeight:"70px"}}>
              <IconButton data-test-id={'removePhotoBtn-' + title + '-' + subtitle} onClick={handleRemove} color="primary" style={{fontSize:"10px", position:"absolute", top:"10px", right:'10px'}}>
                <DeleteOutlineIcon/>
              </IconButton>
              <IconButton onClick={iconClickHandler} color="primary" style={{fontSize:"10px", position:"absolute", top:"10px", right:'60px'}}>
                <Upgradeicon/>
              </IconButton>
              <img data-test-id={'btn-uploadPhoto-' + title} onClick={iconClickHandler} src={imageFile && showImage ? imageFile : uploadedImgBase64} className={classes.uploadedImg} />
            </div>
            :
            <div className={classes.photoContainer}>
              <IconButton data-test-id={'btn-uploadPhoto-' + title} onClick={iconClickHandler}>
                <span style={{ fontSize: "100px", fontWeight: 800, lineHeight: "40px", color: "#2C62E0", display: "flex", alignItems: 'center', aspectRatio: "1" }}>+</span>
              </IconButton>
            </div>
        }</div>

    </>
  );

}
