import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { makeHttpMessage } from '../../../components/src/utils/http-message';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  error:string[] | null
  password:string
  confirmPassword:string
  token:string
  sucess:string
  showPassword:boolean,
  showNewPassword:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendRequestMsgId:string
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      error : null,
      password : "",
      confirmPassword : "",
      token: "",
      sucess: "",
      showPassword: false,
      showNewPassword: false
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId == this.sendRequestMsgId) {
        if(responseJson.errors){
          if(responseJson.errors[0].hasOwnProperty("token")){
            this.setState({
              error : [responseJson.errors[0].token]
            })
          } else if(responseJson.errors[0].hasOwnProperty("failed_reset")){
            this.setState({
              error : ["Weak password."]
            })
          } else {
            this.setState({
              error: ["Something went wrong."]
            })
          }} else {
            this.props.navigation.navigate('SuccessPage')
          // this.setState({
          //   sucess:"Password changed successfully."
          // })
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      token : window.location.href.split("token=")[1]
    })
  }

  // Customizable Area Start
  sendRequest(){
    this.setState({
      error:null,
      sucess: ""
    })
    
    if(this.state.password === "" || this.state.confirmPassword === ""){
      this.setState({
        error : ["All fields are required."]
      })
    } else if (
      this.state.password !== this.state.confirmPassword
    ){
      this.setState({
        error : ["Password and confirm password does not match."]
      })
    } else {
      const header = {
        "Content-Type" : 'application/json',
        "token" : this.state.token
      };
  
      const httpBody = {}
  
      const msg = makeHttpMessage({
        header,
        httpBody,
        httpMethod : 'POST',
        url : `/bx_block_login/reset_password?password=${this.state.password}&password_confirmation=${this.state.confirmPassword}`
      });
  
      this.sendRequestMsgId = msg.messageId;
  
      runEngine.sendMessage(msg.id, msg);
    }
  }
  // Customizable Area End
}
