import { useJetZeroMutation, useJetZeroQuery } from "../../utils/react-query";
import { emptyLegsMessages , putAccountMessage, getUploadDocumentMessage, putDocumentMessage, preferenceMessage, userMessage, postPreferenceMessage, hotelKeyMessage, resetPasswordMessage } from "../message-generators/basicProfile.messages";
import { basicProfileKey, uploadDocumentKey, emptyLegsKey, preferences, hotelKey, user } from "../query-keys/basicprofile.keys";

export function useEmptyLegsSearchFilter(){
  return useJetZeroMutation<Parameters<typeof emptyLegsMessages.getEmptyLegsSearchFilter>>({
    queryKey: emptyLegsKey.getEmptyLegsSearchFilter(),
    messageGenerator: emptyLegsMessages.getEmptyLegsSearchFilter,
  });
}

export function useEmptyLegs(){
  return useJetZeroMutation<Parameters<typeof emptyLegsMessages.getEmptyLegs>[0]>({
    queryKey: emptyLegsKey.getEmptyLegs(),
    messageGenerator: emptyLegsMessages.getEmptyLegs,
  });
}

export function useFeaturedLegs() {
  return useJetZeroQuery<any>({
    queryKey: basicProfileKey.getFeatured(),
    message: putAccountMessage.getFeatured(),
  });
}

export function useAllTrips(){
  return useJetZeroMutation<any>({
    queryKey: emptyLegsKey.getAllTrips(),
    messageGenerator: emptyLegsMessages.getAllTrips,
  });
}

export function useAllEmptyLegs(){
  return useJetZeroMutation<Parameters<typeof emptyLegsMessages.getAllEmptyLegs>[0]>({
    queryKey: emptyLegsKey.getAllEmptyLegs(),
    messageGenerator: emptyLegsMessages.getAllEmptyLegs,
  });
}

export function postLegRequest(){
  return useJetZeroMutation<Parameters<typeof emptyLegsMessages.postLegRequest>[0]>({
    queryKey: emptyLegsKey.postEmptyLegRequest(),
    messageGenerator: emptyLegsMessages.postLegRequest,
  });
}

export function usePutAccount() {
  return useJetZeroMutation<Parameters<typeof putAccountMessage.putAccount>[0]>({
    queryKey: basicProfileKey.putAccount(),
    messageGenerator: putAccountMessage.putAccount,
  });
}

export function useGetAccount() {
  return useJetZeroQuery<any>({
    queryKey: basicProfileKey.getAccount(),
    message: putAccountMessage.getAccount(),
  });
}

export function useResetPassword() {
  return useJetZeroMutation<Parameters<typeof resetPasswordMessage.resetPassword>[0]>({
    queryKey: basicProfileKey.resetPassword(),
    messageGenerator: resetPasswordMessage.resetPassword,
  });
}

export function useVerifyPassword() {
  return useJetZeroMutation<Parameters<typeof resetPasswordMessage.verifyPassword>[0]>({
    queryKey: basicProfileKey.verifyPassword(),
    messageGenerator: resetPasswordMessage.verifyPassword,
  });
}

export function useFetchDocuments() {
  return useJetZeroQuery<any>({
    queryKey: uploadDocumentKey.getDocument(),
    message: getUploadDocumentMessage.getUploadDocument()
  });
}

export function useWorldClock() {
  return useJetZeroQuery<any>({
    queryKey: uploadDocumentKey.getWorldClock(),
    message: getUploadDocumentMessage.getWorldClock()
  });
}

export function useFetchAirports(term:string) {
  return useJetZeroQuery<any>({
    queryKey: preferences.getAirports(),
    message: preferenceMessage.getAirports(term)
  });
}

export function useFetchFavAirports(){
  return useJetZeroQuery<any>({
    queryKey: preferences.getFavAirports(),
    message: preferenceMessage.getFavAirports()
  });
}

export function useDelFavAirports(){
  return useJetZeroMutation<Parameters<typeof postPreferenceMessage.delFavAirport>[0]>({
    queryKey: preferences.delFavAirport(),
    messageGenerator: postPreferenceMessage.delFavAirport
  });
}

export function usePostFavAirports(){
  return useJetZeroMutation<Parameters<typeof postPreferenceMessage.postFavAirport>[0]>({
    queryKey: preferences.postFavAirport(),
    messageGenerator: postPreferenceMessage.postFavAirport
  })
}

export function useFetchAircrafts(term:string) {
  return useJetZeroQuery<any>({
    queryKey: preferences.getAircrafts(),
    message: preferenceMessage.getAircrafts(term)
  });
}

export function useFetchFavAircrafts(){
  return useJetZeroQuery<any>({
    queryKey: preferences.getFavAircrafts(),
    message: preferenceMessage.getFavAircrafts()
  });
}

export function useDelFavAircrafts(){
  return useJetZeroMutation<Parameters<typeof postPreferenceMessage.delFavAircraft>[0]>({
    queryKey: preferences.delFavAircraft(),
    messageGenerator: postPreferenceMessage.delFavAircraft
  });
}

export function usePostFavAircrafts(){
  return useJetZeroMutation<Parameters<typeof postPreferenceMessage.postFavAircraft>[0]>({
    queryKey: preferences.postFavAircraft(),
    messageGenerator: postPreferenceMessage.postFavAircraft
  })
}

export function useFetchVehicleType() {
  return useJetZeroQuery<any>({
    queryKey: preferences.getVehicleType(),
    message: preferenceMessage.getVehicleType()
  });
}

export function useFetchHotels() {
  return useJetZeroQuery<any>({
    queryKey: hotelKey.getHotels(),
    message: hotelKeyMessage.getHotels()
  });
}

export function useFetchFavHotels() {
  return useJetZeroQuery<any>({
    queryKey: hotelKey.getFavHotels(),
    message: hotelKeyMessage.getFavHotels()
  });
}

export function useFetchProvider() {
  return useJetZeroQuery<any>({
    queryKey: preferences.getProvider(),
    message: preferenceMessage.getProvider()
  });
}

export function useFetchSuperCar() {
  return useJetZeroQuery<any>({
    queryKey: preferences.getSuperCar(),
    message: preferenceMessage.getSuperCar()
  });
}

export function usePutDocument() {
  return useJetZeroMutation<Parameters<typeof putDocumentMessage.putDocument>[0]>({
    queryKey: uploadDocumentKey.putDocument(),
    messageGenerator: putDocumentMessage.putDocument,
  });
}

export function useFetchUserPreferences() {
  return useJetZeroQuery<any>({
    queryKey: user.getUsersPreferences(),
    message: userMessage.getUsersPreferences()
  });
}

export function usePostUserPreferences(){
  return useJetZeroMutation<Parameters<typeof userMessage.postUsersPreferences>[0]>({
    queryKey: user.postUsersPreferences(),
    messageGenerator: userMessage.postUsersPreferences
  })
}

export function usePrivateFlights(){
  return useJetZeroMutation<Parameters<typeof emptyLegsMessages.postPrivateFlights>[0]>({
    queryKey: emptyLegsKey.postPrivateFlights(),
    messageGenerator: emptyLegsMessages.postPrivateFlights,
  });
}
