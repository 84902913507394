import React from "react";
import { Box, Typography } from "@material-ui/core";
import TripInfo from "./TripInfo";
import { Skeleton } from "@material-ui/lab";


import BookTripsControllerWeb from "./BookedTripsController.web";

export default class BookedTrips extends BookTripsControllerWeb {

  render() {
    return (
      <Box
        className="rootContainer1"
        sx={{
          padding: { sm: "20px 40px", md: "20px 40px" },
          display: "block",
          marginBottom: "200px",
        }}
      >
        {
          this.state.error !== "" ? 
          <Typography variant="h3" color="error" style={{textTransform:"uppercase"}}>{this.state.error}</Typography>
          :
          <Box style={{display:"flex", flexDirection:"column", gap:"120px", width:"100%"}}>
          { 
            this.state.info.length > 0 ?
            this.state.info.map((item:any) => {
              return(
                <TripInfo key={item.availability.from_date_utc} type="departure" TripInfo={item}/>
              )
            })
            :
            <Typography variant="h3" color="primary" style={{textTransform:"uppercase"}}>
              { this.state.loading ? 
                <>
                  <Skeleton variant="rect" />
                  <br />
                  <Skeleton variant="rect" />
                </> : "No trip found."
              }
            </Typography>
          }
          </Box>
        }
      </Box>
    );
  }
}
