import React, { useLayoutEffect } from 'react'
import { Typography } from '@material-ui/core';


function FixedText({ text, className }: Readonly<{ text: string, className?: string }>) {

  const matches = window.outerWidth <= 600

  const style = {
    fixedContainer: {
      position: matches ? 'absolute' : 'fixed',
      top: matches ? 100 : 400,
      left: matches ? 40 : 110,
      zIndex: 10,
      display: "block"
    } as React.CSSProperties,
    fixedText: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "28px",
      letterSpacing: "0.2em",
      color: "#2C62E0"
    } as React.CSSProperties,
    fixedTextMobile: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 8,
      lineHeight: "normal",
      letterSpacing: 1.6,
      color: "#2C62E0",
    } as React.CSSProperties,
  }

  useLayoutEffect(() => {

    const callbackFixedText = () => {
      let containersFixedText: NodeList = document.querySelectorAll('#overlap-container, .hide-clock-in-this-container')
      let watcherFixedText: any = document.getElementById('floating-fixed-text')
      if (!watcherFixedText) return;

      let bottomOfWatcherFixedText = watcherFixedText.getBoundingClientRect().bottom
      let topOfWatcherFixedText = watcherFixedText.getBoundingClientRect().top
      let flagArrayFixedText: Array<boolean> = []

      containersFixedText.forEach((element: any) => {
        let marginFixedText = 100
        let { top, bottom } = element.getBoundingClientRect()
        let inside = top <= topOfWatcherFixedText + marginFixedText && bottom + marginFixedText >= bottomOfWatcherFixedText
        flagArrayFixedText.push(inside)
      })

      let isVisbleFixedText = flagArrayFixedText.filter(item => item === true).length > 0
      let currentVisibilityFixedText = isVisbleFixedText ? 'hidden' : 'visible'
      let previousVisibilityFixedText = watcherFixedText.style.visibility
      if (currentVisibilityFixedText != previousVisibilityFixedText)
        watcherFixedText.style.visibility = currentVisibilityFixedText
    }

    document.addEventListener('scroll', callbackFixedText)
    return () => {
      document.removeEventListener('scroll', callbackFixedText)
    }

  }, [])

  if (window.outerWidth <= 1150) return <></>

  return (
    <div style={style.fixedContainer} id="floating-fixed-text">
      <Typography style={matches ? style.fixedTextMobile : style.fixedText} className={className}>{text}</Typography>
    </div>
  )
}



export default FixedText
