// Customizable Area Start
import React from "react";
import JoinControllerWebJoin, { PropsJoin } from "./JoinControllerWeb.web";
import { JoinWebContentJoinContent } from "./JoinPageContent2";


export default class JoinWebJoinContent extends JoinControllerWebJoin {
  constructor(props: PropsJoin) {
    // Customizable Area End
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    return (<JoinWebContentJoinContent />);
  }
}
// Customizable Area End

