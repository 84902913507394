// Customizable Area Start
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  Button,
  TextField,
  styled
} from "@material-ui/core";
//@ts-ignore
import { motion as m } from "framer-motion";
import { HideContainer } from "../../../components/src/shared/HideContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { JetZeroAccordion, JetZeroAccordionSummary, JetZeroAccordionDetails } from "../../../components/src/shared"
import StyledSelect from './StyledSelect'
import SearchIcon from "@material-ui/icons/Search";
import MultiselectReactDropdown from "multiselect-react-dropdown";
import CloseIcon from "@material-ui/icons/Close";
import { useFetchAirports, useFetchFavAirports, usePostFavAirports, useDelFavAirports, useFetchFavAircrafts, usePostFavAircrafts, useDelFavAircrafts, useFetchAircrafts, useFetchVehicleType, useFetchProvider, useFetchSuperCar, useFetchHotels, useFetchFavHotels, useFetchUserPreferences, usePostUserPreferences } from "../../../components/src/api/hooks/basicProfile.hooks";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useNavigate } from "../../../components/src/utils/router-context";
import { emailRegex } from "../../../components/src/fake-data/fake-impact";
export const configJSON = require("./config");


const useStyles = makeStyles((theme) => ({
  numberTypoMyPreferences: {
    width: "50px",
    [theme.breakpoints.down('sm')]: {
      width: "50px"
    }
  },
  blueLinesMyPreferences: {
    height: "1px",
    width: "40%",
    position : "relative",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      width: "40%",
    },
  },
  sendButtonMyPreferences: {
    fontWeight: 400,
    textTransform: "uppercase",
    textAlign: "right",
    paddingLeft: "40px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
    },
  },
  arrowContainerMyPreferences: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyItems: "flex-end",
    justifyContent: "flex-end",
    marginTop: "104px",
  },
  containerMyPreferences: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  arrow : {
    width: "9px",
    height: "9px",
    left: "calc(100% - 10px)",
    bottom: "-4px",
    margin: "0",
    position: "absolute",
    transform:"rotate(45deg)",
    borderRight: `1px solid ${theme.palette.primary.main}`,
    borderTop: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down('sm')]: {
        width: "9px",
        height: "9px",
        bottom: "-4px",
    },
  }
}));

export const Headings = styled(Typography)({
  fontSize: 20,
  textTransform: 'uppercase',
  "& > span": {
    color: "#C1C1C1"
  },
  "@media only screen and (max-width: 1150px)": {
    fontSize: "15px !important",
    fontStyle: "normal",
    lineHeight: "20px",
    fontWeight: 400,
  },
  "@media only screen and (max-width: 768px)": {
    fontSize: "10px !important",
  }
})

export const InputContainer = styled('div')({
  "& .MuiInput-input, & #search_input, & .custom-select__placeholder, & .custom-select__single-value, & .chip, & .PhoneInputInput": {
    fontSize: "20px !important",
    "@media only screen and (max-width: 1150px)": {
      fontSize: "15px !important",
    },
    "@media only screen and (max-width: 768px)": {
      fontSize: "10px !important",
    }
  },
  "@media only screen and (max-width: 768px)": {
    "& .chip": {
      padding: "0px 8px"
    },
    "& .MuiSvgIcon-root": {
      width: 20,
      paddingLeft: 4
    }
  }
})

export default function MyPreferencesContent() {
  const classes = useStyles();

  const customStyles = {
    optionContainer: {
      borderRadius: "4px",
      backgroundColor: "white",
      border: 0,
      fontFamily: "atyp",
    },
    chips: {
      borderRadius: "4px",
      backgroundColor: "white",
      color: "#6f6f70",
      border: "1px solid #2C62E0",
      fontFamily: "atyp",
      fontSize: "18px",
    },
    searchBox: {
      border: 0,
    },
    inputField: {
      // To change input field position or margin
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "40px",
      fontFamily: "atyp",
    },
    option: {
      hoverBackgroundColor: "gray", // Set the background color when hovering over an option
      selectedBackgroundColor: "gray",
      fontFamily: "atyp",
    },
  };

  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const [value, setValue] = useState<any>("")

  const handleChangeS = (panel: string) => (_event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  //PREFERED AIRPORT
  const [airportTerm, setAirportTerm] = React.useState<string>("")
  const { data: airportList, isFetching: airportLoading, refetch: reFetchAirportList } = useFetchAirports(airportTerm);
  const { data: favAirportList, refetch:refetchFavAirport } = useFetchFavAirports();
  const { mutate: postFavAirports } = usePostFavAirports();
  const { mutate: delFavAirport } = useDelFavAirports();
  useEffect(() => {
    reFetchAirportList()
  }, [airportTerm])

  //PREFERED AIRCRAFT
  const [aircraftTerm, setAircraftTerm] = React.useState<string>("")
  const { data: aircraftList, isFetching: aircraftLoading, refetch: reFetchAircraftList } = useFetchAircrafts(aircraftTerm);
  const { data: favAircraftList, refetch:refetchFavAircraft } = useFetchFavAircrafts();
  const { mutate: postFavAircrafts } = usePostFavAircrafts();
  const { mutate: delFavAircraft } = useDelFavAircrafts();
  useEffect(() => {
    reFetchAircraftList()
  }, [aircraftTerm])

  //PREFERED LIST
  const { data: VehicleTypeList } = useFetchVehicleType();
  const { data: hotelList, isLoading: hotelLoading } = useFetchHotels();
  const { data: hotelFavList, refetch:refetchFavHotel } = useFetchFavHotels();
  const { data: providerList } = useFetchProvider();
  const { data: superCarList } = useFetchSuperCar();
  const [selectedHotel, setSelectedHotel] = useState<any>([])
  const [preferedAirports, setPreferedAirports] = useState<any>([])
  const [preferedAircrafts, setPreferedAircrafts] = useState<any>([])
  const navigate = useNavigate()
  useEffect(() => {
    hotelFavList?.data?.length > 0 &&
      setSelectedHotel((prevSelectedHotel: any) => [
        ...prevSelectedHotel,
        ...hotelFavList.data.map((item: any) => {
          return {
            id: item.id,
            name: item.attributes.hotel_name
          }
        })
      ])
  }, [hotelFavList])
  useEffect(() => {
    favAirportList?.data?.length > 0 &&
      setPreferedAirports((prePreferedAirport: any) => [
        ...prePreferedAirport,
        ...favAirportList.data.map((item: any) => {
          return {
            id: item.attributes.airport_id,
            name: `${item.attributes.airport.data.attributes.name} (${item.attributes.airport.data.attributes.icao})`
          }
        })
      ])
  }, [favAirportList])
  useEffect(() => {
    favAircraftList?.data?.length > 0 &&
      setPreferedAircrafts((prePreferedAircraft: any) => [
        ...prePreferedAircraft,
        ...favAircraftList.data.map((item: any) => {
          return {
            id: item.attributes.aircraft_id,
            name: item.attributes.aircraft.tail_number
          }
        })
      ])
  }, [favAircraftList])

  //USER
  const { data: userPreferences, refetch: userReFetchUserPreferences } = useFetchUserPreferences()
  const { mutate: postPreferences, isLoading:postLoading } = usePostUserPreferences();

  const [error, setError] = useState<string[]>([])
  const [missingFields, setMissingFields] = useState<any>([])

  useEffect(() => {
    if (userPreferences?.data?.attributes.emergency_contact?.phone && isValidPhoneNumber(`${userPreferences.data.attributes.emergency_contact.phone}`)) {
      setValue(`${userPreferences.data.attributes.emergency_contact.phone}`)
    }
  }, [userPreferences])

  useEffect(() => {
    if (value !== "") {
      const e = {
        target: {
          name: "emergecyContactPhone",
          value: value
        }
      }
      handleChangeMyPreferences(e)
    }
  }, [value])

  const accountAccordian = [
    {
      heading: "EMERGENCY CONTACT",
      required: true,
      inputFields: [
        {
          placeHolder: "John dhee",
          name: "emergencyContactFullName",
          label: "Full Name",
          type: "text"
        },
        {
          placeHolder: "Brother",
          name: "emergecyContactRelationShip",
          label: "RELATIONSHIP",
        },
        {
          placeHolder: "(555) 555-5555",
          name: "emergecyContactPhone",
          label: "phone",
          type: "phoneNumber",
          error : ""
        },
        {
          placeHolder: "john@doe.com",
          name: "emergecyContactEmail",
          label: "email",
        },
      ],
    },
    {
      heading: "CATERING PREFERENCES LIST",
      required: false,
      inputFields: [
        {
          placeHolder: "Text Field", name: "beverage", label: "beverage"
        },
        {
          placeHolder: "Select",
          name: "dietry",
          label: "DIETARY",
          type: "select",
          options: [
            { label: "Vegan", value: "vegan" },
            { label: "Vegetarian", value: "vegetarian" },
            { label: "Paleo", value: "paleo" },
            { label: "Low Carb", value: "lowcarb" },
            { label: "KETO", value: "keto" },
          ],
        },
        {
          placeHolder: "Text Field",
          name: "preferredDietry",
          label: "preferred dietary",
        },
        { placeHolder: "Text Field", name: "allergies", label: "ALLERGIES" },
      ],
    },
    {
      heading: "FAVOURITE HOTELS",
      required: false,
      inputFields: [
        {
          placeHolder: "Search hotels", name: "hotels", type: "multiselect", options: hotelList?.data?.map((item: any) => {
            return {
              id: item.id,
              name: item.attributes.hotel_name
            }
          })
        },
      ],
    },
    {
      heading: "FAVOURITE TAILS",
      subHeading: "(JETS)",
      required: false,
      inputFields: [
        {
          placeHolder: "Search tails", name: "preffredAircraft", type: "multiselect", options: aircraftList?.data?.results?.map((item: any) => {
            return {
              id: item.aircraft_id,
              name: item.tail_number
            }
          })
        },
      ],
    },
    {
      heading: "PREFERRED AIRPORT",
      required: true,
      inputFields: [
        {
          placeHolder: "Search airports", name: "preffredAirport", type: "multiselect", options: airportList?.data?.results?.map((item: any) => {
            return {
              id: item.airport_id,
              name: `${item.name} (${item.icao})`
            }
          })
        },
      ],
    },
    {
      heading: "GROUND TRANSPORT PREFERENCES",
      required: false,
      inputFields: [
        {
          placeHolder: "Select",
          name: "gtpVechilePreType",
          label: "VEHICLE PREFERENCE TYPE",
          type: "select",
          options: VehicleTypeList?.vehicle_preference_type?.map((item: any) => {
            return {
              label: item.vehicle_preference_type,
              value: item.vehicle_preference_type
            }
          }),
        },
        {
          placeHolder: "Select",
          name: "gptPrefrredProvider",
          label: "PREFERRED PROVIDER",
          type: "select",
          options: providerList?.preferred_provider?.map((item: any) => {
            return {
              label: item.preferred_provider,
              value: item.preferred_provider
            }
          }),
        },
        {
          placeHolder: "Select",
          name: "gptPreSuperCar",
          label: "PREFERRED Supercar",
          type: "select",
          options: superCarList?.preferred_supercar?.map((item: any) => {
            return {
              label: item.preferred_supercar,
              value: item.preferred_supercar
            }
          }),
        },
      ],
    },
    {
      heading: "SPECIAL HANDLING REQUEST I.E.",
      required: false,
      subHeading: "(SECURITY)",
      inputFields: [
        {
          placeHolder: "Select",
          name: "shrMeetAndGreet",
          label: "Meet & greet",
          type: "select",
          options: [
            { label: "Yes", value: 'true' },
            { label: "No", value: 'false' },
          ],
        },
        {
          placeHolder: "Select",
          name: "shrPlaneSidePickUp",
          label: "Planeside pickup",
          type: "select",
          options: [
            { label: "Yes", value: 'true' },
            { label: "No", value: 'false' },
          ],
        },
      ],
    },
  ];

  const [invalidPhoneError, setinvalidPhoneError] = useState<boolean>(false)

  const [valueSet, setValueSet] = useState<any>(
    {
      "emergencyContactFullName": "",
      "emergecyContactRelationShip": "",
      "emergecyContactPhone": "",
      "emergecyContactEmail": "",
      "beverage": "",
      "dietry": "",
      "preferredDietry": "",
      "allergies": "",
      "gtpVechilePreType": "",
      "gptPrefrredProvider": "",
      "gptPreSuperCar": "",
      "shrMeetAndGreet": "",
      "shrPlaneSidePickUp": ""
    }
  )

  useEffect(() => {
    userPreferences?.data &&
      setValueSet(
        {
          "emergencyContactFullName": userPreferences.data.attributes.emergency_contact?.full_name,
          "emergecyContactRelationShip": userPreferences.data.attributes.emergency_contact?.relationship,
          "emergecyContactPhone": userPreferences.data.attributes.emergency_contact?.phone,
          "emergecyContactEmail": userPreferences.data.attributes.emergency_contact?.email,
          "beverage": userPreferences.data.attributes.catering_preference?.beverages,
          "dietry": userPreferences.data.attributes.catering_preference?.dietary,
          "preferredDietry": userPreferences.data.attributes.catering_preference?.preferred_dietary,
          "allergies": userPreferences.data.attributes.catering_preference?.allergies,
          "gtpVechilePreType": userPreferences.data.attributes.preferred_vehicle_types?.vehicle_preference_type,
          "gptPrefrredProvider": userPreferences.data.attributes.preffered_providers?.preferred_provider,
          "gptPreSuperCar": userPreferences.data.attributes.preferred_supercar?.preferred_supercar,
          "shrMeetAndGreet": String(userPreferences.data.attributes.special_handling_request?.meet_greet),
          "shrPlaneSidePickUp": String(userPreferences.data.attributes.special_handling_request?.planeside_pickup)
        }
      )
  }, [userPreferences])

  const onSelect = (selectedList: any, selectedItem: any) => {
    switch (expanded) {
      case '4': {
        setPreferedAirports((prePreferedAirport: any) => [
          ...prePreferedAirport,
          selectedItem
        ])
        break;
      }
      case '3': {
        setPreferedAircrafts((prePreferedAircraft: any) => [
          ...prePreferedAircraft,
          selectedItem
        ])
        break;
      }
      case '2': {
        setSelectedHotel((prevHotels: any) => [
          ...prevHotels,
          selectedItem
        ])
      }
    }
  }

  const onRemove = (removedList: any, removedItem: any) => {
    switch (expanded) {
      case '4': {
        if(isNaN(removedItem)){
          setPreferedAirports(
            preferedAirports.filter((item: any) => item.id !== removedItem.id)
          )
        }else {
          const temp = preferedAirports;
          temp.pop();
          setPreferedAirports(temp);
        }
        break;
      }
      case '3': {
        if(isNaN(removedItem)){
          setPreferedAircrafts(
            preferedAircrafts.filter((item: any) => item.id !== removedItem.id)
          )
        }else {
          const temp = preferedAircrafts;
          temp.pop();
          setPreferedAircrafts(temp);
        }
        break;
      }
      case '2': {
        if(isNaN(removedItem)){
          setSelectedHotel(
            selectedHotel.filter((item: any) => item.id !== removedItem.id)
          )
        }else {
          const temp = selectedHotel;
          temp.pop();
          setSelectedHotel(temp);
        }
      }
    }
  }

  const SearchTerm = (value: string) => {
    switch (expanded) {
      case '4': {
        value.length>3 && setAirportTerm(value)
        break;
      }
      case '3': {
        value.length>3 && setAircraftTerm(value)
        break;
      }
    }
  }

  const DefaultValues = () => {
    switch (expanded) {
      case '4': {
        return preferedAirports
      }
      case '3': {
        return preferedAircrafts
      }
      case '2': {
        return selectedHotel
      }
    }
  }

  const multiSelectLoading = () => {
    switch (expanded) {
      case '4': {
        return airportLoading
      }
      case '3': {
        return aircraftLoading
      }
      case '2': {
        return hotelLoading
      }
    }
  }

  const handleArrowDown = (event:any) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      const containerUl = Array.from(document.querySelectorAll('.optionContainer'));
      const highlightedLi = document.querySelector('.highlight') as HTMLElement;
      if (highlightedLi) {
        const newList = containerUl.filter((item:any) => Array.from(item.childNodes).filter((things:any) => things.innerText===highlightedLi.innerText).length>0)
        newList[0].scrollTo(0,highlightedLi.offsetTop)
      }
    }
  };

  useEffect(() => {
    userReFetchUserPreferences()
    window.addEventListener('keydown', handleArrowDown);
    return () => {
      window.removeEventListener('keydown', handleArrowDown);
    };
  }, [])

  const handleChangeMyPreferences = (e: any) => {
    const myPreferencesname = e.target.name
    const myPreferencesobj = {
      ...valueSet,
      [myPreferencesname]: e.target.value
    }
    setValueSet(myPreferencesobj)
  }

  const [invalidEmailError, setInvalidEmailError] = useState<boolean>(false)

  const handleSubmit = () => {

    setError([])
    if (!isValid()) {
      setError(["Please check errors!"])
      return
    }

    function isValidEmailPreferences(emailpreferences:string) {
      const emailRegexPreferences = emailRegex;
      return emailRegexPreferences.test(emailpreferences);
    }

    if(!isValidEmailPreferences(valueSet["emergecyContactEmail"])){
      setInvalidEmailError(true)
      setError(["Please check errors!"])
      return
    }
    
    if(!isValidPhoneNumber(`${valueSet["emergecyContactPhone"]}`)){
      setinvalidPhoneError(true)
      setError(["Please check errors!"])
      setMissingFields((prevMissingField:any) => [...prevMissingField, "emergecyContactPhone"])
      return
    }

    const payload = {
      "emergency_contact": {
        "full_name": valueSet["emergencyContactFullName"],
        "relationship": valueSet["emergecyContactRelationShip"] || "",
        "phone": valueSet["emergecyContactPhone"],
        "email": valueSet["emergecyContactEmail"]
      },

      "hotels": selectedHotel?.map((item: any) => {
        return {
          "hotel_name": item.name
        }
      }),

      "catering_preference": {
        "beverages": valueSet["beverage"] || "",
        "dietary": valueSet["dietry"] || "",
        "preferred_dietary": valueSet["preferredDietry"] || "",
        "allergies": valueSet["allergies"] || ""
      },

      "ground_transports": {
        "vehicle_preference_type": valueSet["gtpVechilePreType"] || "",
        "preferred_provider": valueSet["gptPrefrredProvider"] || "",
        "preferred_supercar": valueSet["gptPreSuperCar"] || ""
      },
      "special_handling_requests": {
        "meet_greet": valueSet["shrMeetAndGreet"] === 'true',
        "planeside_pickup": valueSet["shrPlaneSidePickUp"] === 'true'
      }
    }

    const addedAirportList = preferedAirports?.filter((selected: any) => !favAirportList?.data?.some((airport: any) => airport.attributes.airport_id === selected.id));
    const removedAirportList = favAirportList?.data?.filter((airport: any) => !preferedAirports?.some((selected: any) => selected.id === airport.attributes.airport_id));

    removedAirportList?.map((item: any) => {
      delFavAirport(item.id, {
        onError() {
          setError(prevError => [...prevError, `Airport not updated! (id : ${item.id}`]);
        },
      })
    })
    addedAirportList?.map((item: any) => {
      postFavAirports({ "airport_id": item.id })
    })

    const addedAircraftList = preferedAircrafts?.filter((selected: any) => !favAircraftList?.data?.some((aircraft: any) => aircraft.attributes.aircraft_id === selected.id));
    const removedAircraftList = favAircraftList?.data?.filter((aircraft: any) => !preferedAircrafts?.some((selected: any) => selected.id === aircraft.attributes.aircraft_id));

    removedAircraftList?.map((item: any) => {
      delFavAircraft(item.id, {
        onError() {
          setError(prevError => [...prevError, `Aircraft not updated! (id : ${item.id})`]);
        },
      })
    })
    addedAircraftList?.map((item: any) => {
      postFavAircrafts({ "aircraft_id": item.id })
    })

    postPreferences(payload, {
      onSuccess() {
        refetchFavAircraft()
        refetchFavAirport()
        refetchFavHotel()
        userReFetchUserPreferences()
        navigate("/account#book")
      },
      onError(error) {
        const { errors: [{ token: errorMessage }] } = error;
        setError([errorMessage]);
      }
    });
  }

  const isValid = () => {
    let inputKeys: Array<string> = []
    let requiredAccountAccordian = accountAccordian.filter((item:any) => item.required)
    requiredAccountAccordian.forEach((item: any) => item.inputFields.map((item: any) => inputKeys.push(item.name)))
    inputKeys = inputKeys.filter(item => item != 'emergecyContactRelationShip')
    let dataKeys: Array<string> = Object.keys(valueSet)
    let validFlag = true
    let missingFields: Array<string> = []

    if (preferedAirports.length > 0) dataKeys.push('preffredAirport')

    inputKeys.forEach((key: string) => {
      let condition1 = !dataKeys.includes(key)
      let condition2 = !valueSet[key] && !['preffredAirport'].includes(key)

      if (condition1 || condition2) {
        validFlag = false
        missingFields.push(key)
      }

    })

    setMissingFields(missingFields)

    return validFlag
  }

  const variants = {
    shake: {
      x: [40, -40, 40, -40, 40, 0],
    },
    noShake: {
      x: 0,
    },
  };

  const renderAccordianSummary = (item:any, index:any, error:any) => {
    return(
      <JetZeroAccordionSummary>
        <Box sx={{ width: "100%", display: 'flex', alignItems: "center", borderBottom: "1px solid #2C62E0", padding: { xs: '0px', sm: "0px", md: "10px 0px" } }}>
          <Typography variant="h4" color={error ? "error" : "primary"} className={classes.numberTypoMyPreferences}>{String(index + 1).padStart(2, '0')}</Typography>
          <m.div
            style={{width:"100%"}}
            animate={
              error ? "shake" : "noShake"
            }
            variants={variants}
            transition={{
              type: "spring",
            }}
          >
            <Headings style={{color: error ? "#f44336" : "black"}} variant="h3">
              {item.heading}
              {item.required && <span style={{ color: error ? "#f44336" : "rgba(44, 98, 224, 1)" }}>*</span>}
              <span>&nbsp;{item?.subHeading}</span>
            </Headings>
          </m.div>
          <div style={{ flexGrow: 1 }} />
          <Typography style={{ width: "30px", textAlign: "center", fontSize: "30px", lineHeight: "40px", fontWeight: 600, color: error ? "#f44336" : "#2C62E0" }}> {expanded === index.toString() ? "-" : "+"} </Typography>
        </Box>
     </JetZeroAccordionSummary>
    )
  }

  const renderFieldComponent = (fields:any) => {
    let fieldComponent;
    if (fields.type === 'select') {
      fieldComponent = (
        <StyledSelect
          name={fields.name}
          onChange={(e: any) => handleChangeMyPreferences({ target: { value: e.value, name: fields.name } })}
          options={fields?.options?.map((option: any) => ({ label: option.label, value: option.value }))}
          value={fields?.options?.find(
            (item: any) =>
              item.value === String(valueSet[fields.name])
          )}
          placeholder={fields.placeHolder}
          classNamePrefix={'custom-select'}
          aria-label={'account-field-' + fields.name}
        />
      );
    } else if (fields.type === 'multiselect') {
      fieldComponent = (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginBottom: "5px",
          }}
        >
          <SearchIcon
            style={{
              marginRight: "15px",
              fontSize: "25px",
              marginTop: "15px",
            }}
            color="primary"
          />
          <MultiselectReactDropdown
            options={fields?.options}
            avoidHighlightFirstOption
            selectionLimit={fields.name === "preffredAirport" || fields.name === "preffredAircraft" ? 3 : -1}
            onSearch={SearchTerm}
            selectedValues={DefaultValues()}
            onSelect={onSelect}
            onRemove={onRemove}
            loading={multiSelectLoading()}
            displayValue="name"
            placeholder={fields.placeHolder}
            customCloseIcon={<CloseIcon />}
            style={customStyles}
          />
        </div>
      );
    } else if (fields.type === 'phoneNumber') {
      fieldComponent = (
        <>
          <PhoneInput
            international
            value={value}
            onChange={setValue}
            placeholder="Enter phone number"
            data-test-id={'account-field-' + fields.name}
          />
          <Typography variant="h5" style={{ marginTop: "5px" }}>
            Do not add '0' before number
          </Typography>
        </>
      )
    } else {
      fieldComponent = (
        <TextField
          fullWidth
          type="string"
          onChange={(e: any) => handleChangeMyPreferences(e)}
          placeholder={fields.placeHolder}
          name={fields.name}
          style={{ marginBottom: "10px" }}
          value={valueSet[fields.name]}
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
            'data-test-id': 'account-field-' + fields.name
          }}
        />
      );
    }
    return fieldComponent
  }

  const renderAccordianDetail = (item:any) => {
    return(
      <JetZeroAccordionDetails>
        {
          item.inputFields.map((fields: any, index: number) => {
            let hasError = missingFields.includes(fields.name)
            let fieldComponent = renderFieldComponent(fields);
            
            let errorMessage = hasError && "can't be blank"
            if(invalidPhoneError && fields.name=='emergecyContactPhone'){
              errorMessage = "Invalid phone number."
            }
            if(invalidEmailError && fields.name=='emergecyContactEmail'){
              errorMessage = "Invalid email."
            }
            return (
              <Box
                key={'account-accordion-item-' + item.heading + '-' + fields.label}
                sx={{
                  borderBottom: "1px solid #2C62E0",
                  padding: { xs: '10px 0px 0px 0px', sm: "10px 0px 0px 0px", md: "10px 0px" },
                }}
              >
                {item.inputFields.length > 1 && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h4" color="primary" style={{ width: "60px" }}>{String(index + 1).padStart(2, '0')}</Typography>
                    <Headings variant="h3">{fields.label}
                      {item.required && fields.name !== 'emergecyContactRelationShip' && <span style={{ color: "rgba(44, 98, 224, 1)" }}>*</span>}
                    </Headings>
                  </Box>
                )}
                <Box sx={{ marginLeft: item.inputFields.length > 1 ? "60px" : "0px" }}>
                  <InputContainer>
                    {fieldComponent}
                  </InputContainer>
                  <Typography style={{ paddingTop: 4 }} variant='h5' color='error'>{errorMessage}</Typography>
                </Box>
              </Box>
            )
          })
        }
      </JetZeroAccordionDetails>
    )
  }

  const renderAccordian = (item:any, index:any) => {
    let error = item.inputFields.map((item:any) => item.name)
                          .map((item:any) => missingFields.includes(item))
                          .reduce((acc:any, curr:any)=> {
                                                          return acc || curr
                                                        }, false)
    return (
              <JetZeroAccordion data-test-id={'accordion-preferences-' + item.heading} key={'account-accordion-key-' + item.heading} expanded={expanded === index.toString()} onChange={handleChangeS(index.toString())}>
                {renderAccordianSummary(item, index, error)}
                {renderAccordianDetail(item)}
              </JetZeroAccordion>
            )
          
  }

  return (
    <Box style={{ overflowX: "hidden" }} className="rootContainer1" sx={{ padding: { sm: "20px 40px", md: "20px 40px" }, display: "block" }}>
      <div></div>

      <m.div
        initial={{
          x: "100%",
        }}
        animate={{
          x: "0%",
        }}
        transition={{
          duration: 0.75,
          ease: "easeOut",
        }}
        style={{ width: "100%" }}
      >
        <Box className={classes.containerMyPreferences}>
          {accountAccordian.map((item, index) => renderAccordian(item, index))}
          <Box>
            {error.map((item) => {
              return (
                <Typography key={item} variant='body2' color='error'>{item}</Typography>
              )
            })}
          </Box>
          <Box className={classes.arrowContainerMyPreferences} style={{ marginBottom: "166px" }}>
            <Box className={classes.blueLinesMyPreferences}><div className={classes.arrow}></div></Box>
            
            <Button
              data-test-id='btn-save'
              color="primary"
              variant="text"
              className={classes.sendButtonMyPreferences}
              type="submit"
              onClick={() => handleSubmit()}
            >
              {"SAVE"}
              <HideContainer hideWhen={!postLoading}>
                <CircularProgress
                  size={20}
                  style={{ margin: "0 5px" }}
                  color="primary"
                />
              </HideContainer>
            </Button>
          </Box>
        </Box>
      </m.div>
    </Box>
  );
}
// Customizable Area End
