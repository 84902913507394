import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = ({ children, location: { pathname } }: any) => {
  useEffect(() => {
    if (!pathname.includes('privacy'))
      requestAnimationFrame(() =>
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        }));
  }, [pathname]);

  return children || null;
};

export default withRouter(ScrollToTop);
