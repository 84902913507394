import React from "react";
import { Box, Typography, TextField, styled } from "@material-ui/core";
import { JetZeroAccordion, JetZeroAccordionSummary, JetZeroAccordionDetails } from '../../../components/src/shared';
import { InputContainer } from '../../../blocks/user-profile-basic/src/MyPreferences'

import StyledSelect from "../../../blocks/user-profile-basic/src/StyledSelect";

export const DocumentAccordionItem = ({ item, index, expanded, change, handleChange, valueSet, classes }: { classes: any, change: any, item: any, index: any, expanded: any, handleChange: any, valueSet: any }) => {
  const isExpanded = expanded === index.toString();
  const number = String(index + 5).padStart(2, '0');

  return (
    <JetZeroAccordion
      data-test-id={'document-accordion-' + item.heading}
      key={item.details}
      expanded={isExpanded}
      onChange={change}
    >
      <JetZeroAccordionSummary>
        <Box sx={{
          width: "100%",
          display: 'flex',
          alignItems: "center",
          borderBottom: "1px solid #2C62E0",
          padding: { xs: '0px', sm: "0px", md: "10px 0px" }
        }}>
          <FieldText variant="h3" color="primary" className={classes.numberTypo}>{number}</FieldText>
          <FieldText variant="h3" style={{ textTransform: "uppercase" }}>
            {item?.heading} <span style={{ color: "#c1c1c1" }}>{item?.subHeading}</span>
            {item?.required && <span style={{ color: "rgba(44, 98, 224, 1)" }}>*</span>}
          </FieldText>
          <div style={{ flexGrow: 1 }} />
          <Typography style={{
            width: "30px",
            textAlign: "center",
            fontSize: "30px",
            lineHeight: "40px",
            fontWeight: 600,
            color: "#2C62E0"
          }}>
            {isExpanded ? "-" : "+"}
          </Typography>
        </Box>
      </JetZeroAccordionSummary>
      <JetZeroAccordionDetails>
        {item?.inputFields.map((fields: any, fieldIndex: any) => {
          const fieldKey = `document-accordion-${item.heading}-${fields.label}`;
          const isMultipleFields = item.inputFields.length > 1;

          return (
            <Box key={fieldKey} sx={{
              borderBottom: "1px solid #2C62E0",
              padding: { xs: '10px 0px 0px 0px', sm: "10px 0px 0px 0px", md: "10px 0px" }
            }}>
              <InputContainer>
                {isMultipleFields && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h4" color="primary" style={{ width: "60px" }}>
                      {String(fieldIndex + 1).padStart(2, '0')}
                    </Typography>
                    <FieldText variant="h3" style={{ textTransform: "uppercase" }}>
                      {fields?.label}
                    </FieldText>
                  </Box>
                )}
                <Box sx={{ marginLeft: isMultipleFields ? "60px" : "0px" }}>
                  {fields.type === 'select' ?
                    <StyledSelect
                      aria-label={'document-upload-field-' + fields.name}
                      onChange={(e: any) => handleChange({ target: { name: fields.name, value: e.value } })}
                      classNamePrefix={'custom-select'}
                      placeholder={fields.placeHolder}
                      options={fields?.options}
                      name={fields.name}
                      value={fields?.options.find(
                        (item: any) =>
                          item.value === valueSet[fields.name]
                      )}
                    />
                    :
                    <TextField
                      fullWidth
                      onKeyDown={(e:any) => {
                        if (fields.type === 'number') {
                          const regex = /^\d$/;
                          if (!regex.test(e.key) && e.key !== 'Backspace') {
                            e.preventDefault();
                          }
                        }
                        if (fields.placeHolder === "MM/YY"){
                          const regex = /^\d{0,2}\/?\d{0,2}$/;
                          const inputValue = e.target.value + e.key;
                          if (!regex.test(inputValue) && e.key !== 'Backspace') {
                            e.preventDefault();
                          } else if (inputValue.length === 2 && e.key !== 'Backspace') {
                            e.target.value = inputValue + '/';
                            e.preventDefault();
                          }
                        }
                      }}
                      type={fields.type === "date" ? "date" : "string"}
                      onChange={(e) => handleChange(e)}
                      placeholder={fields.placeHolder}
                      name={fields.name}
                      style={{ marginBottom: "10px" }}
                      value={valueSet[fields.name]}
                      InputProps={{
                        disableUnderline: true
                      }}
                      inputProps={{
                        ...(fields.name === "issue_date" && {
                          max: new Date().toISOString().split("T")[0],
                        }),
                        ...(fields.name === "passport_expiry_date" && {
                          min: new Date().toISOString().split("T")[0],
                        }),
                        'data-test-id': 'document-upload-field-' + fields.name
                      }}
                    />
                  }
                  <Typography variant='h5' color='error'>{fields.error}</Typography>
                </Box>
              </InputContainer>
            </Box>
          );
        })}
      </JetZeroAccordionDetails>
    </JetZeroAccordion>
  );
};

const FieldText = styled(Typography)({
  "@media only screen and (max-width: 1150px)": {
    fontSize: 15
  },
  "@media only screen and (max-width: 768px)": {
    fontSize: 12
  }
})