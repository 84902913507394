import React from "react";

// Customizable Area Start
//@ts-ignore
import {motion as m} from 'framer-motion';
import AccountPages2 from "./AccountPages2.web";
// Customizable Area End


import UserProfileControllerWeb, { Props } from "./UserProfileController.web";
import { AccountLayout } from "./AccountLayout.web";
import AccountPages from "./AccountPages.web";

export default class UserProfile extends UserProfileControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div style={{overflowX:"hidden"}}>
        <m.div
          initial={{
            x : "100%"
          }}
          animate = {{
            x : "0%"
          }}
          transition={{
            duration : 0.75,
            ease : "easeOut"
          }}
        >
          <AccountLayout name={this.state?.account?.full_name} gender={this.state?.account?.gender}/>
          <AccountPages2 accountAccount={this.state?.account}/>
        </m.div>
        </div>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  styles = {
    
  }
  // Customizable Area End
}
