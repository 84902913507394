import React from "react";
import { Box, Button, styled,Typography } from "@material-ui/core";
import InvoiceControllerWeb from "./InvoiceController.web";
import { downloadIcon } from "./assets";
import { Skeleton } from "@material-ui/lab";

export default class Invoice extends InvoiceControllerWeb {

  container = (contain:any) => {
    const ContainerWrapper = styled(Box)({
      "@media only screen and (max-width: 768px)": {
        paddingTop: 2
      }
    });
    return <ContainerWrapper>{contain}</ContainerWrapper>
  }

  table = (contain:any) => {
    const Table = styled("div")({
      fontSize: 20,
      fontWeight: 600,
      paddingBottom: 147,
    
      "& > div > div": {
        display: "grid",
        padding: "8px 0px",
        borderBottom: "1px solid #2C62E0",
        gridTemplateColumns: "1fr 1fr 180px 1fr 1fr"
      },
      "& > div > div > div": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      "& > div > div > div:first-child": {
        color: "#2C62E0",
        justifyContent: "flex-start"
      },
      "& > div > div > div:last-child": {
        justifyContent: "flex-end"
      },
      "& > div > div > div:nth-child(2)": {
        justifySelf: "flex-end"
      },
      "& > div > div > div:nth-child(4)": {
        justifySelf: "flex-start"
      },
    
      "@media only screen and (max-width: 1500px)": {
        fontSize: 16
      },
      "@media only screen and (max-width: 725px)": {
        fontSize: 10
      },
      "@media only screen and (max-width: 550px)": {
        "& > div > div": {
          gridTemplateColumns: "1fr 1fr 25px 1fr 1fr"
        },
        "& > div > div > div:nth-child(2)": {
          paddingRight: 8
        },
        "& > div > div > div:nth-child(4)": {
          paddingLeft: 8
        }
      }
    });
    return <Table>{contain}</Table>
  }

  line = (contain:any) => {
    const Line = styled("div")({
      width: "80%",
      height: "1px",
      backgroundColor: "#2C62E0"
    });
    return <Line>{contain}</Line>
  }

  isMobileScreen = window.outerWidth < 768

  render() {
    return (
      this.container(
      <Box
        className="rootContainer1"
        sx={{ padding: { sm: "20px 40px", md: "20px 40px" }, display: "block" }}
      >
        {
          this.state.error !== "" && <Typography style={{paddingBottom:147}} variant='h3' color='error'>{this.state.error}</Typography>
        }
        {
          this.state.loading && <Skeleton style={{marginBottom:"70px"}} variant="rect" />
        }
        {this.state.invoice.length>0 && this.table(
          <div>
            {this.state.invoice.map((item:any) => (
              <div key={item.avinode_trip_id}>
                <div>{item.trip_id}</div>
                <div>{item.from_airport_city}</div>
                <div>
                  {this.line(<div />)}
                </div>
                <div>{item.to_airport_city}</div>
                <div>
                  <Button
                    data-test-id={`downloadBtn-${item.trip_id}`}
                    color="primary"
                    variant="text"
                    endIcon={<img src={downloadIcon} alt="download" />}
                    style={{fontWeight:600}}
                    onClick={()=>this.handleDownload(item.pdf_link.href)}
                  >
                    {this.isMobileScreen ? "" : "DOWNLOAD"}
                  </Button>
                </div>
              </div>
            ))}
          </div>)
        }
      </Box>)
    );
  }
}
