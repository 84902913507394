import React, { useState } from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { makeStyles } from "@material-ui/core/styles";
import { styled, Typography, Button, Box } from "@material-ui/core";

import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { appendJsonContentType } from "../../../components/src/utils/http-response";
import { makeHttpMessage } from "../../../components/src/utils/http-message";
import { HTMLParser } from "../../../components/src/utils/html-parser";
import { HideContainer } from "../../../components/src/shared";

import { Service } from "../../../components/src/types";
import { IBlock } from "../../../framework/src/IBlock";

interface Props {}
interface SS {}
interface S {
  apiCallId: string;
  services: Array<ServiceInterfaceItem>;
}

interface ServiceInterfaceItem {
  heading: string;
  subTitle: string;
  paragraph: string;
  subHeading: string;
  description: string;
  subComponent?: { subHeading: string; subText: string }[];
}

class PrivacyTermsContent extends BlockComponent<Props, S, SS> {
  state = {
    apiCallId: "",
    services: [],
  };

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive(from: string, message: Message): void {
    let requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let sucessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestId === this.state.apiCallId) {
      this.setState({ services: sucessResponse.data });
    }
  }

  getServicesApi() {
    let message = makeHttpMessage({
      header: appendJsonContentType({}),
      httpMethod: "GET",
      url: "/bx_block_self_content/services",
    });
    this.setState({
      apiCallId: message.messageId,
    });

    runEngine.sendMessage(message.id, message);
  }

  componentDidMount(): any {
    super.componentDidMount();
    this.getServicesApi();
    localStorage.getItem("token") && sessionStorage.setItem("token", `${localStorage.getItem("token")}`)
  }

  serviceResponseMapper(item: Service): ServiceInterfaceItem {
    let policyComponents: ServiceInterfaceItem["subComponent"] = [];

    const heading = item?.attributes?.service_name ?? "";
    const subHeading = item?.attributes?.policy?.data?.attributes?.title ?? "";
    const subTitle = item?.attributes?.policy?.data?.attributes?.heading ?? "";
    const description =
      item?.attributes?.policy?.data?.attributes?.description ?? "";
    const paragraph =
      item?.attributes?.policy?.data?.attributes?.policy_paragraphs?.data?.[0]
        ?.attributes?.paragraph ?? "";
    const policy_components =
      item?.attributes?.policy?.data?.attributes?.policy_components?.data ?? [];

    if (policy_components !== "no policy components available")
      policyComponents = policy_components?.map(
        ({ attributes: { component_heading, component_description } }) => ({
          subHeading: component_heading,
          subText: component_description,
        })
      );

    return {
      description,
      heading,
      subHeading,
      subTitle,
      subComponent: policyComponents,
      paragraph,
    };
  }

  render() {
    return (
        <motion.div
          initial={{
            x: "100%",
          }}
          animate={{
            x: "0%",
          }}
          transition={{
            duration: 0.75,
            ease: "easeOut",
          }}
        >
          <Container>
            {this.state.services.map((item: any) => (
              <ServiceItem
                service={this.serviceResponseMapper(item)}
                key={item.heading}
              />
            ))}
          </Container>
        </motion.div>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  root: {},
  serviceItemContainer: {
    paddingTop: "10vh",
  },
  heading: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.2rem",
    lineHeight: "40px",
    letterSpacing: "0.2em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      lineHeight: "20px",
    },
  },
  approchText: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "40px",
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
      fontSize: "15px",
      lineHeight: "20px",
    },
  },
  reducedFontSize: {
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      lineHeight: "20px",
    },
  },
  text: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.2rem",
    lineHeight: "28px",
    textAlign: "justify",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
      lineHeight: "21px",
    },
  },
  subTitleText: {
    color: "#999",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
      lineHeight: "1.5",
    },
  },
  text1Container: {
    marginTop: "42px",
    maxWidth: "70%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  text2Container: {
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  scrollableContainerFolded: {
    maxHeight: "400px",
    overflow: "hidden",
  },
  scrollableContainerExpanded: {
    overflowY: "auto",
    maxHeight: "400px",
    paddingRight: "20px",
  },
  seemoreText: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    textTransform: "capitalize",
    padding: "0",
    marginTop: "10px",
  },
}));

function ServiceItem({ service }: { service: ServiceInterfaceItem }) {
  const item = service;
  const classes = useStyles();

  const {
    heading,
    subTitle,
    paragraph,
    subHeading,
    description,
    subComponent,
  } = service;

  const [showMore, setShowMore] = useState(false);

  function readMore() {
    setShowMore(true);
  }

  let shortDescription = description
    ?.split(" ")
    .slice(0, 30)
    .join(" ");

  let shownDescription = showMore ? description : shortDescription;

  return (
    <GridDiv
      data-test-id="privacy-page-headings"
      id={String(item.heading).toLowerCase()}
      style={{ paddingTop: 110 }}
    >
      <div>
        <Typography className={classes.heading} data-testid="heading">
          <HTMLParser content={heading || ""} />
        </Typography>
      </div>
      <div>
        <div
          className={clsx(classes.scrollableContainerFolded, {
            [classes.scrollableContainerExpanded]: showMore,
          })}
        >
          <Typography className={classes.approchText}>
            <HTMLParser content={subHeading || ""} />
          </Typography>
          <Typography className={clsx(classes.subTitleText, classes.text)}>
            <HTMLParser content={subTitle || ""} />
          </Typography>
          <Box
            sx={{
              paddingTop: "30px",
              maxWidth: { xs: "100%", sm: "100%", md: "70%" },
            }}
          >
            <Typography className={classes.text}>
              <HTMLParser content={paragraph || ""} />
            </Typography>
          </Box>
          <Box style={{ paddingTop: "15px" }}>
            <Typography className={classes.text} component="span">
              <HTMLParser content={shownDescription || ""} />
            </Typography>
          </Box>
          <HideContainer hideWhen={!showMore}>
            {subComponent?.map(({ subHeading, subText }, index: number) => (
              <div style={{ marginTop: "30px" }} key={index}>
                <Typography
                  className={clsx(classes.approchText, classes.reducedFontSize)}
                  style={{
                    marginTop: "30px",
                    marginBottom: "20px",
                  }}
                >
                  <HTMLParser content={subHeading || ""} />
                </Typography>

                <Typography
                  className={clsx(classes.subTitleText, classes.text)}
                >
                  <HTMLParser content={subText || ""} />
                </Typography>
              </div>
            ))}
          </HideContainer>
        </div>
        <HideContainer hideWhen={showMore}>
          <Button
            color="primary"
            variant="text"
            data-test-id={"seeMoreButton"}
            onClick={readMore}
            className={clsx(classes.text, classes.seemoreText)}
          >
            {"see more"}
          </Button>
        </HideContainer>
        <HideContainer hideWhen={!showMore}>
          <Box style={{ height: "28px", width: "100%" }}></Box>
        </HideContainer>
      </div>
    </GridDiv>
  );
}

const GridDiv = styled("div")({
  gridGap: 104,
  display: "grid",
  gridTemplateColumns: "154px 1fr",
  "@media only screen and (max-width: 1300px)": {
    gridGap: 50,
  },
  "@media only screen and (max-width: 1000px)": {
    display: "block",
    "& > div:first-child": {
      paddingBottom: 32,
      "@media only screen and (max-width: 390px)": {
        paddingBottom: 0,
      },
    },
  },
});

const Container = styled("div")({
  padding: "100px 260px 200px 110px",
  "@media only screen and (max-width: 960px)": {
    padding: "100px 40px 200px 40px",
  },
});

export default PrivacyTermsContent;
