export const configJSON = require("../../../blocks/user-profile-basic/src/config.js");

export const valuesetDefault =  {
    "activated": false,
    "country_code": "",
    "email": "",
    "first_name": "",
    "middle_name": "",
    "last_name": "",
    "full_phone_number": "",
    "phone_number": "",
    "home_address_line_1": "",
    "home_address_line_2": "",
    "home_address_city": "",
    "home_address_country": "",
    "home_address_zip_code": "",
    "company_address_line_1": "",
    "company_address_line_2": "",
    "company_address_city": "",
    "company_address_country": "",
    "company_address_zip_code": "",
    "type": false,
    "full_name": "",
    "user_name": "",
    "gender": "",
    "date_of_birth": "",
    "age": 0,
    "pronoun": "",
    "company_name": "",
    "assistants_email": "",
    "citizenship": "",
    "country_of_residence": "",
    "approx_weight": 0,
    "passport_number": "",
    "nervous_flyer": false,
    "created_at": "",
    "updated_at": "",
    "device_id": "",
    "unique_auth_id": "",
    "terms": false,
    "policy": false,
    "first_time_selection_at": ""
  }

  export const accountAccordian = [
    {
      headingAccount: "Name",
      requiredAccount: true,
      inputFieldsAccount: [
        {
          placeHolderAccount: "John",
          nameAccount: "first_name",
          labelAccount: "First Name",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "text",
        },
        {
          placeHolderAccount: "Joseph",
          nameAccount: "middle_name",
          labelAccount: "Middle Name",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "text",
        },
        {
          placeHolderAccount: "Dhee",
          nameAccount: "last_name",
          labelAccount: "Last Name",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "text",
        },
      ],
    },
    {
      headingAccount: "Home Address",
      requiredAccount: true,
      inputFieldsAccount: [
        {
          placeHolderAccount: "#21st street",
          nameAccount: "home_address_line_1",
          labelAccount: "First Line",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "text",
        },
        {
          placeHolderAccount: "2nd line",
          nameAccount: "home_address_line_2",
          labelAccount: "Second Line",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "text",
        },
        {
          placeHolderAccount: "Miami",
          nameAccount: "home_address_city",
          labelAccount: "City",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "text",
        },
        {
          placeHolderAccount: "United states",
          nameAccount: "home_address_country",
          labelAccount: "Country",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "text",
        },
        {
          placeHolderAccount: "561226",
          nameAccount: "home_address_zip_code",
          labelAccount: "Post Code / Zip",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "number",
        },
      ],
    },
    {
      headingAccount: "Pronoun",
      requiredAccount: true,
      inputFieldsAccount: [
        {
          placeHolderAccount: "Select Pronoun",
          nameAccount: "pronoun",
          typeAccount: "select",
          labelAccount: "Pronoun",
          errorAccount: "",
          valueAccount: "",
          optionsAccount: [
            { label: "He/Him", value: "He/Him" },
            { label: "She/Her", value: "She/Her" },
            { label: "They/Them", value: "They/Them" },
            { label: "Ze/Hir", value: "Ze/Hir" },
          ],
        },
      ],
    },
    {
      headingAccount: "Company Name",
      requiredAccount: false,
      inputFieldsAccount: [
        {
          placeHolderAccount: "Please enter company name",
          nameAccount: "company_name",
          labelAccount: "Company Name",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "text",
        },
      ],
    },
    {
      headingAccount: "Company Address",
      requiredAccount: false,
      inputFieldsAccount: [
        {
          placeHolderAccount: "#3rd Street",
          nameAccount: "company_address_line_1",
          labelAccount: "First Line",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "text",
        },
        {
          placeHolderAccount: "ADC Building",
          nameAccount: "company_address_line_2",
          labelAccount: "Second Line",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "text",
        },
        {
          placeHolderAccount: "New York",
          nameAccount: "company_address_city",
          labelAccount: "City",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "text",
        },
        {
          placeHolderAccount: "US",
          nameAccount: "company_address_country",
          labelAccount: "Country",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "text",
        },
        {
          placeHolderAccount: "562926",
          nameAccount: "company_address_zip_code",
          labelAccount: "Post Code / Zip",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "number",
        },
      ],
    },
    {
      headingAccount: "Phone",
      requiredAccount: true,
      inputFieldsAccount: [
        {
          placeHolderAccount: "Please enter phone number",
          nameAccount: "full_phone_number",
          labelAccount: "Phone",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "number",
        },
      ],
    },
    {
      headingAccount: "Email",
      requiredAccount: true,
      inputFieldsAccount: [
        {
          placeHolderAccount: "Please enter email",
          nameAccount: "email",
          labelAccount: "Email",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "email",
        },
      ],
    },
    {
      headingAccount: "Assistant's Email",
      requiredAccount: false,
      inputFieldsAccount: [
        {
          placeHolderAccount: "Please enter assistants email",
          nameAccount: "assistants_email",
          labelAccount: "Assistant’s Email",
          errorAccount: "",
          valueAccount: "",
          typeAccount: "email",
        },
      ],
    },
    {
      headingAccount: "Citizenship",
      requiredAccount: true,
      inputFieldsAccount: [
        {
          placeHolderAccount: "Please enter citizenship",
          nameAccount: "citizenship",
          typeAccount: "select",
          labelAccount: "Citizenship",
          errorAccount: "",
          valueAccount: "",
          optionsAccount: configJSON.countryList,
        },
      ],
    },
    {
      headingAccount: "Country of Residence",
      requiredAccount: true,
      inputFieldsAccount: [
        {
          placeHolderAccount: "Please enter country of residence",
          nameAccount: "country_of_residence",
          typeAccount: "select",
          labelAccount: "Country of Residence",
          errorAccount: "",
          valueAccount: "",
          optionsAccount: configJSON.countryList,
        },
      ],
    },
    {
      headingAccount: "DOB",
      requiredAccount: true,
      inputFieldsAccount: [
        {
          placeHolderAccount: "Please enter date of birth",
          nameAccount: "date_of_birth",
          typeAccount: "date",
          labelAccount: "Citizenship",
          errorAccount: "",
          valueAccount: "",
        },
      ],
    },
    {
      headingAccount: "Gender",
      requiredAccount: true,
      inputFieldsAccount: [
        {
          placeHolderAccount: "Please enter gender",
          nameAccount: "gender",
          typeAccount: "select",
          labelAccount: "Gender",
          errorAccount: "",
          valueAccount: "",
          optionsAccount: [
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
            { label: "Anatomical", value: "Anatomical" },
            { label: "Trans", value: "Trans" },
            { label: "Non-Binary", value: "Nonbinary" },
            { label: "Personal", value: "Personal" },
            { label: "Cishet", value: "Cishet" },
          ],
        },
      ],
    },
    {
      headingAccount: "Approx Weight (Lbs)",
      requiredAccount: true,
      inputFieldsAccount: [
        {
          placeHolderAccount: "Please Enter Approx Weight in lbs",
          nameAccount: "approx_weight",
          typeAccount: "number",
          labelAccount: "Approx Weight (lbs)",
          errorAccount: "",
          valueAccount: "",
        },
      ],
    },
    {
      headingAccount: "Passport Number",
      requiredAccount: true,
      inputFieldsAccount: [
        {
          placeHolderAccount: "Please Enter Passport Number",
          nameAccount: "passport_number",
          typeAccount: "passport",
          labelAccount: "Approx Weight",
          errorAccount: "",
          valueAccount: "",
        },
      ],
    },
    {
      headingAccount: "Nervous Flyer",
      requiredAccount: true,
      inputFieldsAccount: [
        {
          placeHolderAccount: "Please enter yes/no",
          nameAccount: "nervous_flyer",
          typeAccount: "select",
          labelAccount: "Nervous Flyer",
          errorAccount: "",
          valueAccount: "",
          optionsAccount: [
            { label: "Yes", value: true },
            { label: "No", value: false },
          ],
        },
      ],
    },
    {
      headingAccount: "Reset Password",
      requiredAccount: false,
      inputFieldsAccount: [
        {
          placeHolderAccount: "Please Enter Old Password",
          nameAccount: "oldPassword",
          typeAccount: "text",
          labelAccount: "Old Password",
          errorAccount: "",
          valueAccount: "",
        },
        {
          placeHolderAccount: "Please Enter New Password",
          nameAccount: "newPassword",
          typeAccount: "text",
          labelAccount: "New Password",
          errorAccount: "",
          valueAccount: "",
        },
        {
          placeHolderAccount: "Please Confirm Password",
          nameAccount: "confirmPassword",
          typeAccount: "text",
          labelAccount: "Confirm Password",
          errorAccount: "",
          valueAccount: "",
        }
      ],
    },
  ]