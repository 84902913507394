// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";


export const configJSONJoin = require("./config");

export interface PropsJoin {
  navigationJoin: any;
  idJoin: string;
}

interface SJoin {
}

interface SSJoin {
  idJoin: any;
}

export default class JoinControllerWebJoin extends BlockComponent<
  PropsJoin,
  SJoin,
  SSJoin
  > {

  constructor(propsJoin: PropsJoin) {
    super(propsJoin);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [];

    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
}
// Customizable Area End
