import React from "react";
import PrivacyTermsControllerWeb, {
  Props,
} from "./PrivacyTermsControllerWeb.web";

import PrivacyTermsContent from './PrivacyTermsContent.web'

export class PrivacyTermsWeb extends PrivacyTermsControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return <PrivacyTermsContent />;
  }
}


export default PrivacyTermsWeb;
