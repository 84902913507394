import { APIArrayData, JetZeroImpact, } from "../../types";
import { useJetZeroQuery } from "../../utils/react-query";
import { impactKeys } from "../query-keys/impact.keys";
import { impactMessages } from "../message-generators/impact.messages";


export function useImpactPageContent() {

  return useJetZeroQuery<APIArrayData<JetZeroImpact>>({
    queryKey: impactKeys.fetchImpactPageContent(),
    message: impactMessages.fetchImpactPageContent(),
  });
}

