import React from "react";
// Customizable Area Start
import { SplashScreenContent2 } from "./splashscreenContent2";
// Customizable Area End

import SplashscreenController, { Props } from "./SplashscreenController";

export default class Splashscreen extends SplashscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start

    return <SplashScreenContent2 />;
    // Merge Engine - render - End
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
