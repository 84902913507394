import React from "react";
// Customizable Area Start
import ResetPasswordController, { Props } from "./resetPasswordController.web";
import {Box, Typography, Divider, Button} from '@material-ui/core'
import { JetZeroTextField } from "../../../components/src/design-system";
import './reset.css'
import WorldClocks from "../../../components/src/shared/WorldClock";
import { PasswordVisibility } from "../../../components/src/shared";
// Customizable Area End


export default class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  isMobile = window.outerWidth < 960
  // Customizable Area End

  render() {
    return (
      <Box className="resetPassword">
        <Box className="resetPasswordContainer">
          <Typography className="resetPasswordHeadingTypo">
            RESET PASSWORD
          </Typography>
          <Box className="resetInputContainer" style={this.style.inputContainer}>
            <JetZeroTextField
              style={{...this.style.textField, flex: 1}}
              label=""
              placeholder="NEW PASSWORD"
              type= {this.state.showNewPassword ? "text" : "password"}
              data-test-id="newPasswordText"
              value={this.state.password}
              onChange={(e:any) => this.setState({
                password: e.target.value
              })}
              InputProps={{ 
                disableUnderline: !this.isMobile,
                endAdornment: 
                <PasswordVisibility 
                  showPassword={this.state.showNewPassword}
                  togglePassword={()=>this.setState({showNewPassword: !this.state.showNewPassword})}
                /> 
              }}
            />
            <Box sx={{flex: 1}} style={this.style.dividerWrapper}>
              <Divider className="resetDivider" orientation="vertical" />
              <JetZeroTextField
                style={this.style.textField}
                label=""
                type= {this.state.showPassword ? "text" : "password"}
                data-test-id="confirmPasswordText"
                value={this.state.confirmPassword}
                onChange={(e:any) => this.setState({
                  confirmPassword: e.target.value
                })}
                placeholder="CONFIRM PASSWORD"
                InputProps={{ 
                  disableUnderline: !this.isMobile,
                  endAdornment: 
                    <PasswordVisibility 
                      showPassword={this.state.showPassword}
                      togglePassword={()=>this.setState({showPassword: !this.state.showPassword})}
                    />
                }}
              />
            </Box>
            <Box style={this.style.dividerWrapper}>
              <Divider className="resetDivider" orientation="vertical" />
              <Button
                type="submit"
                color="primary"
                variant="text"
                data-test-id="sendBtn"
                onClick={()=>this.sendRequest()}
              >
                SUBMIT
              </Button>
            </Box>
          </Box>
        </Box>
        {
          this.state.error?.map((error:string) => {
            return (
              <Typography color="error" className="resetDividerErrorTypo">{error}</Typography>
            )
          })
        }
        <Typography color="primary" className="resetDividerErrorTypo">{this.state.sucess}</Typography>
        <WorldClocks reset color="white" />
      </Box>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  style = {
    dividerWrapper: {
      display: "flex",
      alignItems:"center",
    },
    textField: {
      position:"relative",
      top:2,
      width: "100%"
    } as React.CSSProperties,
    inputContainer: {
    border: `1px solid #2C62E0`
    }
  }
  // Customizable Area End
}

