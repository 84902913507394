import React, { useEffect, useState } from 'react'

function useOnlineStatus() {

    const [status, setStatus] = useState<Boolean>(navigator.onLine)

    useEffect(() => {

        function handleEvent() {
            const online: boolean = navigator.onLine
            setStatus(online)
        }

        window.addEventListener('online', handleEvent)
        window.addEventListener('offline', handleEvent)

        return () => {
            window.removeEventListener('online', handleEvent)
            window.removeEventListener('offline', handleEvent)
        }

    }, [])

    return status

}

export default useOnlineStatus;