// Customizable Area Start
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import moment, { Moment } from "moment";
import { DatePicker } from "@material-ui/pickers";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import { AnimatePresence, motion } from "framer-motion";
import {
  Box,
  Grid,
  styled,
  Button,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import MultiselectReactDropdown from "multiselect-react-dropdown";
import clsx from 'clsx';
import "./UserProfileBasic.css";
import { Carousel } from "../../../components/src/shared";


import {
  useEmptyLegs,
  postLegRequest,
  useFetchAirports,
  useEmptyLegsSearchFilter,
  useAllEmptyLegs,
  useFeaturedLegs,
  usePrivateFlights
} from "../../../components/src/api/hooks/basicProfile.hooks";

export interface IAirportRow {
  data: {
    id: string;
    type: string;
    attributes: {
      icao_code: string;
      airport_name: string;
      city: string;
      country_code: string;
      country_name: string;
    };
  };
}

export interface IFlightDetailsRow {
  availability: {
    availability_id: number;
    registration_number: string;
    aircraft_type: string;
    company: string;
    dep_airport_icao: string;
    arrival_airport_icao: string;
    from_date_utc: string;
    to_date_utc: string;
    comment: any;
    price: any;
    currency_code: any;
  };
  departure_airport: IAirportRow;
  arrival_airport: IAirportRow;
}

const filterOptions: Array<any> = [
  { value: "all", label: "All" },
  { value: "preferred", label: "Preferred" },
  { value: "range", label: "Filter" },
];

const useStylesLegs : any = makeStyles((theme) => ({
  rootEmptyLegs: {
    width: '100%',
    display: 'grid',
    gridGap: 8,
    gridTemplateColumns: '150px 1fr 150px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: '"plane plane" "info info"',
    },
  },
  airportInfoEmptyLegs: {
    '& p:first-child': {
      color: 'black',
    },
    '& p': {
      color: theme.palette.secondary.main,
      fontWeight: '600',
      textTransform: 'uppercase',
      margin: '0',
    },
    [theme.breakpoints.down('sm')]: {
      gridArea: "info",
      width: "50%"
    },
  },
  airportSmallInfoEmptyLegs: {
    '& p:first-child': {
      color: 'black',
    },
    '& p': {
      color: theme.palette.secondary.main,
      fontWeight: '600',
      textTransform: 'uppercase',
      margin: '0',
    }
  },
  leftAlignedEmptyLegs: {
    '& p': {
      textAlign: 'left',
    },
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'start',
    },
  },
  rightAlignedEmptyLegs: {
    '& p': {
      textAlign: 'right',
    },
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'end',
    },
  },
  airPlaneContainerEmptyLegs: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    paddingBottom: "15px",
    '& p': {
      color: theme.palette.primary.main,
      fontWeight: '600',
      textTransform: 'uppercase',
      margin: '0',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      gridArea: "plane",
    },
  },
  airPlaneContainerReverseEmptyLegs: {
    flexDirection: 'row-reverse',
  },
  planeImgEmptyLegs: {
    position: 'absolute',
    width: 'var(--plane-img-size)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '70%',
    },
  },
  planeSmallImgEmptyLegs: {
    width: "100%",
  },
}));
interface PropsLegs {
  tripTypeLegs: string; // 'arrive' | 'departure'
  infoLegs: IFlightDetailsRow;
}

function EmptyLegsTripsLegs({ tripTypeLegs, infoLegs }: PropsLegs) {
  const classesLegs = useStylesLegs();
  const isMobileScreenLegs = useMediaQuery('(max-width: 768px)');

  const arrivalLegs = infoLegs?.arrival_airport?.data.attributes ?? {}
  const departureLegs = infoLegs?.departure_airport?.data.attributes ?? {}
  const availabilityLegs = infoLegs?.availability

  const convertDateLegs = (dateLegs: string) => {
    const [dateComponentLegs] = dateLegs.split("T");
    const [yearLegs, monthLegs, dayLegs] = dateComponentLegs.split("-");
    return `${dayLegs}/${monthLegs}/${yearLegs}`;
  }

  return (
    infoLegs && (
      <div>
        <div className={classesLegs.rootEmptyLegs}>

          {!isMobileScreenLegs && (
            <div className={clsx(classesLegs.airportInfoEmptyLegs, classesLegs.leftAlignedEmptyLegs)}>
              <FlightDetailsLegs typeLegs={availabilityLegs.aircraft_type} timecodeLegs={convertDateLegs(availabilityLegs.from_date_utc)} dataLegs={departureLegs} />
            </div>
          )}

          <div className={clsx(classesLegs.airPlaneContainerEmptyLegs, {
            [classesLegs.airPlaneContainerReverseEmptyLegs]: tripTypeLegs == "arrive"
          })}>
            <HeadingsLegs>{'departure'}</HeadingsLegs>

            {!isMobileScreenLegs && (
              <img
                className={clsx(classesLegs.planeImgEmptyLegs, {
                  ["planeArrive"]: tripTypeLegs == 'arrive',
                  ["planeDeparture"]: tripTypeLegs == 'departure',
                })}
                src="/assets/black-white-plane.svg"
                alt="plane" />
            )}

            <HeadingsLegs>{'arrival'}</HeadingsLegs>
            <FlightImageLineLegs />
          </div>

          {!isMobileScreenLegs && (
            <div className={clsx(classesLegs.airportInfoEmptyLegs, classesLegs.rightAlignedEmptyLegs)}>
              <FlightDetailsLegs typeLegs={availabilityLegs.aircraft_type} timecodeLegs={convertDateLegs(availabilityLegs.to_date_utc)} dataLegs={arrivalLegs} />
            </div>
          )}
        </div>
        {isMobileScreenLegs && (
          <div style={{ paddingTop: 10, display: "flex", width: "100%" }}>
            <div className={classesLegs.airportSmallInfoEmptyLegs} style={{ flex: 1 }}>
              <FlightDetailsLegs typeLegs={availabilityLegs.aircraft_type} timecodeLegs={convertDateLegs(availabilityLegs.from_date_utc)} dataLegs={departureLegs} />
            </div>
            <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: 'center' }}>
              <img
                className={clsx(classesLegs.planeSmallImgEmptyLegs, {
                  ["planeArrive"]: tripTypeLegs == 'arrive',
                  ["planeDeparture"]: tripTypeLegs == 'departure',
                })}
                src="/assets/black-white-plane.svg"
                alt="plane" />
            </div>
            <div className={classesLegs.airportSmallInfoEmptyLegs} style={{ flex: 1, textAlign: "right" }}>
              <FlightDetailsLegs typeLegs={availabilityLegs.aircraft_type} timecodeLegs={convertDateLegs(availabilityLegs.to_date_utc)} dataLegs={arrivalLegs} />
            </div>
          </div>
        )}
      </div>)
  );
}

function FlightDetailsLegs({ dataLegs, timecodeLegs, typeLegs }: { dataLegs: any, timecodeLegs: any, typeLegs: any }) {
  return (
    <FlightDetailsTextLegs>
      <Typography>{dataLegs.city_name}</Typography>
      <Typography>{dataLegs.icao}</Typography>
      <Typography>{timecodeLegs}</Typography>
      <Typography>{typeLegs}</Typography>
    </FlightDetailsTextLegs>
  )
}

const HeadingsLegs = styled(Typography)({
  fontWeight: 400,
  fontSize: "20px",
  color: "#2C62E0",
  fontStyle: "normal",
  lineHeight: "normal",
  textTransform: "uppercase",
  "@media only screen and (max-width: 1150px)": {
    fontSize: "16px",
  },
  "@media only screen and (max-width: 768px)": {
    fontSize: "10px",
  }
})

const FlightDetailsTextLegs = styled('div')({
  '@media only screen and (max-width: 599px)': {
    '& .MuiTypography-body1': {
      fontSize: 10
    }
  }
})

const FlightImageLineLegs = styled('div')({
  left: 0,
  right: 0,
  top: '96px',
  position: 'absolute',
  transform: 'translateY(-50%)',
  borderBottom: '1px solid #2C62E0',
  '@media only screen and (max-width: 959px)': {
    top: '100%',
  }
})

export default function Book() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const [privateJets, setPrivateJets] = useState<any>([]);
  const [featured, setFeatured] = useState<any[]>([])

  const { data: featuredCall } = useFeaturedLegs();

  useEffect(()=>{
    featuredCall?.data && setFeatured(featuredCall.data)
  },[featuredCall])

  const { mutate: postPrivateFlightsRequest, isLoading:postPrivateFlightsLoading } = usePrivateFlights();

  useEffect(() => {
    featured &&
      featured.length > 0 &&
      setPrivateJets(
        featured.map((item: any) => {
          return {
            id: item.id,
            type: item.type,
            attributes: {
              name: item.attributes.from,
              position: item.attributes.date,
              content: item.attributes.to,
              image: item.attributes.image,
              requested: item.attributes.requested
            },
          };
        })
      );
  }, [featured]);

  const handleArrowDown = (event:any) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      const parentDiv = Array.from(document.querySelectorAll('.highlightOption')).map((select:any) => select.closest('.optionListContainer'));
      const visibleparentDiv = parentDiv.filter((div:any) => !div.classList.contains('displayNone'))
      if (visibleparentDiv.length>0) {
        const highlightedLi = visibleparentDiv[0].querySelector('.highlight') as HTMLElement;
        const containerUl = Array.from(document.querySelectorAll('.optionContainer'));
        if (highlightedLi) {
          const newList = containerUl.filter((item:any) => Array.from(item.childNodes).filter((things:any) => things.innerText===highlightedLi.innerText).length>0)
          newList.forEach((list:any) => list.scrollTo(0,highlightedLi.offsetTop))
        }
      }
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://apps.avinode.com/webapp/rest/bootstrap?Avinode-WEB-APP=eyJraWQiOiIxNkVBQkQ5RS1BRjYyLTQ4NTEtODk5Qi1BM0UwMThGRjYxNDciLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJjMGMxNWE4NS0wYjc5LTRlYTEtYWZkYy0yNWUwYjM2ODgxZmIiLCJhdmlkb21haW4iOiIuYXZpbm9kZS5jb20iLCJhdml0ZW5hbnQiOjEyNjA0LCJpc3MiOiJhdmlub2RlIiwiYXZpdHlwZSI6MTAsImF2aW5vbmNlIjoiZWQ4YzBmMWItM2YzMi00NGU2LTliYzAtYTQwZTYwZGQ0ZTMyIn0.HFRG3SY7Tis62NLITit614Xk7qBGygALTj3L505dpoUMnihLCvBOopuZ-85e0J3wqCcRMASO2ej52C_2h8h2p_jbP2dD1EXFrD2W1bq5dYtYc9UrdHgC4xiW4WV4gOMs-Y38HUoxY2OJBynREXLdHYBawWzU5HJXckjNqT691S8G9Rp1o-Pz5f_xB1ks2YALy4EKSGF05sZ2ZlfgqO8LqkJUjuwSYybxdnxcRUO8dGsBRlqlcEceTcsYy0Q7PII9PviJexBzb3DofBMQOLdOKVOMznetXwUFWh9h2Bl6zj_vT91NVCoxtT4DpgW1Ktf_Ijx4IgFgIBfowRMyu-jZPw";
    script.async = true;
    document.body.appendChild(script);
    window.addEventListener('keydown', handleArrowDown);
    return () => {
      // Cleanup: Remove the script when the component unmounts
      document.body.removeChild(script);
      window.removeEventListener('keydown', handleArrowDown);
    };
  }, []);

  const handleIDClick = (id:any) => {
    postPrivateFlightsRequest(id, {
      onSuccess(){
        setFeatured((featured:any) => {
          return featured.map((item:any) => {
            const featuredID = item.id;
            
            if (featuredID === id) {
              return {
                ...item,
                attributes : {
                  ...item.attributes,
                  requested : true
                }
              };
            }
            
            return item;
          });
        });
      }
    })
  }

  return (
    <div style={{ paddingBottom: "50px" }}>
      <Box
        className="rootContainer1"
        sx={{ padding: { sm: "20px 40px", md: "20px 40px" } }}
      >
        <div style={{ width: "100%", padding: "65px 0px" }} id="avinodeApp" />
        <EmptyLegs matches={matches} />
        {privateJets.length > 0 && <PrivateFlightsHeading />}
      </Box>
      {privateJets.length > 0 && (
        <Carousel
          destination
          postPrivateFlightsLoading={postPrivateFlightsLoading}
          items={privateJets}
          book
          onClickRequest={({ id }: any) => handleIDClick(id)}
        />
      )}
      <Box sx={{ height: 130 }} />
    </div>
  );
}

function PrivateFlightsHeading() {
  return (
    <Box style={{ width: "100%" }}>
      <Typography
        variant="h1"
        style={{
          fontWeight: 500,
          textAlign: "left",
          color: "rgba(193, 193, 193, 1)",
        }}
      >
        FEATURED
      </Typography>
      <Typography
        variant="h1"
        style={{ color: "black", fontWeight: 500, textAlign: "left" }}
      >
        PRIVATE FLIGHTS
      </Typography>
    </Box>
  );
}

function EmptyLegs({ matches }: any) {
  const [id, setId] = useState<number>(0);
  const [toIcao, setToIcao] = useState<string>("");
  const [fromIcao, setFromIcao] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterType, setFilterType] = useState<string>("all");
  const [searchDate, setSearchDate] = useState(moment().format("YYYY-MM-DD"));
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [flightRecords, setFlightRecords] = useState<any>([]);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const {
    isLoading: selectedFlightDetailsLoading,
    data: selectedFlightDetails,
    mutate: getSelectedEmptyLegs,
  } = useEmptyLegs();

  const {
    isLoading: allFlightDetailsLoading,
    data: allFlightDetails,
    mutate: getAllEmptyLegs,
  } = useAllEmptyLegs();

  const {
    mutate: getSearchFilter,
    isLoading: searchLoading,
    data: dateWiseFlightDetails,
  } = useEmptyLegsSearchFilter();

  const { isLoading: isLoadingRequest, mutate: postRequest } = postLegRequest();

  const getLoading = () => {
    if (filterType === "preferred") return selectedFlightDetailsLoading;
    else if (filterType === "range") return searchLoading;
    else if (filterType === "all") return allFlightDetailsLoading;
  };

  const getNoData = () => {
    return flightRecords.length <= 0;
  };

  useEffect(() => {
    if (filterType === "preferred")
      setFlightRecords(selectedFlightDetails?.data || []);
    else if (filterType === "range")
      setFlightRecords((prev: any) =>
        dateWiseFlightDetails
          ? [...prev, ...dateWiseFlightDetails.data]
          : [...prev]
      );
    else if (filterType === "all")
      setFlightRecords((prev: any) =>
        allFlightDetails ? [...prev, ...allFlightDetails.data] : [...prev]
      );
  }, [selectedFlightDetails, dateWiseFlightDetails, allFlightDetails]);

  useEffect(() => {
    setFlightRecords([]);
    setCurrentPage(1);
  }, [filterType]);

  useEffect(() => {
    setFlightRecords([]);
    setCurrentPage(1);
  }, [toDate, fromDate, searchDate, toIcao, fromIcao]);

  const noData: any = getNoData();
  const isLoading: any = getLoading();

  useEffect(() => {
    if (filterType === "preferred" && fromDate && toDate)
      getSelectedEmptyLegs({ fromDate, toDate });
    else if (searchDate && fromIcao && toIcao && filterType === "range")
      getSearchFilter({
        // @ts-ignore
        page: currentPage,
        date: searchDate,
        fromIcao: fromIcao,
        toIcao: toIcao,
      });
      else if (filterType === "all") getAllEmptyLegs(currentPage);
  }, [currentPage,searchDate, fromIcao, toIcao, filterType, fromDate, toDate]);

  useEffect(() => {
    if ((selectedFlightDetails && filterType === "preferred") || (dateWiseFlightDetails && filterType === "range")) {
      setCurrentPage(1);
    } else if (allFlightDetails && filterType === "all") {
      setCurrentPage(Number(allFlightDetails.current_page));
    }
  }, [
    selectedFlightDetails,
    dateWiseFlightDetails,
    filterType,
    allFlightDetails,
  ]);

  useEffect(() => {
    getAllEmptyLegs(1);
  }, []);

  const sendRequest = (id: number) => {
    postRequest(id, {
      onSuccess(){
        setFlightRecords((prevFlightRecords:any) => {
          return prevFlightRecords.map((record:any) => {
            const availabilityId = record.availability.availability_id;
            
            if (availabilityId === id) {
              return {
                ...record,
                already_requested: true
              };
            }
            
            return record;
          });
        });
      }
    });
    setId(id);
  };

  const ref = useRef(null);
  const [divHeight, setDivHeight] = useState("0");
  useLayoutEffect(() => {
    if (ref.current) {
      //@ts-ignore
      setDivHeight(ref.current.offsetHeight * 5);
    }
  }, [flightRecords]);

                    
  const handleScroll = (e:any) => {
    const div = e.target;
    const scrollPercentage = (div.scrollTop / (div.scrollHeight - div.clientHeight)) * 100;
    if (scrollPercentage >= 99) {
      setIsScrolledToBottom(true);
    } else {
      setIsScrolledToBottom(false);
    }
  };  

  return (
    <>
      <Box
        sx={{ padding: "0px" }}
        style={{ width: "100%", position: "relative" }}
      >
        <HeadingTile variant="h2">EMPTY LEGS</HeadingTile>
        <SubHeadingTitle variant="h4">
          Book your seats at available flights
        </SubHeadingTitle>
        <FilterContainer>
          <div>
            <div className="select-wrapper">
              <Grid container spacing={1}>
                {filterOptions.map((item: any) => {
                  return (
                    <Grid item key={item.value}>
                      <StyledButton
                        size="small"
                        data-test-id={"trip-filter-btn-" + item.value}
                        onClick={() => setFilterType(item?.value)}
                        variant={
                          filterType === item.value ? "outlined" : "text"
                        }
                      >
                        {item.label}
                      </StyledButton>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
        </FilterContainer>
      </Box>
      <Box
        sx={{
          padding: { xs: "50px 0px", sm: "50px 0px", md: "50px 0px" },
          width: "100%",
        }}
      >
        <DateFilter
          filterType={filterType}
          searchDate={searchDate}
          setDate={setSearchDate}
          setFrom={(item: any) => setFromIcao(item.value)}
          setTo={(item: any) => setToIcao(item.value)}
        />
        <PreferredFilter
          filterType={filterType}
          onChangeDate={(fromDate: string, toDate: string) => {
            setFromDate(fromDate);
            setToDate(toDate);
          }}
        />
        {isLoading && (
          <>
            <Skeleton variant="rect" />
            <br />
            <Skeleton variant="rect" />
          </>
        )}

        {noData && !isLoading && <Box sx={{ pt: 2, pb: 2 }}>NO TRIP FOUND!</Box>}

        {!noData && (
          <Box
            data-test-id="scrollDiv"
            className="emptyLegContainer"
            style={{
              maxHeight: `${divHeight}px`,
              overflowY:"scroll",
              marginBottom: "30px",
            }}
            onScroll={handleScroll}
          >
            {flightRecords?.map((details: any) => {
              return (
                <GridContainer key={details.availability.availability_id}>
                  <Grid
                    container
                    style={{ padding: "30px 0px 30px 0px" }}
                    ref={ref}
                  >
                    <Grid item xs={12} sm={9} md={10}>
                      <EmptyLegsTripsLegs tripTypeLegs="departure" infoLegs={details} />
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                      <RequestArrow>
                        <Box
                          className="arrow-container"
                          style={{
                            gap: "20px",
                            display: "flex",
                            cursor: details.already_requested ? "blocked" : "pointer",
                            marginTop: "35px",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: !matches ? "column" : "row",
                          }}
                          data-test-id={
                            "request-" + details.availability.availability_id
                          }
                          onClick={() =>
                            !details.already_requested &&
                            sendRequest(details.availability.availability_id)
                          }
                        >
                          <Typography variant="h4" color={details.already_requested ? "secondary" :"primary"}>
                            <Button
                              color="primary"
                              variant="text"
                              type="submit"
                              disabled={details.already_requested}
                            >
                              {details.already_requested ? "REQUESTED" : "REQUEST"}
                              {isLoadingRequest && id === details.availability.availability_id && (
                                  <CircularProgress
                                    size={15}
                                    style={{margin:"0px 5px"}}
                                    color="primary"
                                  />
                              )}
                            </Button>
                          </Typography>
                          <img
                            src="/assets/goArrow.svg"
                            alt="Go Arrow"
                            className="arrow"
                          />
                        </Box>
                      </RequestArrow>
                    </Grid>
                  </Grid>
                </GridContainer>
              );
            })}
          </Box>
        )}

        {!noData && !isLoading && filterType === "all" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              variant="h3"
              color={isScrolledToBottom ? "secondary" :"primary"}
            >
              Scroll down
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}

function PreferredFilter({ filterType, onChangeDate }: any) {
  const [toDate, setToDate] = useState<any>(moment().add(2, 'days'));
  const [fromDate, setFromDate] = useState<any>(moment());

  useEffect(() => {
    onChangeDate(fromDate.format("YYYY-MM-DD"), toDate.format("YYYY-MM-DD"));
  }, [toDate, fromDate]);

  return (
    <AnimatePresence>
      {filterType === "preferred" && (
        <Box>
          <motion.div
              transition={{ duration: 0.3 }}
              exit={{ opacity: 0, marginTop: -80 }}
              animate={{ opacity: 1, marginTop: 0 }}
              initial={{ opacity: 0, marginTop: -100 }}
            >
              <PreferredDate>
                <div>
                  <DatePicker
                    inputProps={{
                      "data-test-id": "field-fromDate",
                    }}
                    value={fromDate}
                    label="From Date"
                    minDate={moment()}
                    onChange={(date: Moment) => setFromDate(date)}
                  />
                </div>

            <div>
              <DatePicker
                inputProps={{
                  "data-test-id": "field-toDate",
                }}
                label="To Date"
                value={toDate}
                minDate={moment()}
                onChange={(date: Moment) => setToDate(date)}
              />
            </div>
          </PreferredDate>
        </motion.div>
      </Box>
    )}
  </AnimatePresence>
  );
}

function DateFilter({
  filterType,
  searchDate,
  setDate,
  setFrom,
  setTo,
}: {
  filterType: string;
  searchDate: string;
  setDate: Function;
  setFrom: Function;
  setTo: Function;
}) {
  const [fromQuery, setFromQuery] = useState("");
  const [toQuery, setToQuery] = useState("");

  const {
    data: toAirports,
    refetch: toRefetch,
    isLoading: toLoading,
  } = useFetchAirports(toQuery);

  const {
    data: fromAirports,
    refetch: fromRefetch,
    isLoading: fromLoading,
  } = useFetchAirports(fromQuery);

  useEffect(() => {
    toRefetch();
  }, [toQuery]);
  useEffect(() => {
    fromRefetch();
  }, [fromQuery]);

  const searchFrom = (value: string) => {
    value.length > 3 && setFromQuery(value);
  };

  const searchTo = (value: string) => {
    value.length > 3 && setToQuery(value);
  };

  return (
    <AnimatePresence>
      {filterType === "range" && (
        <Box>
          <motion.div
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, marginTop: -80 }}
            animate={{ opacity: 1, marginTop: 0 }}
            initial={{ opacity: 0, marginTop: -100 }}
          >
            <DateContainer>
              <div>
                <DatePicker
                  inputProps={{
                    "data-test-id": "field-date",
                  }}
                  label="Date"
                  value={searchDate}
                  onChange={(date: Moment) =>
                    setDate(date.format("YYYY-MM-DD"))
                  }
                />
              </div>
              <div>
                <div className="field-label">From</div>
                <StyledSelect
                  aria-label="field-fromSelect"
                  placeholder="Search From"
                  displayValue="label"
                  selectionLimit={1}
                  onSearch={searchFrom}
                  loading={fromLoading}
                  onSelect={(array) => setFrom(array[0])}
                  options={
                    (fromAirports &&
                      fromAirports?.data?.results?.filter((check:any) => Boolean(check.icao)).map((item: any) => {
                        return {
                          value: item.icao,
                          label: item.icao,
                        };
                      })) ||
                    []
                  }
                />
              </div>
              <div>
                <div className="field-label">To</div>
                <StyledSelect
                  aria-label="field-toSelect"
                  displayValue="label"
                  placeholder="Search To"
                  selectionLimit={1}
                  onSearch={searchTo}
                  loading={toLoading}
                  onSelect={(array) => setTo(array[0])}
                  options={
                    (toAirports &&
                      toAirports?.data?.results?.filter((check:any) => Boolean(check.icao)).map((item: any) => {
                        return{
                          value: item.icao,
                          label: item.icao,
                        }
                      })) ||
                    []
                  }
                />
              </div>
            </DateContainer>
          </motion.div>
        </Box>
      )}
    </AnimatePresence>
  );
}

const StyledSelect = styled(MultiselectReactDropdown)({
  "& .searchWrapper": {
    border: 0,
    fontSize: 14,
    marginTop: 9,
    paddingLeft: 0,
    borderRadius: 0,
    boxShadow: "none",
    fontFamily: "Atyp, sans-serif",
    borderBottom: "1px solid rgb(44, 98, 224)",
  },
  "& .chip": {
    paddingLeft: 8,
    marginBottom: 0,
    padding: "0px 4px",
  },
  "& input": {
    width: 100,
  },
});

const HeadingTile = styled(Typography)({
  color: "#000",
  fontSize: 40,
  fontWeight: 400,
  lineHeight: "40px",
  fontStyle: "normal",
  "@media only screen and (max-width: 768px)": {
    fontSize: 20,
    lineHeight: "20px",
  },
});

const SubHeadingTitle = styled(Typography)({
  fontSize: 20,
  textAlign: "left",
  lineHeight: "40px",
  color: "rgba(193, 193, 193, 1)",
  "@media only screen and (max-width: 768px)": {
    fontSize: 13,
    paddingTop: 8,
    lineHeight: "20px",
  },
});

const DateContainer = styled("div")({
  gap: 16,
  display: "grid",
  paddingBottom: 32,
  gridTemplateColumns: "1fr 1fr 1fr",
  alignItems: "end",
  "& .field-label": {
    padding: 0,
    lineHeight: 1,
    fontWeight: 400,
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  "@media only screen and (max-width: 530px)": {
    display: "block",
    "& > div": {
      paddingBottom: 26,
    },
    "& > div:last-child": {
      paddingBottom: 0,
    },
  },
  "& > div": {
    "& .MuiInput-input": {
      font: "unset",
      fontSize: 14,
      fontFamily: "Atyp, sans-serif",
    },
    "& > .MuiTextField-root": {
      paddingTop: 8,
      width: "100%",
      "& .MuiInput-underline::before": {
        borderBottom: "1px solid #2C62E0",
      },
    },
  },
});

const FilterContainer = styled("div")({
  right: 0,
  top: 15,
  position: "absolute",
  "@media only screen and (max-width: 1315px)": {
    position: "static",
    paddingBottom: 16,
    paddingTop: 16,
  },
  "& > div": {
    gap: 12,
    display: "flex",
    alignItems: "center",
    "@media only screen and (max-width: 355px)": {
      display: "block",
      "& > div:first-child": {
        paddingBottom: 6,
      },
    },
  },
  "& .select-wrapper": {
    width: "100%",
  },
});

const StyledButton = styled(Button)({
  textTransform: "capitalize",
  color: "#C1C1C1",
  "&.MuiButton-outlined": {
    color: "black",
  },
});

const RequestArrow = styled("div")({
  "& .arrow": {
    width: "80%",
  },
  // @ts-ignore
  "@media only screen and (max-width: 599px)": {
    "& .arrow": {
      width: 134,
    },
    "& .arrow-container": {
      flexDirection: "row-reverse !important",
      justifyContent: "flex-start !important",
    },
  },
});

const GridContainer = styled("div")({
  "& > div": {
    width: "100%",
    marginBottom: 100,
    "@media only screen and (max-width: 599px)": {
      marginBottom: 60,
    },
  },
});

const PreferredDate = styled(DateContainer)({
  gridTemplateColumns: "1fr 1fr",
});
// Customizable Area End